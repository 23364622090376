import styled from "styled-components";

import bg from "assets/images/banner-unit-details.png";
import { colors, colorScheme, fonts } from "styles";

export const Container = styled.div`
  width: 100%;
  height: 588px;
  background: #ccc url(${bg}) no-repeat center right;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 880px) {
    background-size: cover;
    height: auto;
    padding: 72px 32px;
  }

  @media screen and (max-width: 720px) {
    background: #d4ccc9;
    height: auto;
    padding: 72px 32px;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1366px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  padding: 0 32px;
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 520px;
`;

export const SubTitle = styled.h1`
  font-size: 24px;
  line-height: 24px;
  color: ${colorScheme.primary};
  text-transform: uppercase;
  margin-bottom: 24px;
  font-family: ${fonts.BrandontextBlack};

  @media screen and (max-width: 880px) {
    font-size: 18px;
    line-height: 18px;
  }

  @media screen and (max-width: 720px) {
    text-align: center;
  }
`;

export const Title = styled.h1`
  font-size: 69px;
  line-height: 69px;
  color: ${colors.dark};
  text-transform: uppercase;
  margin-bottom: 24px;

  span {
    font-family: ${fonts.BrandontextLight};
  }

  @media screen and (max-width: 880px) {
    font-size: 40px;
    line-height: 40px;
  }

  @media screen and (max-width: 720px) {
    text-align: center;
  }

  @media screen and (max-width: 414px) {
    font-size: 30px;
    line-height: 30px;
  }
`;

export const Text = styled.article`
  width: 100%;
  max-width: 80%;
  font-size: 18px;
  line-height: 20px;
  color: ${colors.dark};

  @media screen and (max-width: 720px) {
    text-align: center;
    max-width: 100%;
  }

  @media screen and (max-width: 414px) {
    font-size: 16px;
  }
`;

import React from "react";

import * as S from "./styles";

export const Midia: React.FC = () => {
  return (
    <S.Container>
      <S.GroupInfo>
        <h2>
          Confira
          <br />
          <span>Orthopride na Mídia</span>
        </h2>
        <p className="more">Clique nas marcas abaixo e veja a matéria</p>
        <S.GroupMarks>
          <a
            href="https://revistapegn.globo.com/Franquias/noticia/2022/07/clinica-odontologica-para-classe-c-orthopride-anuncia-franquia-mais-barata-para-chegar-bairros-ricos.html"
            target="_blank"
          >
            <S.ImgEmpresas alt="Pequenas Empresas Grandes Negócios" />
          </a>
          <a
            href="https://www.infomoney.com.br/negocios/rede-de-franquias-orthopride-dribla-a-pandemia-e-avanca/"
            target="_blank"
          >
            <S.ImgInfoMoney alt="Infomoney" />
          </a>
          <a
            href="https://exame.com/negocios/grupo-da-orthopride-e-bodylaser-cresce-15-e-fatura-r-467-milhoes-em-2022/"
            target="_blank"
          >
            <S.ImgExame alt="Exame" />
          </a>

          <a
            href="https://braziljournal.com/brands/na-orthopride-o-franqueado-e-o-cliente-sorriem-juntos/"
            target="_blank"
          >
            <S.ImgBjournal alt="Brazil Journal" />
          </a>
        </S.GroupMarks>
      </S.GroupInfo>
    </S.Container>
  );
};

import React from "react";
import { Link } from "react-router-dom";

import * as S from "./styles";
import { Navigation } from "./Navigation";

export const Header: React.FC = () => {
  return (
    <S.Container>
      <S.Content>
        <Link to="/">
          <S.Logo />
        </Link>
        <Navigation />
      </S.Content>
    </S.Container>
  );
};

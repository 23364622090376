import React, { useState } from "react";

import * as S from "./styles";
import { IFaq } from "data/faqFranchising";

interface Props {
  data: IFaq;
}

export const Accordion: React.FC<Props> = ({ data }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const spring = {
    type: "spring",
    stiffness: 700,
    damping: 30,
  };
  return (
    <S.Container>
      <S.Wrapper>
        <S.Question>{data?.question}</S.Question>
        <S.Button onClick={() => setIsOpen(!isOpen)}>
          <S.Icon
            data-isopen={isOpen}
            layout
            initial={{ rotate: 0 }}
            animate={isOpen ? { rotate: 180 } : { rotate: 0 }}
          >
            <S.Arrow />
          </S.Icon>
        </S.Button>
      </S.Wrapper>
      <S.Response data-isopen={isOpen} open={isOpen} layout transition={spring}>
        {data?.response}
      </S.Response>
    </S.Container>
  );
};

import { call, put } from "redux-saga/effects";

import { api, notify } from "services";
import { ICreateRequest } from "interfaces/create-duck";

import { requestErrorHandler } from "utils";
import { ParticiparSorteioActions } from "store/ducks/parcela-premiada";

export function* ParticiparSorteioRequest(action: any) {
  try {
    const { postData, onSuccess } = action as ICreateRequest;
    const { data } = yield call(api.post, `/sorteio-aceite`, postData);

    yield put(ParticiparSorteioActions.success(data));
    if (onSuccess) onSuccess(data);
    notify(
      "success",
      "Sua participação na promoção foi confirmada com sucesso!"
    );
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(ParticiparSorteioActions.failure(errorMessage));
  }
}

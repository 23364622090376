import styled from "styled-components";
import { motion } from "framer-motion";
import { colors, fonts } from "styles";
import symbolBig from "assets/images/banner-treatment-symbol-big.svg";
import medina from "assets/images/banner-treatment-medina.png";
import mascote from "assets/images/medina-blusa-azul.png";

export const Container = styled.div`
  width: 100%;
  height: 460px;
  padding-left: 150px;
  background: rgb(245, 130, 38);
  background: linear-gradient(
    230deg,
    rgba(245, 130, 38, 1) 35%,
    rgba(242, 111, 57, 1) 100%
  );
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 1100px) {
    height: auto;
    padding-left: 0px;
  }
`;

export const SymbolBig = styled(motion.img).attrs({ src: symbolBig })`
  position: absolute;
  top: -200px;
  right: -200px;
  z-index: 1;

  @media screen and (max-width: 1100px) {
    top: auto;
    bottom: -200px;
    right: -340px;
  }
`;

export const Ball = styled(motion.div)`
  width: 70px;
  height: 70px;
  border-radius: 35px;
  background-color: #353941;
  position: absolute;
  z-index: 1;
  bottom: 64px;
  right: 296px;

  @media screen and (max-width: 1100px) {
    display: none;
  }
`;

export const Modelo = styled.img.attrs({ src: mascote })`
  width: auto;
  max-height: 450px;
  max-width: max-content;
  align-self: flex-end;
  padding-left: 100px;
  padding-bottom: 0px;

  @media screen and (max-width: 1100px) {
    align-self: center;
    padding-left: 0;
    padding-top: 48px;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1366px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  padding: 0 32px;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
    padding: 48px 32px 0 32px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 460px;
`;

export const Title = styled.h1`
  font-size: 69px;
  line-height: 69px;
  color: ${colors.white};
  text-transform: uppercase;
  margin-bottom: 24px;

  span {
    color: white;
    font-family: ${fonts.BrandontextLight};
  }

  @media screen and (max-width: 1100px) {
    text-align: center;
    font-size: 50px;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 414px) {
    font-size: 36px;
    line-height: 36px;
  }
`;

export const Text = styled.article`
  width: 100%;
  max-width: 80%;
  font-size: 18px;
  line-height: 20px;
  color: ${colors.white};

  @media screen and (max-width: 1100px) {
    text-align: center;
    max-width: 100%;
    font-size: 16px;
    margin-bottom: -20px;
  }

  @media screen and (max-width: 414px) {
    font-size: 16px;
  }
`;

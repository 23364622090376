import React, { useCallback } from "react";
import { scroller } from "react-scroll";
import * as S from "./styles";

export const Whitening: React.FC = () => {
  const scroll = useCallback((element) => {
    scroller.scrollTo(element, {
      duration: 400,
      delay: 0,
      offset: -90,
    });
  }, []);
  return (
    <S.Container>
      <S.Content>
        <S.LeftSize>
          <S.WhiteningLogo />
        </S.LeftSize>

        <S.RightSize>
          <S.WhiteningLogoLefty />
          <S.Title>
            Clareamento portátil
            <br />
            Faça de <span>qualquer lugar</span>
          </S.Title>

          <S.Text>
            Faça o seu clareamento em qualquer lugar, com comodidade e
            tecnologia avançada e praticidade. Tenha dentes mais claros, em
            menos tempo! Para realizar este procedimento, é necessário agendar
            uma avaliação com o seu dentista.
          </S.Text>
          <S.MarcarAvaliacao onClick={() => scroll("FormAgenda")}>
            Agendar Avaliação
          </S.MarcarAvaliacao>
        </S.RightSize>
      </S.Content>
      <S.GroupChevy>
        <S.ButtonChevy>
          <S.ChevDown size={50} />
        </S.ButtonChevy>
      </S.GroupChevy>
    </S.Container>
  );
};

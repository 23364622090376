import styled from "styled-components";
import { colors, fonts } from "styles";

import PequenasEmpresas from "assets/images/franchising2024/pequenas-empresas.png";
import InfoMony from "assets/images/franchising2024/infomoney.png";
import Exame from "assets/images/franchising2024/exame.png";
import BrazilJournal from "assets/images/franchising2024/brazil-journal.png";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1920px;
  min-height: 350px;
  background: ${colors.dark};
  padding: 100px;
  @media screen and (max-width: 650px) {
    padding: 50px 20px;
  }
`;

export const GroupInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 700px;
  @media screen and (max-width: 1590px) {
    margin: 0 auto;
  }

  h2 {
    font-family: ${fonts.BrandontextRegular};
    font-size: 40px;
    color: ${colors.orange};
    line-height: 1em;
    text-align: center;
    margin-bottom: 40px;
    span {
      font-family: ${fonts.BrandontextBlack};
      font-size: 50px;
    }
  }
  p.more {
    width: 100%;
    max-width: 390px;
    font-family: ${fonts.BrandontextMedium};
    font-size: 20px;
    color: ${colors.orange};
    border: 1px solid ${colors.orange};
    padding: 5px 15px;
    transition: 0.5s;
    margin-bottom: 80px;
    text-align: center;
  }
`;

export const GroupMarks = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  max-width: 500px;
  height: auto;
  column-gap: 40px;
  row-gap: 40px;
  @media screen and (max-width: 700px) {
    flex-direction: column;
    width: 95%;
    max-width: 300px;
    align-items: center;
  }
`;

export const ImgEmpresas = styled.img.attrs({ src: PequenasEmpresas })`
  width: 100%;
  max-width: 250px;
  height: auto;
`;
export const ImgInfoMoney = styled.img.attrs({ src: InfoMony })`
  width: 100%;
  max-width: 250px;
  height: auto;
`;
export const ImgExame = styled.img.attrs({ src: Exame })`
  width: 100%;
  max-width: 250px;
  height: auto;
`;
export const ImgBjournal = styled.img.attrs({ src: BrazilJournal })`
  width: 100%;
  max-width: 250px;
  height: auto;
`;

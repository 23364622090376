import React from "react";

import * as S from "./styles";
import { Scaffold, Footer } from "components/Shared";
import {
  FormFloat,
  FormMobile,
  Banner,
  Numbers,
  Recognition,
  Video,
  SocialProof,
  Differences,
  ReasonsInvest,
  Investment,
  Ambassadors,
  Midia,
  Features,
  FAQ,
} from "components/Franchising2024";

export const Franchising2024: React.FC = () => {
  return (
    <Scaffold>
      <FormFloat />
      <Banner />
      <FormMobile />
      <Numbers />
      <Recognition />
      <Video />
      <SocialProof />
      <Differences />
      <ReasonsInvest />
      <Investment />
      <Ambassadors />
      <Midia />
      <Features />
      <FAQ />
      <FormMobile />
      <Footer />
    </Scaffold>
  );
};

import React, { useState } from "react";
import ReactPlayer from "react-player";
import { useHistory } from "react-router-dom";
import cover from "assets/images/video-cover.jpg";

import * as S from "./styles";

export const Franchising: React.FC = () => {
  const history = useHistory();
  const [playing, setPlaying] = useState(false);

  const handlePlayClick = () => {
    setPlaying(true);
  };
  return (
    <S.Container>
      <S.Background />
      <S.BackgroundMobile>
        <S.Content>
          <S.Title>
            A <span>rede de franquia</span> que mais cresce no Brasil
          </S.Title>
          <S.Text>
            Assista ao vídeo e veja por que a Orthopride é a franquia que mais
            cresce no Brasil e a melhor opção para tratamentos dentários.
          </S.Text>
        </S.Content>
      </S.BackgroundMobile>
      <S.Video>
        <S.VideoPlay size="80" control={playing} onClick={handlePlayClick} />

        <div className="player-wrapper">
          <ReactPlayer
            url="https://vimeo.com/384864757"
            className="react-player"
            width="100%"
            height="100%"
            controls={false}
            playing={playing}
          />
        </div>
      </S.Video>
      <S.Content>
        <S.Title>
          É investidor?
          <br />
          Somos a opção <span className="dark">mais segura</span>.
        </S.Title>
        <S.Text>
          A rede Orthopride apresenta um modelo de negócio competitivo e de alta
          rentabilidade aos seus franqueados.
        </S.Text>
        <S.UnitsPredicted>
          <S.ItemWrapper>
            <S.UnitNumber>205</S.UnitNumber>
            <S.Label>Unidades em diversos estados</S.Label>
          </S.ItemWrapper>
          <S.ItemWrapper>
            <S.UnitNumber>75</S.UnitNumber>
            <S.Label>Novas unidades previstas até 2026</S.Label>
          </S.ItemWrapper>
        </S.UnitsPredicted>
        <S.Button
          btStyle="secondary"
          onClick={() => history.push("/franchising")}
        >
          Seja um franqueado
        </S.Button>
      </S.Content>
    </S.Container>
  );
};

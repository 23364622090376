import styled, { css } from "styled-components";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1920px;
  align-items: center;
  justify-content: center;
  background: #f0f0f0;
  padding-bottom: 150px;
  @media screen and (max-width: 900px) {
    padding-bottom: 50px;
  }
`;
export const Content = styled.div`
  display: flex;
  width: 90%;
  max-width: 900px;
  margin: 0 auto;
  flex-direction: column;
  padding: 20px;

  align-items: flex-start;
  justify-content: center;
`;

export const GroupTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h1`
  font-size: xx-large;
  color: #222;
  text-transform: uppercase;
  margin-bottom: 10px;
  max-width: 600px;
  line-height: 1.2em;
  text-align: center;
  span {
    color: orange;
  }
`;

export const Topic = styled.p`
  font-size: large;
  color: #222;
  margin-bottom: 15px;
  font-weight: bold;
  margin-top: 20px;
`;

export const Text = styled.p`
  font-size: medium;
  color: #222;
  margin-bottom: 15px;
  font-weight: normal;
`;

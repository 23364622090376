import React from "react";
import { useHistory } from "react-router-dom";

import * as S from "./styles";

export const BannerNovember: React.FC = () => {
  const history = useHistory();
  return (
    <S.Container>
      <S.DesktopBanner alt="Desktop Banner" />
      {/* ------------------------------   */}
      <S.Wrapper>
        <S.Title>
          Arrisque
          <br />
          <span>um sorriso</span>
        </S.Title>
        <S.Text>
          A Orthopride é a rede de clínicas de ortodontia que mais cresce no
          Brasil! Marque já a sua avaliação e descubra o que podemos fazer por
          você!
        </S.Text>
        <S.ButtonsWrapper>
          <S.Button
            btStyle="light"
            type="button"
            onClick={() => history.push("/encontre-uma-unidade")}
          >
            Encontre uma unidade
          </S.Button>
          <S.Button
            btStyle="light"
            type="button"
            onClick={() => history.push("/agendar-avaliacao")}
          >
            Agende online
          </S.Button>
        </S.ButtonsWrapper>
        {/* <S.Loncation>
            <S.Pin />
            Temos uma unidade em <span> Volta Redonda/RJ</span>
          </S.Loncation> */}
      </S.Wrapper>
      {/* ------------------------------   */}

      <S.MobileBanner alt="Mobile Banner" />
      <S.CtaMobile href="https://orthopride.com.br/agendar-avaliacao">
        Agende uma &nbsp;<span> Avaliação</span>
      </S.CtaMobile>
    </S.Container>
  );
};

import { ThemeProvider } from "styled-components";
import { colorScheme, colors, fonts } from ".";

const theme = {
  colors,
  colorScheme,
  fonts,
};

const Theme = ({ children }: any) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;

import styled from "styled-components";
import figure from "assets/images/prevencao-kids-min.png";

import { colors, fonts } from "styles";
export { Button } from "styles/styled-components";

export const Background = styled.section`
  width: 100%;
  padding: 96px 32px;
  background-color: ${colors.grayLight};

  @media screen and (max-width: 414px) {
    padding-top: 48px;
    padding-bottom: 48px;
  }
`;

export const Container = styled.section`
  width: 100%;
  max-width: 1280px;
  display: flex;
  margin: 0 auto;

  @media screen and (max-width: 880px) {
    flex-direction: column-reverse;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media screen and (max-width: 880px) {
    max-width: 100%;

    &:last-child {
      margin-bottom: 32px;
    }
  }
`;

export const Figure = styled.img.attrs({ src: figure })`
  width: 100%;
  max-width: 550px;
  height: auto;
`;

export const Wrapper = styled.div`
  padding-left: 48px;
  width: 100%;
  max-width: 510px;

  @media screen and (max-width: 880px) {
    padding-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const Title = styled.h2`
  font-size: 69px;
  line-height: 69px;
  color: ${colors.dark};
  margin-bottom: 24px;
  text-transform: uppercase;

  span {
    font-family: ${fonts.BrandontextLight};
  }

  @media screen and (max-width: 1130px) {
    font-size: 54px;
    line-height: 54px;
  }

  @media screen and (max-width: 880px) {
    text-align: center;
  }

  @media screen and (max-width: 414px) {
    font-size: 30px;
    line-height: 30px;
  }
`;

export const Text = styled.article`
  width: 100%;
  max-width: 430px;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 40px;

  @media screen and (max-width: 880px) {
    text-align: center;
  }
`;

import styled from "styled-components";
import { colors, fonts } from "styles";

import { Whatsapp } from "@styled-icons/bootstrap/Whatsapp";
import { Phone } from "@styled-icons/boxicons-solid/Phone";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1920px;
  align-items: center;
  justify-content: center;
  background: ${colors.dark};
  min-height: 450px;
`;

export const Title = styled.h2`
  font-family: ${fonts.BrandontextBold};
  font-size: 46px;
  color: ${colors.white};
  line-height: 1em;
  letter-spacing: -1px;
  span {
    color: ${colors.orange};
  }
`;

export const SbTitle = styled.h3`
  font-family: ${fonts.SourceSansProRegular};
  font-size: 19px;
  color: ${colors.white};
  margin: 30px 0 40px 0;
`;

export const GroupButtons = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const ButtonDefalt = styled.a`
  display: flex;
  font-family: ${fonts.BrandontextBold};
  font-size: 20px;
  background: ${colors.orange};
  color: ${colors.white};
  cursor: pointer;
  width: 175px;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  &:first-child {
    margin-right: 30px;
  }
  @media screen and (max-width: 900px) {
    &:first-child {
      margin-right: 0px;
    }
  }
`;

export const WhatsappIcon = styled(Whatsapp).attrs({ size: 24 })`
  margin-right: 8px;
  color: ${colors.white};
`;

export const PhoneIcon = styled(Phone).attrs({ size: 24 })`
  margin-right: 8px;
  color: ${colors.white};
`;

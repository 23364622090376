import styled from "styled-components";
import { colors, fonts } from "styles";

export const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  background: ${colors.orange};
  padding: 100px 200px;
  height: auto;
  overflow-x: hidden;
  @media screen and (max-width: 960px) {
    padding: 100px 100px;
    justify-content: center;
    align-items: flex-start;
  }
  @media screen and (max-width: 800px) {
    padding: 50xp 30px;
  }
`;

export const BlackCircle = styled.div`
  position: absolute;
  top: 10%;
  left: -200px;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background: transparent;
  border: 80px solid ${colors.dark};
  @media screen and (max-width: 950px) {
    width: 300px;
    height: 300px;
    border: 50px solid ${colors.dark};
  }
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const GroupInfo = styled.div`
    display:flex;
    flex-direction:column;
    width: 100%
    height:auto;
    
    h2 {
        font-family: ${fonts.BrandontextBold};
        color:${colors.white};
        font-size: 44px;
        text-transform:uppercase;
        margin-bottom:30px;
        @media screen and (max-width: 1023px) {
          font-size: 35px;
          margin: 0px auto;
        }
        @media screen and (max-width: 800px) {
          font-size: 35px;
          margin: 0px auto;
          text-align:center;
       
        }
        @media screen and (max-width: 540px) {
          font-size: 33px;
          margin: 0px auto;
          text-align:center;
        }
    }
    @media screen and (max-width: 1720px) {
      margin: 0 auto;
    }

  
`;

export const GroupCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 600px;
  height: auto;
  column-gap: 20px;
  justify-content: center;
  align-items: flex-start;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 250px;
  height: auto;

  h2 {
    font-family: ${fonts.BrandontextBlack};
    color: ${colors.black};
    font-size: 80px;
    letter-spacing: -1px;
    margin-bottom: -15px;
    @media screen and (max-width: 1023px) {
      font-size: 70px;
    }
    @media screen and (max-width: 690px) {
      font-size: 60px;
    }

    @media screen and (max-width: 570px) {
      margin-bottom: 20px;
      font-size: 50px;
      margin-bottom: -10px;
    }
  }
  p {
    font-family: ${fonts.BrandontextBold};
    color: ${colors.white};
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
    @media screen and (max-width: 1023px) {
      font-size: 17px;
    }
    @media screen and (max-width: 570px) {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 570px) {
    margin-bottom: 25px;
  }
`;

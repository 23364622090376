import styled from "styled-components";
import { colors, fonts } from "styles";
import MascoteBanner from "assets/images/thop-central-atendimento.png";

export { Button } from "styles/styled-components";

export const Container = styled.section`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1920px;
  background: rgb(245, 130, 38);
  background: linear-gradient(
    0deg,
    rgba(245, 130, 38, 1) 0%,
    rgba(245, 165, 38, 1) 100%
  );
  height: 100%;
  position: relative;
`;

export const Content = styled.section`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const Mascote = styled.img.attrs({ src: MascoteBanner })`
  width: 80%;
  height: auto;
  @media screen and (max-width: 1440px) {
    width: auto;
    height: 350px;
  }
  @media screen and (max-width: 1024px) {
    width: auto;
    height: 300px;
  }
  @media screen and (max-width: 900px) {
    width: 100%;
    height: auto;
  }
`;

export const TextBanner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 900px) {
    margin-bottom: 53px;
  }
`;

export const Title = styled.h1`
  color: ${colors.dark};
  font-size: 65px;
  text-transform: uppercase;
  line-height: 1.2em;
  margin-left: -18%;
  &.light {
    font-family: ${fonts.BrandontextLight};
  }
  &.bold {
    font-family: ${fonts.BrandontextBold};
  }
  @media screen and (max-width: 1176px) {
    font-size: 50px;
  }
  @media screen and (max-width: 900px) {
    font-size: 65px;
    margin: 0 auto;
  }
  @media screen and (max-width: 500px) {
    font-size: 36px;
    margin: 0 auto;
    line-height: 1em;
  }
`;

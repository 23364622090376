import styled from "styled-components";
import Bg from "assets/images/thop-surf/bg.jpg";
import ThopLogo from "assets/images/thop-surf/logo.png";
import Surfers from "assets/images/thop-surf/atletas.png";
import SurfersSmall from "assets/images/thop-surf/atletasbkp.png";

export const Container = styled.div`
  width: 100%;
  max-width: 1920px;
  height: auto;
  overflow-x: hidden;
  /* font-family: "Knockout46";
  font-family: "Knockout49"; */
`;
export const AreaBanner = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  min-height: 620px;
  background: #008da7 url(${Bg}) no-repeat top center;
  background-size: cover;
  padding: 50px;
  @media screen and (max-width: 1100px) {
    display: none;
  }
`;

export const GroupMenu = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  min-height: 250px;
  /* background: rgba(0, 0, 0, 0.5); */
  z-index: 1;
`;

export const SurfersImg = styled.img.attrs({ src: Surfers })`
  width: 95%;
  max-width: 1318px;
  height: auto;
  z-index: 2;
`;

export const MenuOpt = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 50px;
  /* background: rgba(0, 0, 0, 0.8); */
  z-index: 4;
  column-gap: 50px;
  a {
    font-family: "Knockout49";
    color: #fff;
    text-decoration: none;
    font-size: 20px;
    text-transform: uppercase;
    transition: 1s;
    &:hover {
      color: yellow;
    }
    &.ian {
      margin-left: 150px;
      @media screen and (max-width: 1250px) {
        margin-left: 180px;
      }
      @media screen and (max-width: 1150px) {
        margin-left: 100px;
      }
      @media screen and (max-width: 1120px) {
        margin-left: 100px;
        margin-right: -15px;
      }
    }
    &.thop {
      margin-right: 150px;
      @media screen and (max-width: 1250px) {
        margin-right: 80px;
      }
      @media screen and (max-width: 1150px) {
        margin-right: 80px;
      }
    }
    &.medina {
      margin-right: 50px;
      @media screen and (max-width: 1170px) {
        margin-right: 15px;
      }
    }
  }
`;

export const AreaBannerMobile = styled.div`
  display: none;
  position: relative;
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  min-height: 620px;
  background: #008da7 url(${Bg}) no-repeat top center;
  background-size: cover;
  padding: 50px 50px 0 50px;

  @media screen and (max-width: 1100px) {
    display: flex;
  }
`;

export const GroupTopMobile = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
  h2 {
    font-family: "Knockout46";
    /* font-family: "Knockout49"; */
    color: #fff;
    font-size: 80px;
    text-align: left;
    text-transform: uppercase;
    line-height: 1em;
    order: 1;
    @media screen and (max-width: 890px) {
      order: 2;
      text-align: center;
      margin-top: 20px;
    }
    @media screen and (max-width: 500px) {
      font-size: 55px;
    }
  }
  @media screen and (max-width: 890px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Logo = styled.img.attrs({ src: ThopLogo })`
  width: 100%;
  max-width: 220px;
  height: auto;
  @media screen and (max-width: 500px) {
    max-width: 150px;
  }
`;
export const SurfersAreaMobile = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: auto;
  @media screen and (max-width: 890px) {
    display: none;
  }
`;

export const SurfersMobile = styled.img.attrs({ src: SurfersSmall })`
  width: 100%;
  max-width: 800px;
`;

export const MenuOptMobile = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 50px;
  background: rgba(0, 0, 0, 0.8);
  z-index: 4;
  column-gap: 50px;

  a {
    font-family: "Knockout49";
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    text-transform: uppercase;
    transition: 1s;
    &:hover {
      color: yellow;
    }
    &.ian {
      margin-left: -5px;
      margin-right: -20px;
      /* @media screen and (max-width: 1250px) {
        margin-left: 180px;
      } */
    }
  }
  &.thop {
    margin-left: -30px;
    margin-right: 30px;

    /* @media screen and (max-width: 1250px) {
        margin-left: 180px;
      } */
  }
`;

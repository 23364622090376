import React from "react";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";

import * as S from "./styles";

export const Braces: React.FC = () => {
  const history = useHistory();
  const settings = {
    slidesToShow: 3,
    speed: 500,
    adaptiveHeight: true,
    nextArrow: (
      <S.NextButton>
        <S.IconNext />
      </S.NextButton>
    ),
    prevArrow: (
      <S.PrevButton>
        <S.IconPrev />
      </S.PrevButton>
    ),
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  };
  const animeThop = {
    hidden: {
      scale: 1,
    },
    thopEstetico: {
      scale: [1, 1.025, 1],
      transition: { ease: "easeInOut", duration: 4, repeat: Infinity },
    },
    thopFixo: {
      scale: [1, 1.025, 1],
      rotate: [1, 3, 1],
      transition: { ease: "easeInOut", duration: 5, repeat: Infinity },
    },
    thopTransparente: {
      scale: [1, 1.025, 1],
      transition: { ease: "easeInOut", duration: 4, repeat: Infinity },
    },
  };

  return (
    <S.Container>
      <S.WhiteBackground />
      <S.Content>
        <S.Title>
          Aparelhos que se encaixam no seu <span>estilo</span>.
        </S.Title>
        <S.Text>
          Oferecemos tratamentos ortodônticos avançados, que corrigem a
          dentição, alinham a mordida e protegem os dentes. Temos aparelhos que
          se encaixam perfeitamente ao seu estilo: colorido para quem quer
          "causar" ou estético para quem é mais discreto!
        </S.Text>
        <S.Wrapper>
          <Slider {...settings}>
            <S.BoxBrace className="estetico">
              <S.TitleBox>
                Aparelho
                <br /> Ortodôntico Estético
              </S.TitleBox>
              <S.AparelhoEstetico
                initial="hidden"
                animate="thopEstetico"
                variants={animeThop}
              />
              <S.TextBox>
                Opção de tratamento mais discreta com peças da cor dos dentes ou
                translúcidas. Sua eficácia é igual à do aparelho fixo, porém tem
                o diferencial da sensação de <span>invisibilidade</span>.
              </S.TextBox>
              <S.KnowMore onClick={() => history.push("/tratamentos")}>
                <S.ArrowIcon />
                Saiba mais
              </S.KnowMore>
            </S.BoxBrace>
            <S.BoxBrace>
              <S.TitleBox>
                Aparelho
                <br /> Ortodôntico Fixo
              </S.TitleBox>
              <S.AparelhoFixo
                initial="hidden"
                animate="thopFixo"
                variants={animeThop}
              />
              <S.TextBox>
                Sendo a escolha mais comum, a força das suas peças metálicas
                promove a movimentação dos dentes para os locais corretos,
                alinhando-os. Disponível em{" "}
                <span className="dark">diversas cores</span>.
              </S.TextBox>
              <S.KnowMore onClick={() => history.push("/tratamentos")}>
                <S.ArrowIcon dark />
                Saiba mais
              </S.KnowMore>
            </S.BoxBrace>
            <S.BoxBrace className="transparente">
              <S.TitleBox>
                Aparelho
                <br /> Ortodôntico Transparente
              </S.TitleBox>
              <S.AparelhoInvisivel
                initial="hidden"
                animate="thopTransparente"
                variants={animeThop}
              />
              <S.TextBox>
                Alinhador transparente, exclusivo da Orthopride, que permite o
                reposicionamento dos dentes de maneira simples, rápida e sem
                comprometer a estética, pois é quase que imperceptível, até
                mesmo na hora de falar. Opção mais{" "}
                <span>moderna e discreta</span>.
              </S.TextBox>
              <S.KnowMore onClick={() => history.push("/tratamentos")}>
                <S.ArrowIcon />
                Conheça o Lineo
              </S.KnowMore>
            </S.BoxBrace>
          </Slider>
        </S.Wrapper>
        <S.DragText>Arraste para o lado</S.DragText>
      </S.Content>
    </S.Container>
  );
};

import React, { useRef, useCallback, useState, useEffect } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { useHistory } from "react-router-dom";
import { OptionTypeBase } from "react-select";
import { Form } from "@unform/web";
import { FormHandles, SubmitHandler } from "@unform/core";
import * as Yup from "yup";

import { ListUnidadesState, ListUnidadesActions } from "store/ducks/unidades";
import { statesOptions } from "utils";
import { useValidation } from "hooks";

import * as S from "./styles";
import { Select } from "components/Shared/Form";

interface iBooking {
  unitName?: string | null;
  unitState?: string | null;
}

export const Booking: React.FC<iBooking> = ({ unitName, unitState }) => {
  const dispatch = useDispatch();
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { handleFormErrors } = useValidation();
  const [cityOptions, setCityOptions] = useState<OptionTypeBase[]>([]);
  const [selectedCity, setSelectedCity] = useState<OptionTypeBase | null>([]);
  const [selectedState, setSelectedState] = useState<OptionTypeBase | null>(
    null
  );
  const [initCity, setInitCity] = useState<string>(" ");

  useEffect(() => {
    if (unitName) {
      const formattedUnitName = unitName
        ?.trim()
        .toLocaleUpperCase()
        .replace(/-/g, " ");
      setInitCity(formattedUnitName);
    }
  }, [unitName]);

  const { data: listUnitsData, loading: listUnitsLoading } = useSelector<
    RootStateOrAny,
    ListUnidadesState
  >((state) => state.listUnidades);

  const handleSubmit = useCallback<SubmitHandler>(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          state: Yup.string().required("Obrigatório"),
          city: Yup.string().required("Obrigatório"),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        history.push({
          pathname: "/agendar-avaliacao",
          state: { city: data.city },
        });
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [handleFormErrors, history]
  );

  const getUnits = useCallback(
    (state) => {
      dispatch(ListUnidadesActions.request({ state }));
    },
    [dispatch]
  );

  const setCities = useCallback(() => {
    const data = listUnitsData.map((unit) => ({
      label: unit.Cidade.toUpperCase(),
      value: unit.Cidade,
    }));

    const uniqueData: any[] = [];

    for (let index = 0; index < data.length; index++) {
      const item = data[index];
      if (
        !uniqueData.some(
          (i) => i.value.toUpperCase() === item.value.toUpperCase()
        )
      ) {
        uniqueData.push(item);
      }
    }

    const uniqueDataOrder = uniqueData.sort((a, b) =>
      a.label > b.label ? 1 : b.label > a.label ? -1 : 0
    );

    setCityOptions(uniqueDataOrder);
  }, [listUnitsData]);

  useEffect(() => {
    if (selectedState) {
      getUnits(selectedState.value);
    }
  }, [getUnits, selectedState]);

  useEffect(() => {
    setCities();
  }, [setCities]);

  useEffect(() => {
    if (unitState) {
      const foundStateOption = statesOptions.find(
        (option) => option.value.toUpperCase() === unitState.toUpperCase()
      );
      if (foundStateOption) {
        setSelectedState(foundStateOption);
      }
    }
  }, [unitState]);

  useEffect(() => {
    if (initCity) {
      const foundCityOption = cityOptions.find(
        (option) => option.value.toUpperCase() === initCity.toUpperCase()
      );
      if (foundCityOption && unitState) {
        setSelectedCity(foundCityOption);
      }
    }
  }, [initCity, cityOptions, unitState]);

  useEffect(() => {
    const formData: {
      state?: { label: string; value: string };
      city?: { label: string; value: string };
    } = {};

    if (selectedState) {
      formData.state = {
        label: selectedState.label,
        value: selectedState.value,
      };
    }
    if (selectedCity) {
      formData.city = { label: selectedCity.label, value: selectedCity.value };
    }

    formRef.current?.setData(formData);
  }, [selectedState, selectedCity]);

  return (
    <S.Container>
      <S.Content>
        <S.Title>
          Marque a sua <span>avaliação</span>
        </S.Title>
        <S.Text>
          Nossas unidades estão em diversas cidades e estados brasileiros.
          Preencha abaixo com os seus dados e encontre a clínica mais próxima de
          você:
        </S.Text>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Select
            name="state"
            label="Selecione seu estado:"
            options={statesOptions}
            onChange={(e) => setSelectedState(e)}
            defaultValue={selectedState}
          />
          <Select
            name="city"
            label="Selecione sua cidade:"
            isDisabled={listUnitsLoading || cityOptions.length === 0}
            isLoading={listUnitsLoading}
            options={cityOptions}
          />
          {!listUnitsLoading && selectedState && cityOptions.length === 0 && (
            <S.Warning>
              Nenhuma unidade encontrada para o estado selecionado.
            </S.Warning>
          )}
          <S.Button>Marcar uma avaliação</S.Button>
        </Form>
      </S.Content>
    </S.Container>
  );
};

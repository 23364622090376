import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { v4 as uuidV4 } from "uuid";

import * as S from "./styles";

interface IOption {
  route: string;
  name: string;
  spotlight?: boolean;
}

export const Navigation: React.FC = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const options: Array<IOption> = [
    {
      route: "/encontre-uma-unidade",
      name: "Encontre uma unidade",
    },
    {
      route: "/tratamentos",
      name: "Tratamentos",
    },
    {
      route: "/harmonizacao-orofacial",
      name: "Harmonização Orofacial",
    },
    {
      route: "/studioorthopride",
      name: "Studio Orthopride",
    },

    {
      route: "/empresa",
      name: "Empresa",
    },
    {
      route: "/franchising",
      name: "Franchising",
    },
    {
      route: "/surf-thop-team",
      name: "Surf Thop Team",
    },

    {
      route: "/contato",
      name: "Fale com a gente",
    },
  ];

  const mobileOptions: Array<IOption> = [
    {
      route: "/",
      name: "Início",
    },
    {
      route: "/tratamentos",
      name: "Tratamentos",
    },
    {
      route: "/harmonizacao-orofacial",
      name: "Harmonização Orofacial",
    },
    {
      route: "/studioorthopride",
      name: "Studio Orthopride",
    },

    {
      route: "/empresa",
      name: "A Orthopride",
    },
    {
      route: "/franchising",
      name: "Franchising",
    },
    {
      route: "/duvidas-frequentes",
      name: "Dúvidas frequentes",
    },
    {
      route: "/surf-thop-team",
      name: "Surf Thop Team",
    },
    {
      route: "/contato",
      name: "Fale com a gente",
    },
  ];

  const handleNavigate = useCallback(
    (route: string) => {
      setOpen(false);
      history.push(route);
    },
    [history]
  );
  return (
    <>
      <S.WebContainer>
        {options.map((option: IOption) => (
          <S.Option
            key={uuidV4()}
            onClick={() => handleNavigate(option.route)}
            spotlight={option.spotlight}
          >
            {option.name}
          </S.Option>
        ))}
        <S.BookButton
          btStyle="outline"
          onClick={() => handleNavigate("agendar-avaliacao")}
        >
          Agendar uma Avaliação
        </S.BookButton>
      </S.WebContainer>
      <S.MobileButton onClick={() => setOpen(true)}>
        <S.MenuIcon />
      </S.MobileButton>
      <S.Overlay open={open}>
        <S.CloseButton onClick={() => setOpen(false)}>
          <S.CloseIcon />
        </S.CloseButton>
        <S.Logo />
        <S.WrapperMobileOptions>
          {mobileOptions.map((option: IOption) => (
            <S.MobileOption
              key={uuidV4()}
              onClick={() => handleNavigate(option.route)}
            >
              {option.name}
            </S.MobileOption>
          ))}
        </S.WrapperMobileOptions>
        <S.WrapperButtons>
          <S.Button
            btStyle="secondary"
            onClick={() => handleNavigate("/encontre-uma-unidade")}
          >
            Encontre uma unidade
          </S.Button>
          <S.Button
            btStyle="light"
            onClick={() => handleNavigate("/encontre-uma-unidade")}
          >
            Agende online
          </S.Button>
        </S.WrapperButtons>
      </S.Overlay>
    </>
  );
};

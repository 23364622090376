import styled, { css } from "styled-components";

type ButtonStyleOption =
  | "primary"
  | "secondary"
  | "transparent"
  | "light"
  | "outline"
  | "cancel"
  | "danger";

interface ButtonStyle {
  btStyle?: ButtonStyleOption;
}

const styleButton = {
  primary: css`
    background-color: ${({ theme }) => theme.colorScheme.primary};
    color: #fff;
  `,
  secondary: css`
    background-color: ${({ theme }) => theme.colorScheme.secondary};
    color: #fff;
  `,
  outline: css`
    background-color: transparent;
    color: ${({ theme }) => theme.colorScheme.primary};
    border: 2px ${({ theme }) => theme.colorScheme.primary} solid;
  `,
  transparent: css`
    background-color: transparent;
    color: ${({ theme }) => theme.colorScheme.primary};
    padding: 16px 0;
  `,
  light: css`
    background-color: #fff;
    color: ${({ theme }) => theme.colorScheme.secondary};
  `,
  cancel: css`
    background-color: #e0e0e0;
    color: #888;
  `,
  danger: css`
    background-color: #ff2e4a;
    color: #fff;
  `,
};

export const Button = styled.button<ButtonStyle>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 16px 24px;
  height: 56px;
  border-radius: 4px;
  transition: 300ms ease;

  &:hover {
    transform: scale(0.95);
  }

  ${({ btStyle }) => (btStyle ? styleButton[btStyle] : styleButton.primary)}
`;

export const ButtonMini = styled.button<ButtonStyle>`
  display: flex;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  transition: 300ms ease;

  :hover {
    transform: scale(0.95);
  }

  ${({ btStyle }) => (btStyle ? styleButton[btStyle] : styleButton.primary)}
`;

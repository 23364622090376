import styled from "styled-components";
import { ChevronCircleRight } from "@styled-icons/fa-solid";
import { Facebook, Instagram, Linkedin } from "@styled-icons/boxicons-logos";
import { colors, colorScheme, fonts } from "styles";

import thop from "assets/images/footer-thop.png";
import android from "assets/images/app-android.png";
import ios from "assets/images/app-ios.png";
import { ReactComponent as logoOrtho } from "assets/images/back-logo.svg";
import foco from "assets/images/logo-foco.png";

export const Container = styled.footer`
  width: 100%;
  background-color: white;
  padding: 72px 32px;

  @media screen and (max-width: 900px) {
    padding: 48px 32px;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1366px;
  margin: 0 auto;

  &:last-child {
    border-top: 2px #d6dde6 solid;
    padding-top: 12px;
    margin-top: 32px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 900px) {
    &.main {
      flex-direction: column;
      align-items: center;
    }
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  @media screen and (max-width: 900px) {
    width: 100%;
    &.links {
      display: none;
    }
  }
`;

export const Thop = styled.img.attrs({ src: thop })`
  width: 100%;
  height: auto;
  max-width: max-content;
  max-height: max-content;
`;

export const Orthopride = styled(logoOrtho)`
  margin-bottom: 24px;
`;

export const Text = styled.article`
  width: 100%;
  max-width: 480px;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.blueGray};
  margin-bottom: 32px;

  &.app {
    max-width: 270px;
  }
`;

export const ContactButtonsWrapper = styled(Row)`
  width: 100%;
  max-width: max-content;

  @media screen and (max-width: 400px) {
    flex-direction: column;
  }
`;

export const ContactButton = styled.button.attrs({ type: "button" })`
  display: flex;
  align-items: center;
  font-size: 18px;
  color: ${colors.dark};
  margin-right: 48px;

  :hover {
    svg {
      transform: translateX(4px);
    }
  }

  :last-child {
    margin-right: 0;
  }
`;

export const ArrowIcon = styled(ChevronCircleRight).attrs({ size: 20 })`
  transition: 300ms ease;
  margin-right: 8px;
  color: ${colorScheme.primary};
`;

export const Privacy = styled.a`
  display: block;
  text-decoration: underline;
  font-size: 16px;
  margin-top: 32px;
  color: ${colorScheme.text};
`;

export const Title = styled.h6`
  font-size: 20px;
  color: ${colors.dark};
  margin-bottom: 24px;
`;

export const Link = styled.button.attrs({ type: "button" })`
  display:flex
  font-size: 16px;
  font-family: ${fonts.SourceSansProRegular};
  color: ${colorScheme.text};
  margin-bottom: 16px;
  justify-content:flex-start !important;
  align-items:flex-start;
  text-align:left;
  width: 100%
  max-width:120px;
  transition: 300ms ease;

  :last-child {
    margin-bottom: 0;
  }

  :hover {
    color: ${colorScheme.primary};
  }
`;

export const AppButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 900px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const AppButton = styled.button.attrs({ type: "button" })`
  display: flex;
  margin-bottom: 16px;
  transition: 300ms ease;

  :last-child {
    margin-bottom: 0;
  }

  :hover {
    transform: scale(0.95);
  }

  @media screen and (max-width: 900px) {
    margin-bottom: 0;

    &:first-child {
      margin-right: 16px;
    }
  }
`;

export const Android = styled.img.attrs({ src: android })`
  width: 100%;
  max-width: max-content;
`;

export const Ios = styled.img.attrs({ src: ios })`
  width: 100%;
  max-width: max-content;
`;

export const Social = styled.div`
  width: 100%;
  max-width: max-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  font-family: ${fonts.BrandontextBold};
  color: ${colors.dark};

  @media screen and (max-width: 900px) {
    text-align: center;
    align-self: center;
  }

  @media screen and (max-width: 350px) {
    span {
      display: none;
    }
  }
`;

export const SocialButton = styled.button.attrs({ type: "button" })`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 8px;
  border: 1px #465974 solid;
  transition: 300ms ease;

  &:hover {
    transform: scale(0.95);
    background-color: #465974;
    svg {
      color: white;
    }
  }
`;

export const IconFacebook = styled(Facebook).attrs({ size: 20 })`
  color: #465974;
`;

export const IconInstagram = styled(Instagram).attrs({ size: 20 })`
  color: #465974;
`;

export const IconLinkedin = styled(Linkedin).attrs({ size: 20 })`
  color: #465974;
`;

export const TextLegal = styled(Text)`
  font-size: 14px;
  text-align: right;
  margin: 0 32px 0 0;

  @media screen and (max-width: 900px) {
    text-align: center;
    margin: 0 0 32px 0;
    align-self: center;
  }
`;

export const FocoButton = styled.button.attrs({ type: "button" })`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Foco = styled.img.attrs({ src: foco })``;

import { call, put } from "redux-saga/effects";

import { api, notify } from "services";
import { IFechRequest } from "interfaces/fetch-duck";
import { requestErrorHandler } from "utils";
import { FetchPacienteActions } from "store/ducks/parcela-premiada";

export function* FetchPacienteRequest(action: any) {
  try {
    const { id, onSuccess } = action as IFechRequest;
    const { data } = yield call(api.get, `/sorteio/pacientes/${id}`);
    yield put(FetchPacienteActions.success(data));
    if (onSuccess) onSuccess(data);
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(FetchPacienteActions.failure(errorMessage));
  }
}

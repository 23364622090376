import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import * as S from "./styles";

export const Gallery: React.FC = () => {
  const settings = {
    slidesToShow: 1,
    speed: 500,
    adaptiveHeight: true,
    nextArrow: (
      <S.NextButton>
        <S.IconNext />
      </S.NextButton>
    ),
    prevArrow: (
      <S.PrevButton>
        <S.IconPrev />
      </S.PrevButton>
    ),
    // responsive: [
    //   {
    //     breakpoint: 801,
    //     settings: {
    //       slidesToShow: 2,
    //     },
    //   },
    //   {
    //     breakpoint: 541,
    //     settings: {
    //       slidesToShow: 1,
    //     },
    //   },
    // ],
  };
  return (
    <S.Container>
      <S.WrapperSlider>
        <Slider {...settings}>
          <div className="item">
            <Link to="/surf-thop-team/medina">
              <img src="/gallery/thopSurf/4.png" alt="thopSurf" />
            </Link>

            <Link to="/surf-thop-team/medina">Gabriel Medina</Link>
          </div>
          <div className="item">
            <Link to="/surf-thop-team/ian">
              <img src="/gallery/thopSurf/1.png" alt="thopSurf" />
            </Link>
            <Link to="/surf-thop-team/ian">Ian cosenza</Link>
          </div>
          <div className="item">
            <Link to="/surf-thop-team/michelle">
              <img src="/gallery/thopSurf/2.png" alt="thopSurf" />
            </Link>
            <Link to="/surf-thop-team/michelle">Michelle des bouillons</Link>
          </div>
          <div className="item">
            <Link to="/surf-thop-team/thop">
              <img src="/gallery/thopSurf/3.png" alt="thopSurf" />
            </Link>
            <Link to="/surf-thop-team/thop">thop</Link>
          </div>

          <div className="item">
            <Link to="/surf-thop-team/lucas-chumbo">
              <img src="/gallery/thopSurf/5.png" alt="thopSurf" />
            </Link>
            <Link to="/surf-thop-team/lucas-chumbo">Lucas Chumbo</Link>
          </div>
          <div className="item">
            <Link to="/surf-thop-team/lucas-fink">
              <img src="/gallery/thopSurf/6.png" alt="thopSurf" />
            </Link>
            <Link to="/surf-thop-team/lucas-fink">Lucas Fink</Link>
          </div>
        </Slider>
      </S.WrapperSlider>
    </S.Container>
  );
};

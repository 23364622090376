import styled from "styled-components";
import futebol from "assets/images/sponsor-futebol.png";
import medinaFirula from "assets/images/sponsor-medina-firula.png";
import medina from "assets/images/sponsor-medina.png";
import medinaBg from "assets/images/sponsor-medina-bg.png";
import suzuki from "assets/images/sponsor-suzuki.png";
import stockcar from "assets/images/sponsor-stockcar.png";
import times from "assets/images/sponsor-times.png";

export const Container = styled.section`
  display: flex;
  width: 100%;

  @media screen and (max-width: 1240px) {
    flex-wrap: wrap;
  }
`;

export const Subtitle = styled.h3`
  font-size: 14px;
  text-transform: uppercase;
  color: white;
  margin-bottom: 8px;
  width: 100%;
  max-width: 154px;
  position: relative;
  z-index: 3;
`;

export const Title = styled.h2`
  font-size: 42px;
  line-height: 42px;
  color: white;
  margin-bottom: 16px;
  text-align: left;
  width: 100%;
  max-width: 154px;
  position: relative;
  z-index: 3;
`;

export const Text = styled.article`
  font-size: 14px;
  line-height: 18px;
  color: white;
  width: 100%;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 33.33%;
  height: 654px;
  position: relative;
  padding: 64px 32px;

  @media screen and (max-width: 980px) {
    width: 100%;
  }
`;

export const Medina = styled(Box)`
  background: black url(${medinaBg}) no-repeat center center;
  align-items: flex-end;

  h2 {
    color: #3acdd3;
  }

  article {
    width: 100%;
    max-width: 154px;
  }
`;

export const Firula = styled.img.attrs({ src: medinaFirula })`
  width: 100%;
  max-width: max-content;
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 64px;
`;

export const PhotoMedina = styled.img.attrs({ src: medina })`
  width: 100%;
  max-width: max-content;
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: 0;

  @media screen and (max-width: 414px) {
    left: -48px;
  }
`;

export const Futebol = styled(Box)`
  background: black url(${futebol}) no-repeat center center;
  text-align: center;
  align-items: center;

  h3 {
    color: ${({ theme }) => theme.colorScheme.primary};
  }

  h2 {
    text-align: center;
  }

  article {
    width: 100%;
    max-width: 360px;
  }
`;

export const Suzuki = styled(Box)`
  background: black url(${suzuki}) no-repeat center center;

  h2 {
    color: ${({ theme }) => theme.colorScheme.primary};
  }

  article {
    width: 100%;
    max-width: 154px;
  }
`;

export const Stockcar = styled.img.attrs({ src: stockcar })`
  width: 100%;
  max-width: max-content;
  position: absolute;
  bottom: 100px;
  z-index: 1;
`;

export const Times = styled.img.attrs({ src: times })`
  width: 100%;
  max-width: max-content;
  position: absolute;
  bottom: 100px;
  z-index: 1;
`;

import React from "react";
import { Scaffold, Footer } from "components/Shared";
import { Gallery } from "components/ThopSurf/Gallery";
import { About } from "components/ThopSurf/About";
import { useParams, Link } from "react-router-dom";
import { FindUnitSimple } from "pages/Unit";
import * as S from "./styles";

interface IParams {
  slug: string;
}
export interface OtpItem {
  id: number;
  slug: string;
}

export const ThopSurf: React.FC = () => {
  let { slug } = useParams<IParams>();

  const Options: OtpItem[] = [
    { id: 1, slug: "medina" },
    { id: 2, slug: "michelle" },
    { id: 3, slug: "lucas-chumbo" },
    { id: 4, slug: "lucas-fink" },
    { id: 5, slug: "ian" },
    { id: 6, slug: "thop" },
  ];

  const validateSlug = (slug: string, otpItems: OtpItem[]): string => {
    const found = otpItems.some((item) => item.slug === slug);
    return found ? slug : "medina";
  };

  if (slug) {
    slug = slug ? validateSlug(slug, Options) : "medina";
  } else {
    slug = "medina";
  }

  return (
    <Scaffold>
      <S.Container>
        <S.AreaBanner>
          <S.GroupMenu>
            <S.SurfersImg alt="Atletas" />
            <S.MenuOpt>
              <Link to="/surf-thop-team/ian" className="ian">
                Ian cosenza
              </Link>
              <Link to="/surf-thop-team/michelle">Michele des Bouillons</Link>
              <Link to="/surf-thop-team/thop" className="thop">
                Thop
              </Link>
              <Link to="/surf-thop-team/medina" className="medina">
                Gabriel Medina
              </Link>
              <Link to="/surf-thop-team/lucas-chumbo">Lucas Chumbo</Link>
              <Link to="/surf-thop-team/lucas-fink">Lucas Fink</Link>
            </S.MenuOpt>
          </S.GroupMenu>
        </S.AreaBanner>
        <S.AreaBannerMobile>
          <S.GroupTopMobile>
            <h2>
              só a coragem
              <br />
              leva ao topo.
              <br />
              arrisque um sorriso!
            </h2>
            <S.Logo alt="Logo ThopSurf" />
          </S.GroupTopMobile>
          <S.SurfersAreaMobile>
            <S.SurfersMobile alt="Atletas" />
            <S.MenuOptMobile>
              <Link to="/surf-thop-team/ian" className="ian">
                Ian cosenza
              </Link>
              <Link to="/surf-thop-team/michelle">Michele des Bouillons</Link>
              <Link to="/surf-thop-team/thop" className="thop">
                Thop
              </Link>
              <Link
                to="/surf-thop-team/medina"
                className="medina"
                href="/medina"
              >
                Gabriel Medina
              </Link>
              <Link to="/surf-thop-team/lucas-chumbo">Lucas Chumbo</Link>
              <Link to="/surf-thop-team/lucas-fink">Lucas Fink</Link>
            </S.MenuOptMobile>
          </S.SurfersAreaMobile>
          <Gallery />
        </S.AreaBannerMobile>
        <About iSlug={slug} />
      </S.Container>
      <FindUnitSimple />
      <Footer />
    </Scaffold>
  );
};

import React, { useRef, useCallback, useState, useEffect } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { useHistory } from "react-router-dom";
import { OptionTypeBase } from "react-select";
import { Form } from "@unform/web";
import { FormHandles, SubmitHandler } from "@unform/core";
import * as Yup from "yup";
import { v4 as uuidV4 } from "uuid";

import { ListUnidadesState, ListUnidadesActions } from "store/ducks/unidades";
import { statesOptions } from "utils";
import { useValidation } from "hooks";

import * as S from "./styles";
import { Scaffold, UnitCard, Footer } from "components/Shared";
import { Select } from "components/Shared/Form";

export const FindUnit: React.FC = () => {
  const dispatch = useDispatch();
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { handleFormErrors } = useValidation();

  //armazena as opções do select cidade
  const [cityOptions, setCityOptions] = useState<OptionTypeBase[]>([]);

  //controle de estado selecionado
  const [selectedState, setSelectedState] = useState<OptionTypeBase | null>(
    null
  );

  //controle de cidade selecionada
  const [selectedCity, setSelectedCity] = useState<OptionTypeBase | null>(null);

  // incluindo controle das variaveis de bairro
  const [selectedNeighborhood, setSelectedneighborhood] =
    useState<OptionTypeBase | null>(null);

  //armazena as opções do select bairro
  const [neighborhoodOptions, setNeighborhoodOptions] = useState<
    OptionTypeBase[]
  >([]);

  //constant de controle do botão submit
  const [pressButton, setPressButton] = useState<OptionTypeBase | null>(null);

  const { data: listUnitsData, loading: listUnitsLoading } =
    useSelector<RootStateOrAny>(
      (state) => state.listUnidades
    ) as ListUnidadesState;

  const onSelectUnit = useCallback(
    (route) => {
      history.push(route);
    },
    [history]
  );

  const handleSubmit = useCallback<SubmitHandler>(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          state: Yup.string().required("Obrigatório"),
          city: Yup.string().required("Obrigatório"),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        //setando os estados de controle com os novos valores selecionados, ao invés do objeto contendo o item do select ex. {label:nome, value: nome}
        setSelectedCity(data.city);
        setSelectedneighborhood(data.neighborhood);
        setPressButton(selectedState);
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [handleFormErrors, selectedState]
  );

  const getUnits = useCallback(
    (state) => {
      dispatch(ListUnidadesActions.request({ state }));
    },
    [dispatch]
  );

  const setCities = useCallback(() => {
    const data = listUnitsData.map((unit) => ({
      label: unit.Cidade.toUpperCase(),
      value: unit.Cidade,
    }));

    const uniqueData: any[] = [];
    for (let index = 0; index < data.length; index++) {
      const item = data[index];
      if (
        !uniqueData.some(
          (i) => i.value.toUpperCase() === item.value.toUpperCase()
        )
      ) {
        uniqueData.push(item);
      }
    }
    const uniqueDataOrder = uniqueData.sort((a, b) =>
      a.label > b.label ? 1 : b.label > a.label ? -1 : 0
    );
    setCityOptions(uniqueDataOrder);
  }, [listUnitsData]);

  const setNeighborhoods = useCallback(
    (city) => {
      const neighborhoodList = listUnitsData.filter(
        (item) => item.Cidade === city
      );
      const data = neighborhoodList.map((unit) => ({
        label: unit.Nome.toUpperCase(),
        value: unit.Bairro.toUpperCase(),
      }));

      const uniqueData: any[] = [];
      for (let index = 0; index < data.length; index++) {
        const item = data[index];
        if (
          !uniqueData.some(
            (i) => i.value.toUpperCase() === item.value.toUpperCase()
          )
        ) {
          uniqueData.push(item);
        }
      }
      const uniqueDataOrder = uniqueData.sort((a, b) =>
        a.label > b.label ? 1 : b.label > a.label ? -1 : 0
      );

      setNeighborhoodOptions(uniqueDataOrder);
    },
    [listUnitsData]
  );

  useEffect(() => {
    if (selectedState) {
      getUnits(selectedState.value);
    }
  }, [getUnits, selectedState]);

  useEffect(() => {
    setCities();
  }, [setCities]);

  useEffect(() => {
    if (selectedCity) {
      setNeighborhoods(selectedCity.value);
    }
  }, [setNeighborhoods, selectedCity]);

  const handleChange = useCallback((e: any) => {
    setSelectedState(e);
    setSelectedCity(null);
    setSelectedneighborhood(null);
  }, []);

  const handleResetChoice = useCallback(() => {
    setSelectedState(null);
    setSelectedCity(null);
    setSelectedneighborhood(null);
    setCityOptions([]);
    setPressButton(null);
  }, []);

  const SearchForm: React.FC = () => {
    return (
      <S.Wrapper>
        <S.Title>
          Encontre uma
          <br />
          <span>unidade</span>
        </S.Title>
        <S.Text>
          Selecione o seu estado e a sua cidade e encontre as unidades
          Orthopride mais próximas de você!
        </S.Text>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Select
            name="state"
            label="Selecione seu estado:"
            options={statesOptions}
            onChange={handleChange}
            defaultValue={selectedState}
          />
          <Select
            name="city"
            label="Selecione sua cidade:"
            isDisabled={listUnitsLoading || cityOptions.length === 0}
            isLoading={listUnitsLoading}
            options={cityOptions}
            onChange={(event) => setSelectedCity(event)}
            defaultValue={selectedCity}
          />
          {!listUnitsLoading && selectedState && cityOptions.length === 0 && (
            <S.Warning>
              Nenhuma unidade encontrada para o estado selecionado.
            </S.Warning>
          )}

          <Select
            name="neighborhood"
            label="Selecione uma unidade:"
            isDisabled={listUnitsLoading || selectedCity === null}
            isLoading={listUnitsLoading}
            options={neighborhoodOptions}
            onChange={(event) => setSelectedneighborhood(event)}
            defaultValue={selectedNeighborhood}
          />
          {!listUnitsLoading &&
            selectedState &&
            selectedNeighborhood &&
            neighborhoodOptions.length === 0 && (
              <S.Warning>
                Nenhuma unidade encontrada para o bairro selecionado.
              </S.Warning>
            )}

          <S.SubmitButton>
            Encontrar <S.ArrowRightIcon />
          </S.SubmitButton>
        </Form>
      </S.Wrapper>
    );
  };

  const Units: React.FC = () => {
    return (
      <>
        <S.Wrapper>
          <S.BackButton onClick={handleResetChoice}>
            <S.BackgroundIcon>
              <S.BackIcon />
            </S.BackgroundIcon>
            Voltar
          </S.BackButton>
          <S.Title>
            Unidades encontradas
            <br />
            em <span>{selectedCity}</span>
          </S.Title>
          <S.Text>
            Confira as unidades Orthopride encontradas na cidade de &nbsp;
            <strong>{selectedCity}</strong>, no bairro &nbsp;
            <strong>{selectedNeighborhood}.</strong>
          </S.Text>
        </S.Wrapper>
        <S.UnitsList>
          {selectedCity &&
            selectedNeighborhood &&
            listUnitsData
              .filter(
                (unit) =>
                  unit.Cidade.toUpperCase() === selectedCity.toUpperCase() &&
                  unit.Bairro.toUpperCase() ===
                    selectedNeighborhood.toUpperCase()
              )
              .map((unit) => (
                <UnitCard
                  key={uuidV4()}
                  unit={unit}
                  onSelect={onSelectUnit}
                  avaliacao={false}
                />
              ))}

          {selectedCity &&
            !selectedNeighborhood &&
            listUnitsData
              .filter(
                (unit) =>
                  unit.Cidade.toUpperCase() === selectedCity.toUpperCase()
              )
              .map((unit) => (
                <UnitCard
                  key={uuidV4()}
                  unit={unit}
                  onSelect={onSelectUnit}
                  avaliacao={false}
                />
              ))}
        </S.UnitsList>
      </>
    );
  };

  return (
    <Scaffold>
      <S.Container>
        <S.Aside>
          <S.Row>
            <S.Lineo />
            <S.LineoWrapper>
              <S.LineoText>
                Descubra o alinhador transparente exclusivo da
                <span>Orthopride</span>.
              </S.LineoText>
              <S.LineoButton onClick={() => history.push("/tratamentos")}>
                <S.ChevronIcon />
                Saiba mais
              </S.LineoButton>
            </S.LineoWrapper>
          </S.Row>
        </S.Aside>
        <S.Content>
          {!pressButton && <SearchForm />}
          {pressButton && <Units />}
        </S.Content>
      </S.Container>
      <Footer />
    </Scaffold>
  );
};

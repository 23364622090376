import { call, put } from "redux-saga/effects";

import { api, notify } from "services";
import { ICreateRequest } from "interfaces/create-duck";

import { requestErrorHandler } from "utils";
import { CreateProspectActions } from "store/ducks/prospect";

function sanitizeCpf(data: any) {
  data.Cpf = data.Cpf.replace(/\D/g, ""); // Remove todos os caracteres não numéricos
  return data;
}

export function* CreateProspectRequest(action: any) {
  try {
    const { postData, onSuccess } = action as ICreateRequest;
    const updatedPostData = sanitizeCpf(postData);
    const { data } = yield call(api.post, `/prospects`, postData);
    yield put(CreateProspectActions.success(data));
    if (onSuccess) onSuccess();
    // console.log("Retorno da Criação do Prospect: ", data);
  } catch (error) {
    const apiResponse = error.response;
    const errorMessage = apiResponse.data.ErrorMessage;

    notify("error", errorMessage);

    yield put(CreateProspectActions.failure(errorMessage));
  }
}

interface ApiError {
  response: {
    data: {
      error: {
        type: string;
        message: string;
      };
    };
  };
}

function isApiError(object: any): object is ApiError {
  return "response" in object;
}

export const requestErrorHandler = (error: ApiError | Error | any) => {
  if (isApiError(error)) {
    const { message = "Api error" } = error.response?.data?.error;
    return { errorMessage: message };
  }

  if (error instanceof Error) {
    return { errorMessage: error.message };
  }

  return {
    errorMessage: "Couldn't complete your request",
  };
};

import axios from "axios";
import querystring from "querystring";

export const api = axios.create({
  baseURL: "https://smartinstitucional.orthopride.com.br/api",
  headers: {
    ApiKey: "873dad1b-fd94-44de-9c5d-6d2f99676f16",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
    "Cache-Control": "public,max-age=0",
  },
});

export const apiUnidadesGeral = axios.create({
  baseURL:
    "https://smartinstitucional.orthopride.com.br/api/unidades/tags/orthopride",
  headers: {
    ApiKey: "873dad1b-fd94-44de-9c5d-6d2f99676f16",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
    "Cache-Control": "public,max-age=0",
  },
});

export const apiGoogle = axios.create({
  baseURL: "https://maps.googleapis.com/maps/api",
});

export const apiFoco = axios.create({
  //baseURL: "http://localhost:3333",
  baseURL: "https://orthopride-api.herokuapp.com",
});

export const queryBuilder = (params: Record<string, any>) => {
  const cleanParams: Record<string, any> = {};
  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (value) cleanParams[key] = value;
  });
  return querystring.stringify(cleanParams);
};

export const formDataBuilder = (
  data: Record<string, any>,
  fileFields: string[] = []
) => {
  const formData = new FormData();
  Object.keys(data).forEach((prop) => {
    if (fileFields.includes(prop)) {
      if (!(data[prop] instanceof Blob)) {
        throw new Error(`O valor do campo ${prop} não é do tipo Blob`);
      }
      formData.append(prop, data[prop], data[prop].name);
    } else {
      formData.append(prop, data[prop]);
    }
  });
  return formData;
};

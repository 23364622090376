import React, { useCallback } from "react";
import Slider from "react-slick";
import * as S from "./styles";
import { tips, ITip } from "data/braceTipsStudioLight";
import { scroller } from "react-scroll";

export const BracesTipsLight: React.FC = () => {
  const settings = {
    speed: 500,
    adaptiveHeight: true,
    slidesToShow: 1,
    dots: true,
  };
  const scroll = useCallback((element) => {
    scroller.scrollTo(element, {
      duration: 400,
      delay: 0,
      offset: -90,
    });
  }, []);

  return (
    <S.Container>
      <S.Content>
        <S.Title>
          Veja como Funciona o
          <br />
          <span>Orthopride Whitening</span>.
        </S.Title>
        <S.Wrapper>
          {tips.map((tip: ITip) => (
            <S.Tips key={tip.id}>
              <S.TipsNumber>{tip.number}</S.TipsNumber>
              <S.TipsTitle>{tip.title}</S.TipsTitle>
              <S.Text>{tip.text}</S.Text>
            </S.Tips>
          ))}
        </S.Wrapper>
        <S.Wrapper className="slider-mobile">
          <Slider {...settings}>
            {tips.map((tip: ITip) => (
              <S.Tips key={tip.id}>
                <S.TipsNumber>{tip.number}</S.TipsNumber>
                <S.TipsTitle>{tip.title}</S.TipsTitle>
                <S.Text>{tip.text}</S.Text>
              </S.Tips>
            ))}
          </Slider>
        </S.Wrapper>
        <S.DragText>Arraste para o lado</S.DragText>
        <S.MarcarAvaliacao
          id="formSchedule"
          onClick={() => scroll("FormAgenda")}
        >
          Agendar Avaliação
        </S.MarcarAvaliacao>
      </S.Content>
    </S.Container>
  );
};

import styled from "styled-components";
import logoWhitening from "assets/images/Studio/whitening-new.png";
import WhiteningLogoLeft from "assets/images/Studio/whiteningLogo.png";
import { ChevronDown } from "@styled-icons/boxicons-solid/ChevronDown";
import { colors, fonts } from "styles";

export const Container = styled.section`
  position: relative;
  width: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  justify-content: flex-start;

  background: rgb(219, 211, 252);
  background: linear-gradient(
    132deg,
    rgba(219, 211, 252, 1) 0%,
    rgba(241, 237, 254, 1) 27%,
    rgba(247, 245, 255, 1) 46%,
    rgba(255, 255, 255, 1) 94%
  );

  @media screen and (max-width: 110px) {
    width: 100%;
    padding: 0px;
    background: rgb(219, 211, 252);
    background: linear-gradient(
      180deg,
      rgba(219, 211, 252, 1) 0%,
      rgba(241, 237, 254, 1) 19%,
      rgba(247, 245, 255, 1) 31%,
      rgba(255, 255, 255, 1) 94%
    );
    background-size: cover;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  min-height: 500px;
  align-items: flex-start;
  padding-top: 72px;
  @media screen and (max-width: 1100px) {
    flex-direction: column;
    padding-top: 0;
    margin-top: 0;
  }
`;

export const LeftSize = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 50%;
  min-height: 550px;

  @media screen and (max-width: 1100px) {
    width: 100%;
    justify-content: center;
    min-height: 450px;
  }
`;
export const WhiteningLogo = styled.img.attrs({ src: logoWhitening })`
  width: 100%;
  max-width: 529px;
  height: auto;
  padding: 0 35px;
  @media screen and (max-width: 1100px) {
    padding: 15px;
  }
`;

export const RightSize = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 132px;
  min-height: 550px;
  @media screen and (max-width: 1100px) {
    width: 100%;
    align-items: center;
    padding-left: 0;
  }
`;
export const WhiteningLogoLefty = styled.img.attrs({ src: WhiteningLogoLeft })`
  width: 100%;
  max-width: 235px;
  height: auto;
  @media screen and (max-width: 1100px) {
    padding: 60px 0 0 0;
  }
`;

export const Title = styled.h4`
  font-family: ${fonts.BrandontextBold};
  font-size: 20px;
  text-transform: uppercase;
  color: ${colors.dark};
  margin: 40px 0 24px 0;
  span {
    color: #f58226;
  }
  @media screen and (max-width: 1100px) {
    margin: 31px 0 24px 0;
  }
`;

export const Text = styled.p`
  width: 100%;
  max-width: 460px;
  font-family: ${fonts.SourceSansProRegular};
  font-size: 18px;
  text-align: left;
  @media screen and (max-width: 1100px) {
    width: 95%;
    max-width: 327px;
    text-align: center;
  }
`;

export const MarcarAvaliacao = styled.button`
  font-family: ${fonts.BrandontextBold};
  font-size: 18px;
  background: ${colors.orange};
  width: 216px;
  color: #fff;
  border-radius: 3px;
  padding: 18px 22px;
  margin: 40px 0 96px 0;
  @media screen and (max-width: 1100px) {
    margin: 40px 0 80px 0;
  }
`;

export const GroupChevy = styled.div`
  position: relative;
  width: 71px;
  margin: 0 auto;
`;

export const ButtonChevy = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  bottom: -50px;
  background: ${colors.white};
  opacity: 0.8;

  width: 100px;
  height: 100px;
  border-radius: 50%;
`;

export const ChevDown = styled(ChevronDown)`
  color: ${colors.dark};
  cursor: pointer;
`;

import { combineReducers } from "redux";
import {
  listUnidades,
  listUnidadesTags,
  fetchUnidade,
  fetchTagIndex,
  geolocationUnidade,
} from "./unidades";
import { createProspect } from "./prospect";
import { listBooking, createBooking } from "./booking";
import { listCities } from "./city";
import { createContact } from "./contact";
import { createOuvidoria } from "./ouvidoria";
import { createFranchisingContact } from "./franchising";
import { createWhatsappContact } from "./whatsappContact";
import { chkcpf } from "./booking";
import {
  fetchPaciente,
  sorteioAtivo,
  participarSorteio,
} from "./parcela-premiada";
import { campaign } from "./campaign";
import { cpf } from "utils";

const reducers = combineReducers({
  listUnidades,
  listUnidadesTags,
  fetchUnidade,
  fetchTagIndex,
  geolocationUnidade,
  createProspect,
  listBooking,
  createBooking,
  listCities,
  createContact,
  createFranchisingContact,
  createWhatsappContact,
  chkcpf,
  createOuvidoria,
  fetchPaciente,
  sorteioAtivo,
  participarSorteio,
  campaign,
});

export default reducers;

import React from "react";

import * as S from "./styles";

interface Props {
  text?: string;
  color?: string;
}

export const LegalText: React.FC<Props> = ({ text, color }) => {
  return (
    <S.Text color={color}>
      {text || "Clínico geral atuando em ortodontia e estética"}
    </S.Text>
  );
};

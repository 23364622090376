import React, { useState, useEffect } from "react";
import * as S from "./styles";

// Define a interface para o conteúdo do array proof
interface Proof {
  text: string;
  name: string;
}

// Custom Hook para obter a resolução da tela
const useScreenResolution = () => {
  const [screenResolution, setScreenResolution] = useState(() => ({
    width: window.innerWidth,
    height: window.innerHeight,
  }));

  useEffect(() => {
    // Função para atualizar a resolução da tela
    const updateResolution = () => {
      setScreenResolution({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Adiciona o event listener para o evento resize
    window.addEventListener("resize", updateResolution);

    // Executa a função imediatamente ao montar o componente
    updateResolution();

    // Remove o event listener quando o componente é desmontado
    return () => {
      window.removeEventListener("resize", updateResolution);
    };
  }, []);

  return screenResolution;
};

export const SocialProof: React.FC = () => {
  const proof: Proof[] = [
    {
      text: "A holding ajuda na montagem da clínica, desde a escolha dos pontos até a escolha das pessoas...",
      name: "Dr. Leonardo",
    },
    {
      text: "É um grande negócio, tem muita rentabilidade em pouco tempo e é um negócio para o resto da vida...",
      name: "Carlos",
    },
    {
      text: "E não deu outra, em um curto período de tempo, o plano B virou plano AA...",
      name: "Marcelo",
    },
    {
      text: "É um negócio em que você pode trazer vários investidores e abrir várias unidades, assim você tem um crescimento rápido sem usar todo o capital que você tem disponível...",
      name: "Petras",
    },
    {
      text: "O app no celular é muito bom, porque você acompanha todos os números da clínica..",
      name: "Dr. Fred",
    },
  ];

  // Estado para armazenar o índice atual
  const [currentIndex, setCurrentIndex] = useState(0);

  // Função para incrementar o índice atual
  const incrementIndex = (): void => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % proof.length);
  };

  // Função para decrementar o índice atual
  const decrementIndex = (): void => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + proof.length) % proof.length
    );
  };

  // Utiliza o hook para obter a resolução da tela
  const { width } = useScreenResolution();

  return (
    <>
      <S.Container>
        <S.GroupCards>
          <S.ArrowLeftImg alt="Anterior" onClick={decrementIndex} />
          {proof.length > 0 && (
            <>
              <S.Card>
                <S.GroupStars>
                  <S.EmptyStar size={25} />
                  <S.EmptyStar size={25} />
                  <S.EmptyStar size={25} />
                  <S.EmptyStar size={25} />
                  <S.EmptyStar size={25} />
                </S.GroupStars>
                <p>"{proof[currentIndex].text}"</p>
                <p className="sign">{proof[currentIndex].name}</p>
              </S.Card>
              {width > 730 && (
                <S.Card>
                  <S.GroupStars>
                    <S.EmptyStar size={25} />
                    <S.EmptyStar size={25} />
                    <S.EmptyStar size={25} />
                    <S.EmptyStar size={25} />
                    <S.EmptyStar size={25} />
                  </S.GroupStars>
                  <p>"{proof[(currentIndex + 1) % proof.length].text}"</p>
                  <p className="sign">
                    {proof[(currentIndex + 1) % proof.length].name}
                  </p>
                </S.Card>
              )}
            </>
          )}
          <S.GroupArrows>
            <S.ArrowLeftImgMobile alt="Anterior" onClick={decrementIndex} />
            <S.ArrowRightImg alt="Próximo" onClick={incrementIndex} />
          </S.GroupArrows>
        </S.GroupCards>
      </S.Container>
    </>
  );
};

import styled from "styled-components";
import { LocationPin } from "@styled-icons/entypo";
import { colors, fonts, colorScheme } from "styles";
import desktop from "../../../assets/images/BannerNovember/desktop.jpg";
import mobile from "../../../assets/images/BannerNovember/mobile.jpg";
export { Button } from "styles/styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 1920px;
  height: auto;
  background: #000;
  @media screen and (max-width: 950px) {
    flex-direction: column;
    height: auto;
    margin-top: -30px;
  }
`;

export const DesktopBanner = styled.img.attrs({ src: desktop })`
  width: 100%;
  height: auto;
  z-index: 2;
  left: 20px;
  z-index: 1;
  @media screen and (max-width: 950px) {
    display: none;
  }
`;

export const Wrapper = styled.div`
  position: absolute;
  right: 20%;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 440px;
  z-index: 2;
  @media screen and (max-width: 1220px) {
    right: 15%;
  }
  @media screen and (max-width: 1070px) {
    right: 5%;
  }
  @media screen and (max-width: 950px) {
    display: none;
  }
`;

export const Title = styled.h1`
  font-size: 69px;
  line-height: 69px;
  font-family: ${fonts.BrandontextBold};
  color: ${colorScheme.primary};
  text-transform: uppercase;
  margin-bottom: 32px;

  span {
    font-family: ${fonts.BrandontextLight};
    color: ${colors.white};
  }

  @media screen and (max-width: 1126px) {
    text-align: center;
    color: white;
  }

  @media screen and (max-width: 1220px) {
    font-size: 50px;
    line-height: 50px;
  }
  @media screen and (max-width: 1070px) {
    font-size: 40px;
    line-height: 40px;
  }
`;

export const Text = styled.article`
  font-size: 18px;
  color: ${colors.white};
  line-height: 24px;

  @media screen and (max-width: 1126px) {
    text-align: center;
    color: white;
  }
  @media screen and (max-width: 1070px) {
    font-size: 16px;
    max-width: 400px;
  }

  @media screen and (max-width: 414px) {
    font-size: 16px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 40px 0;

  button:first-child {
    margin-right: 16px;
  }

  @media screen and (max-width: 1126px) {
    align-self: center;
  }

  @media screen and (max-width: 414px) {
    flex-direction: column;

    button:first-child {
      margin-right: 0;
      margin-bottom: 16px;
    }

    button {
      width: 100%;
    }
  }
`;

export const MobileBanner = styled.img.attrs({ src: mobile })`
  display: none;
  width: 100%;
  height: auto;
  @media screen and (max-width: 950px) {
    display: flex;
  }
`;

export const CtaMobile = styled.a`
  position: absolute;
  bottom: 60px;
  display: none;
  align-items: center;
  justify-content: center;
  padding: 15px;
  font-family: ${fonts.BrandontextRegular};
  background: #ff8100;
  text-transform: uppercase;
  letter-spacing: -1px;
  color: #000;
  text-decoration: none;
  width: max-content;
  margin: 30px auto;
  z-index: 3;
  border-radius: 20px;
  cursor: pointer;
  span {
    font-family: ${fonts.BrandontextBold};
  }
  @media screen and (max-width: 950px) {
    display: flex;
  }
`;

import { put } from "redux-saga/effects";

import { notify } from "services";

import { requestErrorHandler } from "utils";
import { FetchTagIndexActions } from "store/ducks/unidades";

export function* FetchTagIndexRequest(action: any) {
  try {
    const { query = { tag: action.id }, onSuccess } = action;
    // const { data } = query;
    yield put(FetchTagIndexActions.success(query.tag));
    if (onSuccess) onSuccess(query.tag);
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(FetchTagIndexActions.failure(errorMessage));
  }
}

import React from "react";
import * as S from "./styles";

export const Banner: React.FC = () => {
  return (
    <>
      <S.Container>
        <S.GroupText>
          <h2>
            <span>Seja um</span>
            <br />
            <span>
              franqueado
              <br />
              Orthopride
            </span>
            <br />
            e fature até
            <br />
            <span>R$4 Milhões</span>
            <br />
            por ano, com
            <br />
            lucro médio
            <br />
            de 25%
          </h2>
        </S.GroupText>
        <S.Modalbg></S.Modalbg>
      </S.Container>
    </>
  );
};

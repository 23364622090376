import * as S from "./styles";
export const SponsorsFullPage: React.FC = () => {
  return (
    <>
      <S.Container>
        <S.GroupElements>
          <S.Subtitle>Patrocinadora oficial</S.Subtitle>
          <S.Title>Apaixonados por Esporte</S.Title>
          <S.Text>
            Patrocinamos o esporte porque acreditamos que ele leva alegria para
            as pessoas e, acima de tudo, acreditamos na força do sorriso.
          </S.Text>
          <S.GroupTeam>
            <S.LogoFlu />
          </S.GroupTeam>
        </S.GroupElements>
      </S.Container>
    </>
  );
};

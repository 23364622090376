import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { FetchUnidadeActions, FetchUnidadeState } from "store/ducks/unidades";
import TagManager from "react-gtm-module";
import * as S from "./styles";
import { Scaffold, Footer, Booking } from "components/Shared";
import { Banner, Map, Treatments } from "components/Unit";

interface IParams {
  sigla: string;
  name?: string;
  state?: string;
}

export const UnitDetails: React.FC = () => {
  const { sigla, name, state } = useParams<IParams>();
  const upperCaseUnitState = state?.toUpperCase();
  const history = useHistory();
  const dispatch = useDispatch();
  const { data, loading } = useSelector<RootStateOrAny>(
    (state) => state.fetchUnidade
  ) as FetchUnidadeState;

  const handlePhone = useCallback((link) => {
    window.open(`tel:${link}`);
  }, []);

  const handleWhatsapp = useCallback((link) => {
    const whats = link.replace(/[^0-9]/g, "");
    window.open(`https://wa.me/55${whats}`);
  }, []);

  const fetchData = useCallback(() => {
    dispatch(FetchUnidadeActions.request(sigla));
  }, [dispatch, sigla]);

  const resetData = useCallback(() => {
    dispatch(FetchUnidadeActions.reset());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
    return () => resetData();
  }, [fetchData, resetData]);

  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/unidade/LRA/limeira") {
      const tagManagerArgs = {
        gtmId: "GTM-WFRTGWMM",
      };
      TagManager.initialize(tagManagerArgs);
    }
  }, [location]);

  return (
    <Scaffold>
      <Banner unitName={data?.Nome} />
      <S.Container>
        <S.Content>
          {loading ? (
            <S.Loading />
          ) : (
            <S.BackButton onClick={() => history.push("/encontre-uma-unidade")}>
              <S.BackgroundIcon>
                <S.BackIcon />
              </S.BackgroundIcon>
              Voltar
            </S.BackButton>
          )}
          <S.WrapperInfo>
            <S.Column>
              <S.TitleUnit>
                Unidade
                <br />
                <span>{data?.Nome}</span>
              </S.TitleUnit>
              <S.Text>{data?.Endereco}</S.Text>
            </S.Column>
            <S.Column className="phones">
              <S.PhoneTitle>Telefone{data?.Telefone2 && "s"}</S.PhoneTitle>
              {(data?.Telefone1 || data?.Telefone2) && (
                <S.WrapperPhones>
                  {data?.Telefone1 && (
                    <S.ButtonPhone
                      onClick={() => handlePhone(data?.Telefone1)}
                      disabled={loading}
                    >
                      <S.BackgroundPhoneIcon>
                        <S.PhoneIcon />
                      </S.BackgroundPhoneIcon>
                      {data?.Telefone1}
                    </S.ButtonPhone>
                  )}
                  {data?.Telefone2 && (
                    <S.ButtonPhone
                      onClick={() => handlePhone(data?.Telefone2)}
                      disabled={loading}
                    >
                      <S.BackgroundPhoneIcon>
                        <S.PhoneIcon />
                      </S.BackgroundPhoneIcon>
                      {data?.Telefone2}
                    </S.ButtonPhone>
                  )}
                  {data?.TelefoneWhatsApp && (
                    <S.ButtonPhone
                      onClick={() => handleWhatsapp(data?.TelefoneWhatsApp)}
                      disabled={loading}
                    >
                      <S.BackgroundPhoneIcon>
                        <S.WhatsappIcon />
                      </S.BackgroundPhoneIcon>
                      {data?.TelefoneWhatsApp}
                    </S.ButtonPhone>
                  )}
                </S.WrapperPhones>
              )}
              <S.ResponsibleTitle>Responsável Técnico</S.ResponsibleTitle>
              {location.pathname === "/unidade/ITT/itatiba" ? (
                <S.GroupInfo>
                  <p>* Especialistas em ortodontia. </p>
                  <p className="second">
                    *Clínico geral com atuação em ortodontia.
                  </p>
                </S.GroupInfo>
              ) : null}
              <S.Text>
                {data?.Responsavel && <p>{`${data?.Responsavel}`}</p>}
                <p>CRO: {data?.CroResponsavel}</p>
                <p>EPAO: {data?.Epao}</p>
              </S.Text>
            </S.Column>
          </S.WrapperInfo>
        </S.Content>
        <Map GooglePlaceId={data?.GooglePlaceId} />
      </S.Container>
      <Treatments />
      <Booking unitName={name} unitState={upperCaseUnitState} />
      <Footer />
    </Scaffold>
  );
};

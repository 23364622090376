import React from "react";

import * as S from "./styles";

export const Banner: React.FC = () => {
  return (
    <S.Container>
      <S.Wrapper>
        <S.Logo />
        <S.Title>
          Ortodontia <span>e estética</span>
        </S.Title>
        <S.Text>
          A Orthopride nasceu para oferecer a você mais qualidade de vida, mais
          saúde e muitos sorrisos no seu dia a dia!
        </S.Text>
      </S.Wrapper>
    </S.Container>
  );
};

import React from "react";
import * as S from "./styles";

export const Differences: React.FC = () => {
  return (
    <>
      <S.Container>
        <S.ImgMedina alt="Medina" />
        <S.GroupInfo>
          <h2 className="title">
            <span>Diferenciais</span>
            <br />
            da franquia
            <br />
            Orthopride
          </h2>
          <S.ListItems>
            <li className="item">
              <S.Chev />
              <div>Mais de 250 mil pacientes ativos</div>
            </li>
            <li className="item">
              <S.Chev />
              <div>Cerca de 10 mil consultas ao dia na rede</div>
            </li>
            <li className="item">
              <S.Chev />
              <div>Nenhum caso de falência</div>
            </li>

            <li className="item">
              <S.Chev />
              <div>Maior ticket médio do mercado de ortodontia</div>
            </li>
            <li className="item">
              <S.Chev />
              <div>Software próprio de gestão da unidade</div>
            </li>
          </S.ListItems>
        </S.GroupInfo>
      </S.Container>
    </>
  );
};

import React from "react";
import { v4 as uuidV4 } from "uuid";
import Slider from "react-slick";

import * as S from "./styles";
import { BoxTreatment } from "components/Shared";

export const Treatments: React.FC = () => {
  const settings = {
    slidesToShow: 4,
    speed: 500,
    adaptiveHeight: true,
    nextArrow: (
      <S.NextButton>
        <S.IconNext />
      </S.NextButton>
    ),
    prevArrow: (
      <S.PrevButton>
        <S.IconPrev />
      </S.PrevButton>
    ),
    responsive: [
      {
        breakpoint: 1160,
        settings: {
          slidesToShow: 3,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          dots: true,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          dots: true,
          centerMode: true,
        },
      },
      {
        breakpoint: 415,
        settings: {
          slidesToShow: 1,
          dots: true,
          centerMode: false,
        },
      },
    ],
  };
  const treatments = [
    {
      image: <S.IconAparelho />,
      title: "Aparelho ortodôntico",
      text: "Corrige a posição da dentição, gera uma mordida alinhada, facilita a higienização e melhora a mastigação. Protegendo toda a estrutura dental.",
    },
    {
      image: <S.IconRaspagem />,
      title: "Raspagem de tártaro",
      text: "Remove a placa bacteriana calcificada (tártaro), deixando o dente liso e polido, evitando sangramento e devolvendo a saúde periodontal.",
    },
    {
      image: <S.IconLineo />,
      text: "Alinhador transparente exclusivo da Orthopride. A tecnologia é um avanço para o tratamento ortodôntico, principalmente para pacientes que preferem um aparelho mais discreto e moderno.",
      isNew: true,
    },
    {
      image: <S.IconOrtho />,
      text: "O kit de clareamento dentário Orthopride Whitening inclui uma moldeira de LED e 3 canetas com gel clareador, que facilitam a aplicação do produto devido à sua praticidade. Para realizar o procedimento, é necessário agendar uma avaliação com seu dentista.",
      isNew: true,
    },
    {
      image: <S.IconClareamento />,
      title: "Clareamento dental",
      text: "Já conhece o clareamento da Orthopride? Nosso tratamento remove as pigmentações que amarelam ou escurecem os dentes, mudando rapidamente a cor e a estética, deixando o sorriso mais claro e bonito. Para realizar este procedimento, é necessário agendar uma avaliação com o seu dentista",
    },
    {
      image: <S.IconPrevencao />,
      title: "Prevenção Kids",
      text: "Responsável por prevenir o acúmulo de placa bacteriana, a escovação e o uso do fio dental são as melhores formas de manter uma boa higiene bucal.",
    },
  ];
  return (
    <S.Container>
      <S.Content>
        <S.Title>
          Escolha seu <br />
          <span>tratamento</span> dentário
        </S.Title>

        <S.Text>
          Garanta um sorriso perfeito com os tratamentos odontológicos da
          Orthopride.
        </S.Text>
        <S.Wrapper>
          <Slider {...settings}>
            {treatments.map((treatment) => (
              <S.WrapperSlide>
                <BoxTreatment key={uuidV4()} data={treatment} />
              </S.WrapperSlide>
            ))}
          </Slider>
        </S.Wrapper>
      </S.Content>
    </S.Container>
  );
};

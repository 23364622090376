import { v4 as uuidV4 } from "uuid";

export interface ITip {
  id: string;
  number: number;
  title: string;
  text: string;
}

export const tips: Array<ITip> = [
  {
    id: uuidV4(),
    number: 1,
    title: "Primeiros dias de uso",
    text:
      "Nos primeiros dias, a mucosa dos lábios poderá ficar sensível pelo seu atrito com os bráquetes. Para diminuir o desconforto, coloque um pedaço de cera (que lhe será fornecido) sobre a peça que estiver incomodando.",
  },
  {
    id: uuidV4(),
    number: 2,
    title: "Disciplinar os hábitos",
    text:
      "As colagens resistem às forças geradas para movimentar os dentes, mas não suportam exageros – quando se morde alimentos com força ou objetos duros. Portanto, fica proibido mastigar alimentos como gelo, coco, doces duros e chicletes.",
  },
  {
    id: uuidV4(),
    number: 3,
    title: "Higiene bucal",
    text:
      "Para garantir a sua saúde bucal é fundamental uma higienização eficiente. Com o uso do aparelho ortodôntico, esse processo se torna ainda mais importante e um pouco mais demorado.",
  },
  {
    id: uuidV4(),
    number: 4,
    title: "Evite as manchas",
    text:
      "Café, chocolate, chá, refrigerante e açaí podem deixar os dentes amarelados, assim como o consumo de tabaco (cigarro) também pode provocar pigmentação.",
  },
];

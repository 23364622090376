import styled from "styled-components";
import { motion } from "framer-motion";
import { colors, fonts } from "styles";
import symbol from "assets/images/franchinsing-symbol.svg";
import mina from "assets/images/franchinsing-mina.png";
import firula from "assets/images/franchising-firula.svg";

export const BackgroundContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  background-color: white;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1100px;
  padding: 88px 32px 0 32px;
  display: flex;
  margin: 0 auto;
  border-bottom: 1px #d2d8e0 solid;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }

  @media screen and (max-width: 414px) {
    padding-top: 48px;
    padding-bottom: 48px;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-bottom: 72px;

  @media screen and (max-width: 900px) {
    max-width: 100%;
  }

  @media screen and (max-width: 414px) {
    padding-bottom: 48px;
  }
`;

export const Symbol = styled(motion.img).attrs({ src: symbol })`
  width: 100%;
  max-width: max-content;
  position: relative;
  z-index: 1;
`;

export const Mina = styled(motion.img).attrs({ src: mina })`
  width: 100%;
  max-width: max-content;
  position: absolute;
  z-index: 2;
`;

export const Firula = styled(motion.img).attrs({ src: firula })`
  width: 100%;
  max-width: max-content;
  position: absolute;
  top: 20px;
  left: -80px;
`;

export const Title = styled.h3`
  font-size: 48px;
  line-height: 48px;
  color: ${colors.dark};
  margin-bottom: 32px;

  span {
    font-family: ${fonts.BrandontextLight};
  }

  @media screen and (max-width: 900px) {
    text-align: center;
  }

  @media screen and (max-width: 414px) {
    font-size: 30px;
    line-height: 30px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 450px;
  position: relative;
`;

export const Text = styled.article`
  font-size: 18px;
  line-height: 24px;

  p {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 900px) {
    text-align: center;
  }

  @media screen and (max-width: 414px) {
    font-size: 16px;
  }
`;

import React, { useRef, useCallback, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useHistory } from "react-router-dom";
import { OptionTypeBase } from "react-select";
import { Form } from "@unform/web";
import { FormHandles, SubmitHandler } from "@unform/core";
import * as Yup from "yup";

import { useValidation } from "hooks";
import { statesOptions } from "utils";

import { ListUnidadesState, ListUnidadesActions } from "store/ducks/unidades";
import { CreateContactActions, CreateContactState } from "store/ducks/contact";

import { IUnidade } from "interfaces/unidade";

import { Scaffold, Footer } from "components/Shared";
import { Banner } from "components/Contact";
import { Input, Select, Textarea } from "components/Shared/Form";
import * as S from "./styles";

const subjects = [
  { label: "Informações", value: "informacoes" },
  { label: "Sugestão", value: "sugestao" },
  { label: "Solicitação", value: "solicitacao" },
  { label: "Reclamação", value: "reclamacao" },
  { label: "Elogio", value: "reclamacao" },
];

export const Contact: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [cityOptions, setCityOptions] = useState<OptionTypeBase[]>([]);
  const [units, setUnits] = useState<OptionTypeBase[]>([]);
  const { handleFormErrors } = useValidation();

  const { data: listUnitsData, loading: listUnitsLoading } =
    useSelector<RootStateOrAny>(
      (state) => state.listUnidades
    ) as ListUnidadesState;

  const { loading: contactLoading } = useSelector<RootStateOrAny>(
    (state) => state.createContact
  ) as CreateContactState;

  const setCities = useCallback((unitsData) => {
    const data = unitsData.map((unit: IUnidade) => ({
      label: unit.Cidade,
      value: unit.Cidade,
    }));

    const uniqueData: any[] = [];

    for (let index = 0; index < data.length; index++) {
      const item = data[index];
      if (!uniqueData.some((i) => i.value === item.value)) {
        uniqueData.push(item);
      }
    }
    setCityOptions(uniqueData);
  }, []);

  const getUnits = useCallback(
    (option) => {
      dispatch(ListUnidadesActions.request({ state: option.value }, setCities));
    },
    [dispatch, setCities]
  );

  const unitsOptions = useCallback(
    (option) => {
      const data = listUnitsData
        .filter((unit) => unit.Cidade === option.value)
        .map((unit) => ({
          label: unit.Nome,
          value: unit.Sigla,
        }));

      setUnits(data);
    },
    [listUnitsData]
  );

  const onSuccess = useCallback(
    (data) => {
      history.push({
        pathname: "/contato-obrigado",
        state: data,
      });
    },
    [history]
  );

  const handleSubmit = useCallback<SubmitHandler>(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          state: Yup.string().required("Obrigatório"),
          city: Yup.string().required("Obrigatório"),
          unit: Yup.string().required("Obrigatório"),
          subject: Yup.string().required("Obrigatório"),
          name: Yup.string().required("Obrigatório"),
          email: Yup.string().required("Obrigatório"),
          phone: Yup.string().required("Obrigatório"),
          message: Yup.string().required("Obrigatório"),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        dispatch(CreateContactActions.request(data, onSuccess));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, handleFormErrors, onSuccess]
  );

  return (
    <Scaffold>
      <Banner />
      <S.Container>
        <S.Aside>
          <S.Title>Fale com a gente</S.Title>
          <S.Text>
            <p>
              Este é o nosso canal de comunicação com você. Nos envie sua
              dúvida, sugestão ou reclamação aqui que entraremos em contato.
            </p>
          </S.Text>
          <S.Box>
            <S.ThopSmall />
            <S.BoxTitle>
              deseja tirar alguma dúvida com uma unidade próxima?
            </S.BoxTitle>
            <S.FindUnitButton
              onClick={() => history.push("/encontre-uma-unidade")}
            >
              <S.ChevronIcon />
              Encontre sua Unidade aqui
            </S.FindUnitButton>
          </S.Box>
        </S.Aside>
        <S.Content>
          <S.Text>
            Preencha o formulário abaixo para nos mandar uma mensagem:
          </S.Text>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Select
              name="state"
              label="Estado: "
              options={statesOptions}
              onChange={(e) => getUnits(e)}
            />
            <S.Row border>
              <Select
                name="city"
                label="Cidade:"
                isDisabled={listUnitsLoading || cityOptions.length === 0}
                isLoading={listUnitsLoading}
                options={cityOptions}
                onChange={(e) => unitsOptions(e)}
              />
              <Select
                name="unit"
                label="Unidade:"
                options={units}
                isDisabled={units.length === 0}
              />
            </S.Row>
            <Select name="subject" label="Assunto:" options={subjects} />
            <Input name="name" label="Nome e sobrenome:" />
            <Input name="email" label="E-mail:" />
            <Input name="phone" label="Celular:" />
            <Textarea name="message" label="Descreva o ocorrido:" rows="5" />
            <S.Button>{contactLoading ? <S.Loading /> : "Enviar"}</S.Button>
          </Form>
        </S.Content>
      </S.Container>
      <Footer />
    </Scaffold>
  );
};

import React from "react";
import * as S from "./styles";
// import { Banner } from 'components/Studio/Banner';

import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { NewBanner } from "components/Studio/NewBanner";
import { Gallery } from "components/Studio/Gallery";
import { BracesTips } from "components/Studio/BracesTips";
import { BracesTipsLight } from "components/Studio/BracesTipsLight";
import { AdvancedTecnology } from "components/Studio/AdvancedTecnology";
import { Whitening } from "components/Studio/Whitening";
import { NewBooking } from "components/Studio/NewBooking";
import { Tour } from "components/Studio/Tour";
import { UnitsStudio } from "../Booking/BookingFindUnitByStudio";
import { Element } from "react-scroll";
import { Scaffold, Footer } from "components/Shared";

import { FetchTagIndexActions } from "store/ducks/unidades";
import { useEffect } from "react";

export const Studio: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(FetchTagIndexActions.request("general"));
  }, [dispatch]);
  return (
    <Scaffold>
      <S.Container>
        <NewBanner />
        <Tour />
        {/* <Gallery /> */}
        <AdvancedTecnology />
        <BracesTips />
        <Whitening />
        <BracesTipsLight />
        <Element name="FormAgenda" />
        {/* <NewBooking /> */}
        <UnitsStudio />
        <Footer />
      </S.Container>
    </Scaffold>
  );
};

import styled from "styled-components";
import { colorScheme, fonts } from "styles";
import { ChevronCircleRight } from "@styled-icons/fluentui-system-filled/ChevronCircleRight";

interface MyTagProps {
  bg: string;
}
interface ArrowColor {
  item_color: string;
}

export const Cointainer = styled.section`
  width: 100%;
  height: auto;
  background: transparent;
`;

export const GroupText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 200px;
  background: #fff;
  align-items:center;
  h2 {
    width:95%;
    font-family: ${fonts.BrandontextBold};
    font-size:46px;
    max-width:568px;
    color:#353941;
    margin:40px 0;
    text-align:center;
    letter-spacing: -1px;
    line-height:1em;
    @media screen and (max-width: 900px) {
    font-size:xx-large;
  }
  }
  p {
    font-family: ${fonts.SourceSansProRegular}
    font-size: 18px;
    text-align:center;
    max-width: 586px;
    margin-bottom:50px;
  }
`;

export const AreaCards = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  /* max-width: 1365px; */
  background: linear-gradient(to bottom, #fff 20%, #efefef 20%);
  margin: 0 auto;
  padding-bottom: 100px;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;
export const Card = styled.div.attrs((props: MyTagProps) => ({
  bg: props.bg,
}))<MyTagProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 380px;
  min-height: 100%;
  background: ${(props) => props.bg};
  img.label-orange {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    background: #f09327;
  }
  img.label-purple {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    background: #9032c0;
  }
  @media screen and (max-width: 1435px) {
    max-width: 440px;
  }
  @media screen and (max-width: 1370px) {
    max-width: 400px;
  }
  @media screen and (max-width: 1250px) {
    max-width: 370px;
    min-height: 1050px;
    border: 1px solid #000;
  }
  @media screen and (max-width: 1160px) {
    max-width: 300px;
    min-height: 1150px;
  }
  @media screen and (max-width: 900px) {
    max-width: 90%;
    min-height: 500px;
    height: auto;
    margin: 10px auto;
  }
`;

export const GroupImage = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  h2.title {
    position: absolute;
    top: 30px;
    left: 30px;
    font-family: ${fonts.BrandontextBold};
    font-size: xx-large;
    color: #fff;
    max-width: 90%;
    line-height: 1em;
    @media screen and (max-width: 1160px) {
      font-size: x-large;
    }
    @media screen and (max-width: 900px) {
      font-size: 60px;
    }
    @media screen and (max-width: 753px) {
      font-size: 50px;
    }
    @media screen and (max-width: 632px) {
      font-size: xx-large;
    }
  }
`;

export const GroupCardText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center !important;
  justify-content: flex-start;
  width: 95%;
  min-height: 550px;
  margin: 0 auto;
  padding: 35px 10px;
  margin-bottom: 0px;

  p {
    font-family: ${fonts.BrandontextRegular};
    font-size: 17px;
    color: #fff;
    margin: 0 auto 15px auto;
    line-height: 1.5em;
  }
  ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 15px;
    list-style: inside;
    margin-left: 0px;
  }
  ul li {
    color: #fff;
    margin-bottom: 3px;
  }
  @media screen and (max-width: 1250px) {
    min-height: 600px;
  }
  @media screen and (max-width: 1160px) {
    min-height: 800px;
  }
  @media screen and (max-width: 900px) {
    min-height: 200px;
  }
`;

export const CallToAction = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px auto;
  font-family: ${fonts.BrandontextMedium};
  font-size: x-large;
  color: #fff;
  width: 90%;
  max-width: 250px;
  cursor: pointer;
  a {
    position: absolute;
    top: -50px;
    left: 1%;
  }
`;

export const ChevronR = styled(ChevronCircleRight).attrs(
  (props: ArrowColor) => ({ item_color: props.item_color })
)<ArrowColor>`
  color: ${(props) => props.item_color};
  margin-right: 5px;
`;

export const GroupCall = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-end;
  justify-content: center;
  flex: 1;
`;

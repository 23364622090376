import React from "react";
import { Link } from "react-router-dom";

import * as S from "./styles";

export const Surfers: React.FC = () => {
  return (
    <>
      <S.Container>
        <Link to="/surf-thop-team">
          {/* <S.Text>Acesse nosso instagram para saber mais!</S.Text> */}
          <S.ImgSurfers alt="Surfistas" />
        </Link>
      </S.Container>
    </>
  );
};

import styled from "styled-components";
import { ArrowLeft2 } from "@styled-icons/icomoon";
import { PhoneAlt } from "@styled-icons/fa-solid";
import { Whatsapp } from "@styled-icons/remix-fill";
import { colors, fonts, colorScheme } from "styles";

export { Loading } from "styles/styled-components";

export const Container = styled.section`
  width: 100%;
  margin: 0 auto;
`;

export const Content = styled.section`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 88px 32px;

  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const BackgroundIcon = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.dark};
  margin-right: 16px;
  transition: 300ms ease;
`;

export const BackButton = styled.button.attrs({ type: "button" })`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.dark};
  margin-bottom: 48px;
  text-transform: uppercase;
  font-size: 14px;
  font-family: ${fonts.BrandontextBold};

  :hover {
    ${BackgroundIcon} {
      transform: translateX(-4px);
    }
  }
`;

export const BackIcon = styled(ArrowLeft2).attrs({ size: 20 })`
  color: white;
`;

export const WrapperInfo = styled.div`
  width: 100%;
  display: flex;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  &:first-child {
    margin-right: 80px;
  }

  &.phones {
    padding-left: 40px;
    border-left: 1px #b9b9b9 solid;
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;

    &:first-child {
      margin-right: 0;
      margin-bottom: 32px;
    }

    &.phones {
      padding-left: 0;
      border-left: 0;
    }
  }
`;

export const TitleUnit = styled.h1`
  font-size: 46px;
  line-height: 46px;
  color: ${colors.dark};
  text-transform: uppercase;
  margin-bottom: 24px;

  span {
    color: ${colorScheme.primary};
  }

  @media screen and (max-width: 900px) {
    text-align: center;
  }

  @media screen and (max-width: 414px) {
    font-size: 30px;
    line-height: 30px;
  }
`;

export const Text = styled.article`
  font-size: 16px;
  line-height: 24px;

  @media screen and (max-width: 900px) {
    text-align: center;
  }
`;

export const PhoneTitle = styled.h3`
  font-family: ${fonts.BrandontextBlack};
  font-size: 22px;
  line-height: 22px;
  color: ${colors.dark};
  margin-bottom: 16px;
  text-transform: uppercase;

  @media screen and (max-width: 900px) {
    text-align: center;
  }
`;

export const WrapperPhones = styled.div`
  display: flex;
  margin-bottom: 56px;

  button {
    margin-right: 32px;
  }

  button:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: 900px) {
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;

    button {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }
`;

export const ButtonPhone = styled.button.attrs({ type: "button" })`
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 6px;
  border: 1px #c9d1dd solid;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
  font-family: ${fonts.BrandontextRegular};
  font-size: 16px;
  color: ${colors.dark};
  padding: 16px 20px;
  transition: 300ms ease;
  width: max-content;

  &:hover {
    transform: scale(0.95);
  }
`;

export const BackgroundPhoneIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eaeaea;
  transition: 300ms ease;
  margin-right: 16px;
`;

export const PhoneIcon = styled(PhoneAlt).attrs({ size: 14 })`
  color: ${colorScheme.primary};
`;

export const WhatsappIcon = styled(Whatsapp).attrs({ size: 16 })`
  color: ${colorScheme.primary};
`;

export const ResponsibleTitle = styled.h3`
  font-family: ${fonts.BrandontextBlack};
  font-size: 16px;
  line-height: 16px;
  color: ${colors.dark};
  margin-bottom: 16px;
  text-transform: uppercase;

  @media screen and (max-width: 900px) {
    text-align: center;
  }
`;

export const GroupInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  p {
    font-family: ${fonts.BrandontextRegular};
    font-size: 14px;
    color: ${colors.dark};
    margin: -4px 0 5px 0;
    &.second {
      margin-top: 0px;
      margin-bottom: 20px;
    }
  }
`;

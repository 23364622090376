import styled from "styled-components";
import bg from "assets/images/orofacial/banner/bg-banner.jpg";
import bgMobile from "assets/images/orofacial/banner/bg-banner-mobile.jpg";
import modelo from "assets/images/orofacial/banner/modelo.png";
import logo from "assets/images/orofacial/banner/logo-orofacial.png";
import { ArrowForwardOutline } from "@styled-icons/evaicons-outline";
import { fonts, colors } from "styles";

export const Container = styled.section`
    display:flex
    flex-direction:row;
    flex-wrap:wrap;
    width: 100%;
    height:auto;
    background: url(${bg}) no-repeat center center;
    -webkit-background-size:cover;
    -moz-background-size:cover;
    -o-background-size:cover;
     background-size:cover;
     background-position:center;
     @media screen and (max-width: 1200px) {
        flex-direction:row;
    }
     @media screen and (max-width: 540px) {
      background: url(${bgMobile}) no-repeat center center;
    }
`;
export const Group = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const Lside = styled.div`
  width: 50%;
  min-height: 500px;
  background: transparent;
  @media screen and (max-width: 1200px) {
    display: none;
  }
`;
export const Modelo = styled.img`
  width: 100%;
  height: 100%;
`;

export const Rside = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  min-height: 500px;
  background: transparent;
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
`;

export const GroupInfo = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  width: 95%;
  height: auto;
  padding: 20px;
  margin-top: 50px;
  h2 {
    color: #fbe995;
    font-family: ${fonts.BrandontextBold};
    font-size: 35px;
    text-transform: uppercase;
    letter-spacing: -1px;
    margin-bottom: 30px;
    @media screen and (max-width: 900px) {
      width: 100%;
      text-align: center;
      margin: 0 auto 20px auto;
    }
  }
  p {
    font-family: ${fonts.SourceSansProRegular};
    color: #fff;
    font-size: 23px;
    text-align: center;
    width: 90%;
    max-width: 360px;
    margin-bottom: 50px;
  }
  a {
    font-family: ${fonts.BrandontextBold};
    background: #ef9326;
    font-size: large;
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
  }
`;

export const ArrowRightIcon = styled(ArrowForwardOutline).attrs({ size: 20 })`
  color: white;
  margin-left: 5px;
`;

export const Logo = styled.img.attrs({ src: logo })`
  width: 90%;
  max-width: 530px;
  height: auto;
  margin: 7% auto 50px auto;
`;

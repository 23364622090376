import React from "react";
import { faqs } from "data/faq";

import * as S from "./styles";
import { Accordion } from "./Accordion";

export const FAQ: React.FC = () => {
  return (
    <S.Container>
      <S.Content>
        <S.Title>
          Dúvidas
          <br />
          <span>Frequentes</span>
        </S.Title>
        {faqs.map((faq) => (
          <Accordion key={faq.id} data={faq} />
        ))}
      </S.Content>
    </S.Container>
  );
};

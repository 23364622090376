import React from "react";

import * as S from "./styles";

export const Banner: React.FC = () => {
  const animateBanner = {
    symbolBig: {
      scale: [1, 1.025, 1],
      rotate: [0, 90, 0],
      transition: { ease: "easeInOut", duration: 8, repeat: Infinity },
    },
    ball: {
      scale: [1, 1.2, 1],
      transition: { ease: "easeInOut", duration: 4, repeat: Infinity },
    },
  };

  return (
    <S.Container>
      {/* <S.SymbolBig animate="symbolBig" variants={animateBanner} /> */}
      {/* <S.Ball animate="ball" variants={animateBanner} /> */}
      <S.Content>
        <S.Wrapper>
          <S.Title>
            Conheça a ortodontia <span>orthopride</span>
          </S.Title>
          <S.Text>
            Confira os nossos tratamentos ortodônticos avançados que vão
            transformar o seu sorriso!
          </S.Text>
        </S.Wrapper>
        <S.Modelo />
      </S.Content>
    </S.Container>
  );
};

import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";
import { IState, ISuccessAction, IFailureAction } from "interfaces/create-duck";

const { Types, Creators } = createActions(
  {
    request: ["postData"],
    success: ["data"],
    failure: ["error"],
    reset: [],
  },
  {
    prefix: "CREATE_PROSPECT_",
  }
);

export interface CreateProspectState extends IState {}

const INITIAL_STATE: CreateProspectState = {
  data: {},
  loading: false,
  error: null,
};

const request = (state: CreateProspectState) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

const success = (state: CreateProspectState, action: ISuccessAction) =>
  update(state, {
    data: { $set: action.data },
    loading: { $set: false },
  });

const failure = (state: CreateProspectState, action: IFailureAction) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

const reset = () => INITIAL_STATE;

export const createProspect = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
});

export const CreateProspectTypes = Types;
export const CreateProspectActions = Creators;

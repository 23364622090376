import React from "react";

import * as S from "./styles";

export const Game: React.FC = () => {
  const animeCaio = {
    scaling: {
      scale: [1, 1.015, 1],
      transition: { ease: "easeInOut", duration: 5, repeat: Infinity },
    },
  };
  return (
    <S.Container>
      <S.Content>
        <S.Caio animate="scaling" variants={animeCaio} />
      </S.Content>
      <S.Content>
        <S.Title>
          Na Orthopride, suas consultas são mais <span>divertidas</span>!
        </S.Title>
        <S.Text>
          Nossas salas de espera são equipadas com games e internet, para que
          você se divirta enquanto aguarda o seu atendimento. Nada de filas
          entediantes por aqui!
        </S.Text>
        <S.Wrapper>
          <S.WrapperCaio>
            <S.CaioMobile />
          </S.WrapperCaio>
          <S.WrapperItems>
            <S.WrapperItem>
              <S.Esport />
              <S.Label>Área de E-Sports</S.Label>
            </S.WrapperItem>
            <S.WrapperItem>
              <S.Cockpit />
              <S.Label>Cockpit de games</S.Label>
            </S.WrapperItem>
            <S.WrapperItem>
              <S.Pcs />
              <S.Label>PCs com internet</S.Label>
            </S.WrapperItem>
          </S.WrapperItems>
        </S.Wrapper>
      </S.Content>
    </S.Container>
  );
};

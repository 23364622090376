import styled from "styled-components";
import { colors, fonts } from "styles";
import { PlayCircle } from "@styled-icons/bootstrap/PlayCircle";

interface VideoPlayProps {
  control: boolean;
}

export const Contanier = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1920px;
  background: linear-gradient(to bottom, white 55%, black 45%) !important;
  min-height: 450px;
  padding: 50px 220px;
  @media screen and (max-width: 1720px) {
    align-items: center;
    padding: 100px 30px;
  }
  @media screen and (max-width: 850px) {
    padding: 50px 20px;
  }
  @media screen and (max-width: 730px) {
    background: linear-gradient(to bottom, white 60%, black 40%) !important;
  }
  @media screen and (max-width: 540px) {
    background: linear-gradient(to bottom, white 70%, black 30%) !important;
  }
`;
export const GroupVideo = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 800px;
  @media screen and (max-width: 1590px) {
    margin: 0 auto;
  }
`;
export const Title = styled.h2`
  width: 95%;
  max-width: 1300px;
  font-family: ${fonts.BrandontextLight};
  color: ${colors.black};
  font-size: 44px;
  text-align: center;
  margin-bottom: 40px;
  line-height: 1.1em;
  span {
    font-family: ${fonts.BrandontextBold};
  }
  @media screen and (max-width: 540px) {
    font-size: 35px;
  }
`;
export const AreaVideo = styled.div`
  position: relative;
  display: flex;
  width: 100%px;
  height: auto;
`;

export const Video = styled.div`
  position: relative;
  width: 100%;
  max-width: 786px;
  margin: 0 auto;
  position: relative;
  z-index: 2;

  .player-wrapper {
    position: relative;
    padding-top: 56.25%;
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const VideoPlay = styled(PlayCircle)<VideoPlayProps>`
  position: absolute;
  display: ${(props) => (props.control ? "none" : "flex")};
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  cursor: pointer;
`;

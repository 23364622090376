import React from "react";
import * as S from "./styles";

export const Banner: React.FC = () => {
  return (
    <S.Container>
      <S.Content>
        <S.Mascote />
        <S.TextBanner>
          <S.Title className="light">Dúvidas</S.Title>
          <S.Title className="bold">Frequentes</S.Title>
        </S.TextBanner>
      </S.Content>
    </S.Container>
  );
};

import React, { useState, useCallback, useRef, useEffect } from "react";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import * as S from "./styles";
import { askAndResponse, TCategory } from "data/askAndResponses";
import { Accordion } from "./Accordion";
import { OptionTypeBase } from "react-select";
import { Select } from "components/Shared/Form";

interface ICategoryOption extends OptionTypeBase {
  label: TCategory;
  value: TCategory | null;
}

export const Hightlights: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [selectedItem, setSelectedItem] = useState<TCategory | null>(null);
  const [categoryOptions, setCategoryOptions] = useState<ICategoryOption[]>();
  const [askResps, setAskResps] = useState(askAndResponse);

  const handleSelect = useCallback(() => {
    const categories = askAndResponse.map(
      (askAndResponse) => askAndResponse.category
    );
    const unique = [...new Set(categories)];
    const options = unique.map((category) => ({
      label: category,
      value: category,
    }));

    options.push({ label: "Todos", value: null });
    setCategoryOptions(options);
  }, []);

  const handleFilter = useCallback(
    (category: TCategory) => {
      const disable = selectedItem === category || category === null;
      setSelectedItem(category);
      if (!disable) {
        const data = askAndResponse.filter(
          (askResp) => askResp.category === category
        );
        setAskResps(data);
      } else {
        setAskResps(askAndResponse);
        setSelectedItem(null);
      }
    },
    [selectedItem]
  );

  useEffect(() => {
    handleSelect();
  }, [handleSelect]);

  return (
    <S.Container>
      <S.Content>
        <S.TopTitle>Navegue pelos assuntos</S.TopTitle>
        <S.TopTitle>
          mais <span>procurados</span>
        </S.TopTitle>
        <S.GroupLinks>
          <S.ButtonDefaut
            onClick={() => handleFilter("Tratamento")}
            active={selectedItem === "Tratamento"}
          >
            <S.ToothIcon />
            Tratamentos
          </S.ButtonDefaut>

          <S.ButtonDefaut
            onClick={() => handleFilter("Pagamento")}
            active={selectedItem === "Pagamento"}
          >
            <S.OfferIcon />
            Pagamentos
          </S.ButtonDefaut>

          <S.ButtonDefaut
            onClick={() => handleFilter("Unidades")}
            active={selectedItem === "Unidades"}
          >
            <S.BuildIcon />
            Unidades
          </S.ButtonDefaut>

          <S.ButtonDefaut
            onClick={() => handleFilter("Agendamento")}
            active={selectedItem === "Agendamento"}
          >
            <S.CalendarIcon />
            Agendamento
          </S.ButtonDefaut>

          <S.ButtonDefaut
            onClick={() => handleFilter("Aplicativo")}
            active={selectedItem === "Aplicativo"}
          >
            <S.MobileIcon />
            Aplicativo
          </S.ButtonDefaut>

          <S.ButtonDefaut
            onClick={() => handleFilter("Cancelamento")}
            active={selectedItem === "Cancelamento"}
          >
            <S.UserVoiceIcon />
            Cancelamento
          </S.ButtonDefaut>

          <S.ButtonDefaut
            onClick={() => handleFilter("Contrato")}
            active={selectedItem === "Contrato"}
          >
            <S.ChatIcon />
            Contrato
          </S.ButtonDefaut>
        </S.GroupLinks>

        <S.GroupLinksMobile>
          <Form ref={formRef} onSubmit={() => {}} style={{ width: "100%" }}>
            <Select
              name="categories"
              label=""
              options={categoryOptions}
              onChange={(e: OptionTypeBase) => handleFilter(e.value)}
            />
          </Form>
        </S.GroupLinksMobile>
        <S.DivisorMobile />

        <S.GroupAccordion>
          {askResps.map((askResp) => (
            <Accordion key={askResp.id} data={askResp} />
          ))}
        </S.GroupAccordion>
      </S.Content>
    </S.Container>
  );
};

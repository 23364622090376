import { createGlobalStyle } from "styled-components";

import SourceSansProRegular from "assets/fonts/SourceSansPro-Regular.ttf";
import SourceSansProBold from "assets/fonts/SourceSansPro-Bold.ttf";
import BrandontextBlack from "assets/fonts/brandontext-black.ttf";
import BrandontextBold from "assets/fonts/brandontext-bold.ttf";
import BrandontextLight from "assets/fonts/brandontext-light.ttf";
import BrandontextMedium from "assets/fonts/brandontext-medium.ttf";
import BrandontextRegular from "assets/fonts/brandontext-regular.ttf";
import Knockout69 from "assets/fonts/Knockout-69.otf";
import Knockout93 from "assets/fonts/Knockout-93.otf";
import Knockout46 from "assets/fonts/Knockout-HTF46-Flyweight.otf";
import Knockout49 from "assets/fonts/Knockout-49Liteweight-Regular.otf";
import Knockout30 from "assets/fonts/Knockout-30.otf";

const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: "SourceSansProRegular";
    src: url(${SourceSansProRegular});
  }
  @font-face {
    font-family: "SourceSansProBold";
    src: url(${SourceSansProBold});
  }
  @font-face {
    font-family: "BrandontextBlack";
    src: url(${BrandontextBlack});
  }
  @font-face {
    font-family: "BrandontextBold";
    src: url(${BrandontextBold});
  }
  @font-face {
    font-family: "BrandontextLight";
    src: url(${BrandontextLight});
  }
  @font-face {
    font-family: "BrandontextMedium";
    src: url(${BrandontextMedium});
  }
  @font-face {
    font-family: "BrandontextRegular";
    src: url(${BrandontextRegular});
  }
  @font-face {
    font-family: "Knockout69";
    src: url(${Knockout69});
  }
  @font-face {
    font-family: "Knockout93";
    src: url(${Knockout93});
  }
  @font-face {
    font-family: "Knockout46";
    src: url(${Knockout46});
  }
  @font-face {
    font-family: "Knockout49";
    src: url(${Knockout49});
  }
  @font-face {
    font-family: "Knockout30";
    src: url(${Knockout30});
  }


  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
  }

  html,
  body,
  #root {
    height: 100%;
    scroll-behavior: smooth;
  }

  body {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    background: #EFEFEF;
    font-family: "SourceSansProRegular";
    font-size: 16px;
    color: #5A697E;
    font-weight: normal;
    
  }

  body.modal-open {
    overflow-y: hidden;
  }
  body.react-select-open {
    .modal-body {
      overflow-y: auto;
      .react-select__menu {
        position: sticky;
      }
    }
  }

  a {
    text-decoration: none;
  }

  button {
    font-family: "BrandontextBold";
    border: 0;
    cursor: pointer;
    background-color: transparent;
  }

  input, textarea {
    font-family: "SourceSansProRegular";
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: "BrandontextBold";
    font-weight: unset;
  }

  ul {
    list-style: none;
  }

  .toastsuccess {
    border-radius: 6px;
    background-color: #1976D2 !important;
  }

  .Toastify__toast--error {
    border-radius: 6px;
    background-color: #ec1751 !important;
  }

  .Toastify__toast-body {
    font-family: "BrandontextBold" !important;
    font-size: 14px;
    padding: 0 16px;
  }

  .icon-spin {
    animation: iconSpin 2s infinite linear;
  }

  @keyframes iconSpin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
`;

export default GlobalStyle;

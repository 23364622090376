import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";
import { IState, ISuccessAction, IFailureAction } from "interfaces/create-duck";

const { Types, Creators } = createActions(
  {
    request: ["postData", "onSuccess"],
    success: ["data"],
    failure: ["error"],
    reset: [],
  },
  {
    prefix: "CREATE_FRANCHISING_CONTACT_",
  }
);

export interface CreateFranchisingContactState extends IState {}

const INITIAL_STATE: CreateFranchisingContactState = {
  data: {},
  loading: false,
  error: null,
};

const request = (state: CreateFranchisingContactState) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

const success = (
  state: CreateFranchisingContactState,
  action: ISuccessAction
) =>
  update(state, {
    data: { $set: action.data },
    loading: { $set: false },
  });

const failure = (
  state: CreateFranchisingContactState,
  action: IFailureAction
) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

const reset = () => INITIAL_STATE;

export const createFranchisingContact = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
});

export const CreateFranchisingContactTypes = Types;
export const CreateFranchisingContactActions = Creators;

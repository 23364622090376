import React from "react";

import * as S from "./styles";

export const Ponto: React.FC = () => {
  return (
    <S.Container>
      <S.Title>
        Ponto de instalação
        <br />
        <span> da unidade</span>
      </S.Title>
      <S.Subtitle>Escolha</S.Subtitle>
      <S.Text>
        Nossos consultores de campo estão totalmente aptos a auxiliar os novos
        franqueados na escolha do melhor local. Com base em nosso know-how,
        avaliamos os diversos fatores determinantes para o sucesso do seu
        negócio.
      </S.Text>
      <S.Subtitle>Projeto</S.Subtitle>
      <S.Text>
        Contamos com um escritório de arquitetura instalado full time dentro da
        nossa matriz para prover atendimento em tempo integral, garantindo a
        qualidade das entregas e possibilitando o início imediato da obra.
      </S.Text>
      <S.Subtitle>Reforma</S.Subtitle>
      <S.Text>
        Disponibilizamos um canal de suporte sempre aberto durante a reforma
        para eventuais dúvidas dos nossos franqueados. Além disso, acompanhamos
        todo o processo de perto, com visitas programadas, nas quais orientamos
        sobre todos os detalhes da obra.
      </S.Text>
      <S.Subtitle>Montagem</S.Subtitle>
      <S.Text>
        <p>
          O franqueado recebe todos os checklists e especificações técnicas e
          dimensionais dos equipamentos e móveis para a construção de sua
          unidade, além de instruções e um passo a passo completo com todas as
          etapas pré inaugurais:
        </p>
        <p>
          • Documentação;
          <br /> • Estrutural - Edificação;
          <br /> • Estrutural complementar;
          <br /> • Mobiliário;
          <br /> • Equipagem;
          <br /> • Acabamentos;
          <br /> • Operacional;
        </p>
      </S.Text>
    </S.Container>
  );
};

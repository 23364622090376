import styled from "styled-components";
import { ReactComponent as logo } from "assets/images/back-logo.svg";

export const Container = styled.header`
  width: 100%;
  padding: 16px 32px;
  background-color: #fff;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.15);
  position: sticky;
  top: 0;
  z-index: 999;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1366px;
  margin: 0 auto;
`;

export const Logo = styled(logo)``;

import { call, put } from "redux-saga/effects";

import { api, notify } from "services";
import { IFechRequest } from "interfaces/fetch-duck";
import { requestErrorHandler } from "utils";
import { FetchUnidadeActions } from "store/ducks/unidades";

export function* FetchUnidadeRequest(action: any) {
  try {
    const { id } = action as IFechRequest;
    const { data } = yield call(api.get, `/unidades/${id}`);
    yield put(FetchUnidadeActions.success(data));
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(FetchUnidadeActions.failure(errorMessage));
  }
}

import styled from "styled-components";

export const Content = styled.div`
  width: 100%;
  max-width: 1366px;
  padding: 88px 32px;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  @media screen and (max-width: 414px) {
    padding-top: 48px;
    padding-bottom: 48px;
  }
`;

export const Title = styled.h2`
  width: 100%;
  font-size: 46px;
  line-height: 46px;
  margin-bottom: 32px;
  color: ${({ theme }) => theme.colorScheme.secondary};

  span {
    color: ${({ theme }) => theme.colorScheme.primary};
  }

  @media screen and (max-width: 414px) {
    font-size: 30px;
    line-height: 30px;
  }
`;

export const Text = styled.article`
  width: 100%;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.blueGray};
  columns: 2;
  column-gap: 48px;

  p {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 540px) {
    columns: 1;
  }

  @media screen and (max-width: 414px) {
    font-size: 16px;
  }
`;

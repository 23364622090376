import styled from "styled-components";
import { colors, fonts } from "styles";

import premios from "assets/images/franchising2024/premios.png";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 50px 260px;
  max-width: 1920px;
  background: ${colors.darkBg};
  overflow: hidden;
  @media screen and (max-width: 1720px) {
    padding: 50px 100px;
    align-items: center;
  }
  @media screen and (max-width: 900px) {
    padding: 50px 30px;
  }
`;
export const Title = styled.h2`
  font-family: ${fonts.BrandontextBold};
  font-size: 60px;
  text-transform: uppercase;
  letter-spacing: -1px;
  color: ${colors.orange};
  margin-bottom: 15px;
  @media screen and (max-width: 1590px) {
    margin: 0 auto 40px auto;
  }
  @media screen and (max-width: 830px) {
    font-size: 55px;
  }
  @media screen and (max-width: 680px) {
    font-size: 40px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 540px) {
    font-size: 30px;
  }
`;

export const GroupList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 550px;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: -150px;
  @media screen and (max-width: 1590px) {
    margin: 0 auto;
  }
  @media screen and (max-width: 1160px) {
    margin-bottom: -100px;
  }
  @media screen and (max-width: 1000px) {
    margin-bottom: 0px;
  }
`;
export const ItemList = styled.li`
  width: 100%;
  margin-bottom: 20px;
  p {
    font-family: ${fonts.BrandontextLight};
    color: ${colors.white};
    font-size: 24px;
    span {
      font-family: ${fonts.BrandontextBold};
    }
    @media screen and (max-width: 830px) {
      font-size: 22px;
    }
  }
`;

export const Premiacao = styled.img.attrs({ src: premios })`
  width: 100%;
  max-width: 800px;
  height: auto;
  @media screen and (max-width: 1720px) {
    margin: 40px auto 0 auto;
  }
  @media screen and (max-width: 1590px) {
    margin: -100px auto 0 auto;
  }

  @media screen and (max-width: 1160px) {
    max-width: 700px;
    margin: -10px auto 0 auto;
  }
  @media screen and (max-width: 1160px) {
    max-width: 700px;
    margin: -100px auto 0 auto;
  }
  @media screen and (max-width: 1160px) {
    width: 90%;
    max-width: 700px;
    margin: 0 auto;
  }
  @media screen and (max-width: 560px) {
    width: 90%;
    max-width: 600px;
    height: auto;
    margin-top: 20px;
  }
`;

export const WhiteBall = styled.div`
  position: absolute;
  width: 600px;
  height: 600px;
  background: ${colors.white};
  border-radius: 50%;
  bottom: -350px;
  right: -200px;
  @media screen and (max-width: 1485px) {
    width: 400px;
    height: 400px;
    bottom: -200px;
    right: -150px;
  }
  @media screen and (max-width: 1240px) {
    width: 300px;
    height: 300px;
    bottom: -150px;
    right: -100px;
  }
  @media screen and (max-width: 1200px) {
    display: none;
  }
`;
export const OrangeBall = styled.div`
  position: absolute;
  width: 600px;
  height: 600px;
  border: 150px solid ${colors.orange};
  border-radius: 50%;
  top: -360px;
  right: -315px;
  @media screen and (max-width: 1485px) {
    width: 400px;
    height: 400px;
    top: -200px;
    right: -200px;
    border: 100px solid ${colors.orange};
  }
  @media screen and (max-width: 1240px) {
    width: 300px;
    height: 300px;
    top: -180px;
    right: -150px;
    border: 60px solid ${colors.orange};
  }
  @media screen and (max-width: 900px) {
    display: none;
  }
`;

import styled from "styled-components";
import { colors, colorScheme } from "styles";

export const Container = styled.section`
  width: 100%;
  background-color: ${colors.grayLight};
  padding: 96px 32px;

  @media screen and (max-width: 414px) {
    padding-top: 48px;
    padding-bottom: 48px;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
`;

export const Title = styled.h2`
  font-size: 46px;
  line-height: 46px;
  color: ${colors.dark};
  margin-bottom: 40px;

  @media screen and (max-width: 414px) {
    font-size: 30px;
    line-height: 30px;
    text-align: center;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  .slick-prev:before,
  .slick-next:before {
    content: "";
    display: none;
  }

  &.slider-mobile {
    display: none;
  }

  @media screen and (max-width: 720px) {
    display: none;
    &.slider-mobile {
      display: block;
    }
  }
`;

export const Tips = styled.div`
  padding: 0 16px;
  width: 100%;
  max-width: 270px;

  @media screen and (max-width: 720px) {
    max-width: 100%;
    padding-bottom: 32px;
  }
`;

export const TipsNumber = styled.h3`
  font-size: 68px;
  line-height: 68px;
  color: ${colorScheme.primary};
  margin-bottom: 16px;

  @media screen and (max-width: 414px) {
    text-align: center;
  }
`;

export const TipsTitle = styled.h6`
  font-size: 20px;
  line-height: 20px;
  color: ${colors.dark};
  margin-bottom: 24px;

  @media screen and (max-width: 414px) {
    text-align: center;
  }
`;

export const Text = styled.article`
  font-size: 16px;
  line-height: 24px;

  @media screen and (max-width: 414px) {
    text-align: center;
  }
`;

export const DragText = styled.span`
  display: none;

  @media screen and (max-width: 620px) {
    display: block;
    text-align: center;
    margin-top: 32px;
    color: ${colors.blueGray};
  }
`;

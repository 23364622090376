import styled from "styled-components";
import { ChevronRightCircle } from "@styled-icons/boxicons-solid";
import { ArrowForwardOutline } from "@styled-icons/evaicons-outline";
import { ArrowLeft2 } from "@styled-icons/icomoon";
import { colors, colorScheme, fonts } from "styles";

import { Button } from "styles/styled-components";
export { Loading } from "styles/styled-components";

interface StyledLinkProps {
  isClicked: boolean;
}

export const Container = styled.section`
  width: 100%;
`;

export const InvalidCpf = styled.span`
  color: red;
  font-size: medium;
  margin-bottom: 15px;
`;

export const ChevronIcon = styled(ChevronRightCircle).attrs({ size: 20 })`
  color: ${({ theme }) => theme.colorScheme.primary};
  margin-right: 8px;
  transition: 300ms ease;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 32px;
  margin: 0 auto;

  form {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 470px;
  }

  @media screen and (max-width: 720px) {
    padding: 48px 32px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 780px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h1`
  font-size: 40px;
  line-height: 40px;
  color: ${colors.dark};
  margin-bottom: 24px;
  text-align: center;
  width: 100%;
  max-width: 470px;

  span {
    color: ${colorScheme.primary};
  }

  @media screen and (max-width: 414px) {
    font-size: 30px;
    line-height: 30px;
  }
`;

export const Text = styled.article`
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  padding-bottom: 40px;
  border-bottom: 2px #c7d0dd solid;
  width: 100%;
  max-width: 470px;

  span {
    color: ${colorScheme.primary};
    font-family: ${fonts.SourceSansProBold};
  }
`;

export const SubmitButton = styled(Button).attrs({ type: "submit" })`
  margin-top: 48px;
  align-self: center;
`;

export const ArrowRightIcon = styled(ArrowForwardOutline).attrs({ size: 20 })`
  color: white;
  margin-left: 24px;
`;

export const BackgroundIcon = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.dark};
  margin-right: 16px;
  transition: 300ms ease;
`;

export const BackButton = styled.button.attrs({ type: "button" })`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.dark};
  margin-bottom: 48px;
  text-transform: uppercase;
  font-size: 14px;
  font-family: ${fonts.BrandontextBold};

  :hover {
    ${BackgroundIcon} {
      transform: translateX(-4px);
    }
  }
`;

export const BackIcon = styled(ArrowLeft2).attrs({ size: 20 })`
  color: white;
`;

export const UnitsList = styled.div`
  margin: 32px 0;
`;

export const ErroMessage = styled.p`
  display: none;
  font-size: small;
  color: red;
  padding: -20px 0 10px 0;
  margin-bottom: 15px;
  margin-top: -10px;
`;

export const GroupTreatments = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px;
  padding:0;
  h3 {
    font-family: ${fonts.BrandontextMedium}
    color: #000;
    margin-bottom: 15px;
    
  }
  p.warning {
    display:none;
    font-family: ${fonts.BrandontextMedium};
    color:red;
    font-size: 18px;
    margin: -10px 0 15px 0;
    text-align:left;
  }
  p.legend {
    font-family: ${fonts.BrandontextMedium};
    color:#5A697E;
    font-size: 16px;
    margin-bottom:15px;
    text-align:left;
  }
  p.noItem {
    font-family: ${fonts.BrandontextMedium};
    color:#5A697E;
    font-size: 16px;
    margin-bottom:20px;
    text-align:left;
  }
  ul {
    display:flex;
    flex-direction:column;
    li {
      display:flex;
      align-items:center;
      justify-content:center;
      padding: 20px;
      border: 1px solid #c9c9c9;
      color: #5A697E;
      margin-bottom: 20px;
    }
  }


`;

export const ButtonOpt = styled.a<StyledLinkProps>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: ${({ isClicked }) => (isClicked ? "#fff" : "#5A697E")};
  background-color: ${({ isClicked }) => (isClicked ? "#f58226" : "#fff")};
  margin-bottom: 15px;
  padding: 20px;
  border: 1px solid #c9c9c9;
  font-weight: bold;
`;

import styled from "styled-components";
import { colors, fonts } from "styles";
export { Button } from "styles/styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 270px;
  height: 520px;
  background-color: white;
  padding: 40px 32px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  border: 1px #e0e0e0 solid;
  border-radius: 8px;
  position: relative;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 10px;
    border-radius: 8px 8px 0 0;
    background-color: #bfc1c3;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const New = styled.span`
  position: absolute;
  right: 24px;
  top: 24px;
  font-family: ${fonts.BrandontextBold};
  font-size: 14px;
  text-transform: uppercase;
  color: ${colors.blue};
`;

export const Image = styled.div`
  margin-bottom: 24px;
`;

export const Title = styled.h4`
  font-family: ${({ theme }) => theme.fonts.BrandontextMedium};
  font-size: 27px;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 24px;
  text-align: center;
`;

export const Text = styled.article`
  width: 100%;
  text-align: center;
  font-size: 16px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.blueGray};
  flex: 1;
  margin-bottom: 24px;
`;

import styled from "styled-components";

interface mTopProps {
  mTop: string | null;
}

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  background: rgb(255, 214, 130);
  background: linear-gradient(
    180deg,
    rgba(255, 214, 130, 1) 4%,
    rgba(236, 191, 121, 1) 34%,
    rgba(250, 109, 122, 1) 81%
  );

  padding: 100px 50px 0 50px;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 90%;
  max-width: 1200px;
  min-height: 1200px;

  /* background: rgba(0, 0, 0, 0.5); */
  img.vertical {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 600px;
    height: auto;
    z-index: 1;
    @media screen and (max-width: 1340px) {
      display: none;
    }
  }
  img.pic {
    max-width: 520px;
    height: auto;
    z-index: 1;
  }
  @media screen and (max-width: 1340px) {
    align-items: center;
  }
`;

export const GroupTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 70%;
  max-width: 740px;
  height: auto;

  @media screen and (max-width: 1415px) {
    flex-direction: column;
    width: 100%;
    height: auto;
    img {
      width: 80%;
      max-width: 300px;
      height: auto;
      margin-top: -30px;
      margin-bottom: 30px;
    }
  }
  @media screen and (max-width: 1340px) {
    align-items: center;
    justify-content: center;
  }
`;

export const PicImage = styled.div<mTopProps>`
  margin-top: ${(props) => props.mTop};
  width: 100%;
  height: auto;
  padding: 0;
  margin-bottom: -5px;
  @media screen and (max-width: 1340px) {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 0;
    margin-top: 50px;
  }
`;

export const MobileImg = styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
  width: 100%;
  height:auto;
  display: none;
  margin: 20px auto;
  img {
    width:auto;
    height:700px;
    margin: 20px auto;
    
  }
  @media screen and (max-width: 1340px) {
    display: flex;
    width: 90%;
    height:auto
    margin: 20px auto;
    
  }
`;

export const Title = styled.h1`
  font-family: "Knockout46";
  font-size: 280px;
  color: #fff;
  text-transform: uppercase;
  line-height: 0.8em;
  z-index: 4;
  margin-bottom: 50px;
  @media screen and (max-width: 1340px) {
    text-align: center;
  }
  @media screen and (max-width: 600px) {
    font-size: 150px;
  }
`;
export const Text = styled.div`
  max-width: 480px;
  p {
    font-family: "Knockout30";
    font-size: 35px;
    color: #fff;
    line-height: 1.1em;
    margin-bottom: 30px;
    @media screen and (max-width: 1340px) {
      text-align: center;
    }
    @media screen and (max-width: 600px) {
      font-size: 30px;
    }
  }
`;

export const BtnButton = styled.a`
  position: absolute;
  padding: 10px;
  width: max-content;
  border-radius: 10px;
  font-size: 35px;
  background: #fff;
  color: #000;
  font-family: "Knockout46";
  bottom: 35px;
  cursor: pointer;
  transition: 0.5s;
  z-index: 10;
  text-transform: uppercase;
  margin-left: 190px;
  &:hover {
    background: #000;
    color: #fff;
  }
  @media screen and (max-width: 1340px) {
    margin: 0;
  }
`;

import React, { PropsWithChildren } from "react";

import * as S from "./styles";
import { Header } from "components/Shared";

interface Props extends PropsWithChildren<any> {}

export const Scaffold: React.FC<Props> = ({ children }) => {
  return (
    <S.Container>
      <Header />
      {children}
    </S.Container>
  );
};

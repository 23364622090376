import React, { useState } from "react";
import Youtube from "react-youtube";
import ReactPlayer from "react-player";
import * as S from "./styles";

export const Video: React.FC = () => {
  //   const videoId = "TnFAhQF-u58";
  const [playing, setPlaying] = useState(false);
  const handlePlayClick = () => {
    setPlaying(true);
  };
  return (
    <>
      <S.Contanier>
        <S.GroupVideo>
          <S.Title>
            <span>Veja o que os nossos</span>
            <br />
            franqueados tem a dizer
          </S.Title>
          <S.Video>
            {/* <S.VideoPlay
              size="80"
              control={playing}
              onClick={handlePlayClick}
            /> */}
            <div className="player-wrapper">
              <ReactPlayer
                url="https://vimeo.com/257943778"
                className="react-player"
                width="100%"
                height="100%"
                controls={true}
                playing={playing}
              />
            </div>
          </S.Video>
        </S.GroupVideo>
      </S.Contanier>
    </>
  );
};

import styled from "styled-components";
import { motion } from "framer-motion";
import { colorScheme, fonts } from "styles";
import symbol from "assets/images/symbol-thanks.svg";
import thop from "assets/images/thop-thanks.png";

export const Container = styled.section`
  width: 100%;
  height: 480px;
  margin: 0 auto;
  background: rgb(245, 130, 38);
  background: linear-gradient(
    180deg,
    rgba(245, 130, 38, 1) 35%,
    rgba(242, 111, 57, 1) 100%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 96px 32px;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 730px) {
    height: auto;
    padding-top: 48px;
    padding-bottom: 48px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 850px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 16px;
  position: relative;
  z-index: 2;

  @media screen and (max-width: 730px) {
    flex-direction: column;
  }
`;

export const Title = styled.h1`
  font-size: 72px;
  line-height: 72px;
  text-transform: uppercase;
  margin-bottom: 24px;
  color: ${colorScheme.secondary};

  span {
    font-family: ${fonts.BrandontextLight};
  }

  @media screen and (max-width: 830px) {
    font-size: 49px;
    line-height: 49px;
  }

  @media screen and (max-width: 730px) {
    text-align: center;
  }

  @media screen and (max-width: 414px) {
    font-size: 36px;
    line-height: 36px;
  }
`;

export const Thop = styled.img.attrs({ src: thop })`
  width: 100%;
  max-width: max-content;
`;

export const Symbol = styled(motion.img).attrs({ src: symbol })`
  width: 100%;
  max-width: max-content;
  position: absolute;
  top: 0;
  left: -350px;
  z-index: 1;
`;

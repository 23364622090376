import styled from "styled-components";
import { PhoneAlt } from "@styled-icons/fa-solid";
import { ArrowRight2 } from "@styled-icons/icomoon";
import { Whatsapp } from "@styled-icons/remix-fill";
import { colors, colorScheme, fonts } from "styles";

export const Container = styled.div`
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  border: 1px #a9b6c980 solid;
  padding: 32px 48px;
  margin-bottom: 32px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
  column-gap: 32px;
  border-radius: 6px;

  @media screen and (max-width: 620px) {
    flex-direction: column;
  }

  @media screen and (max-width: 414px) {
    padding: 32px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 32px;

  &:last-child {
    margin-right: 0;
  }

  &.address {
    flex: 1;
  }

  @media screen and (max-width: 620px) {
    margin-bottom: 32px;
  }
`;

export const Name = styled.h2`
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 16px;
  color: ${colors.dark};
  text-transform: uppercase;

  @media screen and (max-width: 620px) {
    text-align: center;
  }
`;

export const Text = styled.article`
  font-size: 13px;
  line-height: 18px;
  text-transform: uppercase;

  p {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 8px;
    }
  }

  @media screen and (max-width: 620px) {
    text-align: center;
    p {
      justify-content: center;
    }
  }
`;

export const PhoneIcon = styled(PhoneAlt).attrs({ size: 16 })`
  color: ${colors.dark};
  margin-right: 8px;
`;

export const WhatsappIcon = styled(Whatsapp).attrs({ size: 16 })`
  color: ${colors.dark};
  margin-right: 8px;
`;

export const TitlePhone = styled.h3`
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 16px;
  color: ${colors.dark};

  @media screen and (max-width: 620px) {
    text-align: center;
  }
`;

export const ViewButton = styled.button.attrs({ type: "button" })`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${colors.dark};
  text-transform: uppercase;
  font-size: 14px;
  font-family: ${fonts.BrandontextBold};
  align-self: center;
`;

export const ArrowRightIcon = styled(ArrowRight2).attrs({ size: 20 })`
  color: white;
`;

export const BackgroundIcon = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colorScheme.primary};
  transition: 300ms ease;
  margin-bottom: 8px;
`;

import React from "react";

import { Scaffold, Footer } from "components/Shared";
import { Banner, Hightlights } from "components/CupomRegulament";

export const CupomRegulament: React.FC = () => {
  return (
    <Scaffold>
      <Banner />
      <Hightlights />
      <Footer />
    </Scaffold>
  );
};

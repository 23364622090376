import styled from "styled-components";
import { ChevronRightCircle } from "@styled-icons/boxicons-solid";
import { ArrowForwardOutline } from "@styled-icons/evaicons-outline";
import { ArrowLeft2 } from "@styled-icons/icomoon";
import { colors, colorScheme, fonts } from "styles";

import bg from "assets/images/findUnit-background.png";
import lineo from "assets/images/lineo-logo.png";

import { Button } from "styles/styled-components";

export const Container = styled.section`
  display: flex;
  width: 100%;
  position: relative;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  column-gap: 24px;
  align-items: center;

  @media screen and (max-width: 1280px) {
    flex-direction: column;
  }
`;

export const Lineo = styled.img.attrs({ src: lineo })`
  width: 100%;
  max-width: max-content;

  @media screen and (max-width: 1280px) {
    margin-bottom: 32px;
  }
`;

export const LineoWrapper = styled.div`
  padding-left: 24px;
  border-left: 1px #707070 solid;

  @media screen and (max-width: 1280px) {
    border-left: 0;
    display: flex;
    flex-direction: column;
  }
`;

export const LineoText = styled.article`
  color: white;
  font-size: 16px;
  line-height: 20px;

  span {
    color: ${({ theme }) => theme.colorScheme.primary};
  }

  @media screen and (max-width: 1280px) {
    text-align: center;
  }
`;

export const LineoButton = styled.button.attrs({ type: "button" })`
  color: white;
  font-size: 18px;
  display: flex;
  align-items: center;
  margin-top: 16px;
  transition: 300ms ease;

  &:hover {
    color: ${({ theme }) => theme.colorScheme.primary};
    svg {
      transform: translateX(4px);
    }
  }

  @media screen and (max-width: 1280px) {
    align-self: center;
  }
`;

export const ChevronIcon = styled(ChevronRightCircle).attrs({ size: 20 })`
  color: ${({ theme }) => theme.colorScheme.primary};
  margin-right: 8px;
  transition: 300ms ease;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 80px;

  form {
    margin-top: 40px;
  }

  @media screen and (max-width: 720px) {
    padding: 48px 32px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 470px;
`;

export const Title = styled.h1`
  font-size: 40px;
  line-height: 40px;
  color: ${colors.dark};
  margin-bottom: 16px;

  span {
    color: ${colorScheme.primary};
  }

  @media screen and (max-width: 414px) {
    font-size: 30px;
    line-height: 30px;
  }
`;

export const Text = styled.article`
  font-size: 16px;
  line-height: 20px;
`;

export const SubmitButton = styled(Button).attrs({ type: "submit" })`
  margin-top: 48px;
`;

export const ArrowRightIcon = styled(ArrowForwardOutline).attrs({ size: 20 })`
  color: white;
  margin-left: 24px;
`;

export const BackgroundIcon = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.dark};
  margin-right: 16px;
  transition: 300ms ease;
`;

export const BackButton = styled.button.attrs({ type: "button" })`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.dark};
  margin-bottom: 48px;
  text-transform: uppercase;
  font-size: 14px;
  font-family: ${fonts.BrandontextBold};

  :hover {
    ${BackgroundIcon} {
      transform: translateX(-4px);
    }
  }
`;

export const BackIcon = styled(ArrowLeft2).attrs({ size: 20 })`
  color: white;
`;

export const UnitsList = styled.div`
  margin: 32px 0;
`;

export const Warning = styled.div`
  font-family: ${fonts.SourceSansProRegular};
  font-size: 14px;
  color: ${colors.red};
`;

import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as S from "./styles";
import { Scaffold, Footer } from "components/Shared";
import { ThanksBanner } from "components/Ouvidoria";

interface ILocationState {
  name: string;
}

export const Thanks: React.FC = () => {
  const history = useHistory();
  const { state } = useLocation<ILocationState>();
  return (
    <Scaffold>
      <ThanksBanner />
      <S.Container>
        <S.Title>Olá {state?.name}</S.Title>
        <S.Text>
          Obrigado por entrar em contato com a Orthopride, sua opinião é muito
          importante pra nós! Nossa equipe vai avaliar sua mensagem e caso
          necessário, entraremos em contato com você para mais esclarecimentos.
        </S.Text>
        <S.Button btStyle="secondary" onClick={() => history.push("/")}>
          Voltar à home
        </S.Button>
      </S.Container>
      <Footer />
    </Scaffold>
  );
};

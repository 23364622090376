import styled from "styled-components";
import { colors, fonts } from "styles";

import Medina from "assets/images/franchising2024/medina-circle.png";
import Caio from "assets/images/franchising2024/caio-circle.png";
import Ian from "assets/images/franchising2024/ian-circle.png";
import Lucas from "assets/images/franchising2024/lucascircle.png";
import Fink from "assets/images/franchising2024/fink-circle.png";
import Michele from "assets/images/franchising2024/michele-circle.png";

export const Container = styled.div`
  width: 100%;
  max-width: 1920px;
  min-height: 300px;
  background: #f1f1f1;
  padding: 100px 10%;
  @media screen and (max-width: 1720px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 50px;
  }
`;

export const GroupAmbassadors = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;

  h2 {
    font-family: ${fonts.BrandontextLight};
    color: ${colors.black};
    line-height: 0.9;
    font-size: 60px;
    text-align: center;
    margin-bottom: 20px;
    @media screen and (max-width: 700px) {
      font-size: 50px;
    }
    span {
      font-family: ${fonts.BrandontextBlack};
      color: ${colors.orange};
      font-size: 90px;
      @media screen and (max-width: 700px) {
        font-size: 60px;
      }
    }
  }
`;

export const GroupCards = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  max-width: 780px;
  column-gap: 30px;

  @media screen and (max-width: 1300px) {
    flex-direction: column;
    align-items: center;
    max-width: 400px;
  }
`;

export const Card = styled.div`
  width: 100%;
  max-width: 370px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  padding: 10px;
  h2 {
    font-family: ${fonts.BrandontextBlack};
    color: ${colors.orange};
    font-size: 50px;
    text-align: center;
    line-height: 1em;
    margin-bottom: 20px;
    @media screen and (max-width: 700px) {
      font-size: 40px;
    }
  }
  p {
    font-family: ${fonts.BrandontextMedium};
    color: ${colors.dark};
    font-size: 19px;
    line-height: 1.1em;
    margin-bottom: 15px;
    text-align: center;
  }
`;

export const FaceMedina = styled.img.attrs({ src: Medina })`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-bottom: 15px;
  border: 8px solid ${colors.dark};
`;
export const FaceCaio = styled.img.attrs({ src: Caio })`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-bottom: 15px;
  border: 8px solid ${colors.dark};
`;
export const FaceIan = styled.img.attrs({ src: Ian })`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-bottom: 15px;
  border: 8px solid ${colors.dark};
`;
export const FaceLucas = styled.img.attrs({ src: Lucas })`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-bottom: 15px;
  border: 8px solid ${colors.dark};
`;
export const FaceFink = styled.img.attrs({ src: Fink })`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-bottom: 15px;
  border: 8px solid ${colors.dark};
`;
export const FaceMichele = styled.img.attrs({ src: Michele })`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-bottom: 15px;
  border: 8px solid ${colors.dark};
`;

import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";
import { IState, ISuccessAction, IFailureAction } from "interfaces/fetch-duck";

const { Types, Creators } = createActions(
  {
    request: ["query"],
    success: ["data", "pagination"],
    failure: ["error"],
    reset: [],
  },
  { prefix: "GEOLOCATION_UNIDADE_" }
);

export interface GeolocationUnidadeState extends IState {}

interface ISuccessListUnitsAction extends ISuccessAction {}

const INITIAL_STATE: GeolocationUnidadeState = {
  data: null,
  loading: false,
  error: null,
};

const request = (state: GeolocationUnidadeState) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

const success = (
  state: GeolocationUnidadeState,
  action: ISuccessListUnitsAction
) =>
  update(state, {
    data: { $set: action.data },
    loading: { $set: false },
  });

const failure = (state: GeolocationUnidadeState, action: IFailureAction) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

const reset = () => INITIAL_STATE;

export const geolocationUnidade = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
});

export const GeolocationUnidadeTypes = Types;
export const GeolocationUnidadeActions = Creators;

import styled from "styled-components";
import { fonts, colors, colorScheme } from "styles";

export const Container = styled.section`
  width: 100%;
  background-color: ${colors.grayLight};
  padding: 73px 24px;

  @media screen and (max-width: 414px) {
    padding-top: 64px;
    padding-bottom: 88px;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
`;

export const Title = styled.h2`
  font-size: 46px;
  line-height: 46px;
  font-family: ${fonts.BrandontextBold};
  color: ${colors.dark};
  margin-bottom: 40px;
  span {
    color: ${colors.orange};
  }

  @media screen and (max-width: 414px) {
    margin-top: 63px;
    font-size: 30px;
    line-height: 30px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  .slick-prev:before,
  .slick-next:before {
    content: "";
    display: none;
  }

  &.slider-mobile {
    display: none;
  }

  @media screen and (max-width: 720px) {
    display: none;
    &.slider-mobile {
      display: block;
    }
  }
`;

export const Tips = styled.div`
  padding: 0 16px;
  width: 100%;
  max-width: 300px;

  @media screen and (max-width: 720px) {
    max-width: 100%;
    padding-bottom: 32px;
  }
`;

export const TipsNumber = styled.h3`
  font-size: 68px;
  line-height: 68px;
  color: ${colorScheme.primary};
  margin-bottom: 16px;

  @media screen and (max-width: 414px) {
    text-align: left;
  }
`;

export const TipsTitle = styled.h6`
  font-size: 20px;
  line-height: 20px;
  font-family: ${fonts.BrandontextBold};
  color: ${colors.dark};
  margin-bottom: 24px;

  @media screen and (max-width: 414px) {
    text-align: left;
  }
`;

export const Text = styled.article`
  font-size: 18px;
  line-height: 24px;
  width: 100%;
  max-width: 270px;
  color: ${colors.dark};

  @media screen and (max-width: 710px) {
    width: 100%;
    max-width: 450px;
  }
  @media screen and (max-width: 414px) {
    width: 100%;
    text-align: left;
  }
`;

export const DragText = styled.span`
  display: none;

  @media screen and (max-width: 620px) {
    display: block;
    text-align: center;
    margin-top: 32px;
    color: ${colors.dark};
  }
`;

export const MarcarAvaliacao = styled.button`
  font-family: ${fonts.BrandontextBold};
  font-size: 18px;
  background: ${colors.orange};
  width: 216px;
  color: #fff;
  border-radius: 3px;
  padding: 18px 22px;
  margin-top: 41px;
  @media screen and (max-width: 1100px) {
    margin: 40px 0 80px 0;
  }
  @media screen and (max-width: 540px) {
    display: none;
  }
`;

import React from "react";

import * as S from "./styles";
import { Scaffold, Footer, Franchising } from "components/Shared";
import { Banner, Gallery } from "components/Company";

export const Company: React.FC = () => {
  return (
    <Scaffold>
      <Banner />
      <S.Content>
        <S.Title>A Orthopride</S.Title>
        <S.Text>
          <p>
            Em 2009, nascia a Orthopride, com dedicação exclusiva à ortodontia e
            estética. Nossas unidades são projetadas para oferecer comodidade
            para nossos pacientes, através de um atendimento personalizado,
            tecnologia de ponta e profissionais capacitados para fazer o melhor.
          </p>
          <p>
            Nos tornamos a rede de franquias do ramo que mais cresce no Brasil.
            Presente em 18 estados, com mais de 200 em funcionamento. Hoje,
            cuidamos de mais de 250 mil sorrisos em todo o Brasil.
          </p>
          <p>
            Não é à toa que 90% dos nossos franqueados possuem mais de uma
            unidade: sinal de confiança, profissionalismo e credibilidade.
          </p>
          <p>
            Nosso objetivo é propagar alegria para nossos investidores,
            colaboradores e, claro, nossos pacientes. Acreditamos que tudo que é
            bom começa com um sorriso, inclusive grandes negócios. Assim,
            procuramos levar a nossa filosofia em cada atendimento,
            proporcionando uma experiência positiva a quem nos procura.
          </p>
        </S.Text>
      </S.Content>
      <Gallery />
      <Franchising />
      <Footer />
    </Scaffold>
  );
};

import styled from "styled-components";
import { colors, fonts } from "styles";

import { Star } from "@styled-icons/bootstrap/Star";
import { StarFill } from "@styled-icons/bootstrap/StarFill";
import ArrowRight from "assets/images/franchising2024/arrow-right.png";
import ArrowLeft from "assets/images/franchising2024/arrow-left.png";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 1920px;
  height: auto;
  background: ${colors.white};
  padding: 100px 200px;

  @media screen and (max-width: 1720px) {
    align-items: center;
    padding: 100px 30px;
  }
`;

export const GroupCards = styled.div`
  display: flex;
  width: 90%;
  justify-content: center;
  align-items: center;
  max-width: 700px;
  column-gap: 35px;
  @media screen and (max-width: 1720px) {
    margin: 0 auto;
  }

  @media screen and (max-width: 730px) {
    column-gap: 0;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 0 auto;
    row-gap: 20px;
  }
`;

export const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 300px;
  height: auto;
  border: 1px solid ${colors.black};
  padding: 30px 20px;
  min-height: 400px;
  transition: 0.5s;
  p {
    font-family: ${fonts.BrandontextLight};
    font-size: 25px;
    color: ${colors.black};
    letter-spacing: -1px;
    line-height: 1.1em;
    margin-bottom: 20px;
    text-align: center;
  }
  p.sign {
    font-family: ${fonts.BrandontextBold};
    font-size: 30px;
    color: ${colors.orange};
    letter-spacing: -1px;
    line-height: 1.1em;
  }
  @media screen and (max-width: 830px) {
    max-width: 350px;
  }
  @media screen and (max-width: 830px) {
    min-height: 450px;
  }
  @media screen and (max-width: 730px) {
    min-height: max-content;
  }
`;

export const GroupStars = styled.div`
  display: flex;
  width: 100%;
  column-gap: 10px;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

export const EmptyStar = styled(Star)`
  color: ${colors.orange};
`;

export const ArrowRightImg = styled.img.attrs({ src: ArrowRight })`
  width: 50px;
  height: 60px;
  cursor: pointer;
  @media screen and (max-width: 730px) {
    margin-top: 0px;
  }
`;

export const GroupArrows = styled.div`
  display: flex;
  width: max-content;
  justify-content: center;
  column-gap: 30px;
`;

export const ArrowLeftImg = styled.img.attrs({ src: ArrowLeft })`
  width: 50px;
  height: 60px;
  cursor: pointer;
  @media screen and (max-width: 730px) {
    margin-top: 0px;
    display: none;
  }
`;
export const ArrowLeftImgMobile = styled.img.attrs({ src: ArrowLeft })`
  display: none;
  width: 50px;
  height: 60px;
  cursor: pointer;

  @media screen and (max-width: 730px) {
    margin-top: 0px;
    display: flex;
  }
`;

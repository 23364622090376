import styled from "styled-components";
import bannerDesk from "assets/images/Studio/banner-studio.png";
import bannerMob from "assets/images/Studio/banner-studio-mobile.png";

export const Container = styled.section`
  display: block;
  width: 100%;
  max-width: 1920px;
  height: auto;
  margin: 0 auto;
  padding: 0;
  margin-bottom: -4px;
`;

export const BannerDesk = styled.img.attrs({ src: bannerDesk })`
  width: 100%;
  height: auto;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;
export const BannerMobile = styled.img.attrs({ src: bannerMob })`
  display: none;
  width: 100%;
  height: auto;
  @media screen and (max-width: 800px) {
    display: block;
  }
`;

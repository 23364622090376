import { format, addHours } from "date-fns";
import { call, put } from "redux-saga/effects";
import { api, notify, queryBuilder } from "services";
import { requestErrorHandler } from "utils";

import { IListRequest } from "interfaces/list-duck";
import { ListBookingActions } from "store/ducks/booking";

interface IDate {
  Data: Date;
}

export function* ListBookingRequest(action: any) {
  try {
    const { query = {} } = action as IListRequest;
    const queryString = queryBuilder(query);
    const { data } = yield call(api.get, `/horarios?${queryString}`);

    const comboOptions = data.map((date: IDate) => ({
      value: format(addHours(new Date(date.Data), 3), "yyyy-MM-dd HH:mm:ss"),
      label: format(addHours(new Date(date.Data), 3), "dd/MM/yyyy HH:mm"),
    }));

    yield put(ListBookingActions.success(comboOptions));
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(ListBookingActions.failure(errorMessage));
  }
}

import React, { useCallback } from "react";
import { Link } from "react-scroll";
import * as S from "./styles";
import Lineo from "assets/images/Studio/lineo.png";
import LineoLogo from "assets/images/Studio/LINEO-LOGO.png";

export const AdvancedTecnology: React.FC = () => {
  return (
    <>
      <S.Container>
        <S.Title>
          Tecnologia Avançada em alinhadores <span>transparentes</span>.
        </S.Title>
        <S.SubTitle>
          Discreto, tecnológico, fácil de usar e feito sob medida para você
          transformar o seu sorriso!
        </S.SubTitle>
        <S.GroupInfo>
          <S.Lside>
            <img src={Lineo} alt="Lineo" />
          </S.Lside>
          <S.Rside>
            <img className="logo" src={LineoLogo} alt="Logo Lineo" />
            <p>
              O Lineo é o alinhador transparente da Orthopride. A tecnologia
              usada em sua fabricação é um avanço para o tratamento ortodôntico,
              principalmente para os pacientes que preferem um aparelho discreto
              e moderno.
            </p>
            <Link
              className="callToAction"
              to="formSchedule"
              smooth={true}
              offset={50}
              duration={500}
            >
              Agendar Avaliação
            </Link>
          </S.Rside>
        </S.GroupInfo>
      </S.Container>
    </>
  );
};

import React from "react";
import { useHistory } from "react-router-dom";

import * as S from "./styles";
import { LegalText } from "components/Shared";
export const Clareamento: React.FC = () => {
  const history = useHistory();
  const animate = {
    flying: {
      y: [0, 10, 0],
      transition: { ease: "easeInOut", duration: 4, repeat: Infinity },
    },
  };
  return (
    <S.Background>
      <S.Container>
        <S.Content>
          <S.Ball animate="flying" variants={animate} />
          <S.Figure />
        </S.Content>
        <S.Content>
          <S.Wrapper>
            <S.Subtitle>Odontologia estética</S.Subtitle>
            <S.Title>
              Clareamento <span>dentário</span>
            </S.Title>
            <LegalText text="Clínico geral com atuação em dentística" />
            <S.Text>
              Já conhece o clareamento da Orthopride? Procedimento simples e
              direto que consiste na remoção de pigmentos que amarelam ou
              escurecem os dentes, mudando rapidamente a cor e a estética,
              deixando o sorriso mais claro e bonito. Para realizar este
              procedimento, é necessário agendar uma avaliação com o seu
              dentista
            </S.Text>
            <S.Button onClick={() => history.push("/agendar-avaliacao")}>
              Marcar uma avaliação
            </S.Button>
          </S.Wrapper>
        </S.Content>
      </S.Container>
    </S.Background>
  );
};

import React, { useRef, useCallback, useEffect } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Form } from "@unform/web";
import { FormHandles, SubmitHandler } from "@unform/core";
import * as Yup from "yup";

import { ListCitiesActions, ListCitiesState } from "store/ducks/city";
import {
  CreateFranchisingContactActions,
  CreateFranchisingContactState,
} from "store/ducks/franchising";
import { useValidation } from "hooks";
import { statesOptions } from "utils";

import * as S from "./styles";
import { Scaffold, Footer } from "components/Shared";
import { Input, Select, Textarea } from "components/Shared/Form";
import { BannerInterest } from "components/Franchising";

interface IParms {
  capex: string;
}

interface IOption {
  value: string;
  label: string;
  slug?: string;
}

const capexOptions: Array<IOption> = [
  {
    value: "Clínica pequena CAPEX",
    label: "Clínica pequena CAPEX",
    slug: "pequena",
  },
  {
    value: "Clínica média CAPEX",
    label: "Clínica média CAPEX",
    slug: "media",
  },
  {
    value: "Clínica grande CAPEX",
    label: "Clínica grande CAPEX",
    slug: "grande",
  },
];

export const Interest: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const dispatch = useDispatch();
  const { capex } = useParams<IParms>();
  const history = useHistory();
  const { handleFormErrors } = useValidation();

  const { data: listCitiesData, loading: listCitiesLoading } =
    useSelector<RootStateOrAny>((state) => state.listCities) as ListCitiesState;

  const { loading: createLoading } = useSelector<RootStateOrAny>(
    (state) => state.createFranchisingContact
  ) as CreateFranchisingContactState;

  const onSuccess = useCallback(
    (data) => {
      history.push({
        pathname: "/franqueado-obrigado",
        state: data,
      });
    },
    [history]
  );

  const handleSubmit = useCallback<SubmitHandler>(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          capex: Yup.string().required("Obrigatório"),
          name: Yup.string().required("Obrigatório"),
          phone: Yup.string().required("Obrigatório"),
          email: Yup.string().required("Obrigatório"),
          state: Yup.string().required("Obrigatório"),
          city: Yup.string().required("Obrigatório"),
          message: Yup.string().required("Obrigatório"),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        dispatch(CreateFranchisingContactActions.request(data, onSuccess));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, handleFormErrors, onSuccess]
  );

  const getCitiesOptions = useCallback(
    (data) => {
      dispatch(ListCitiesActions.request({ state: data.value }));
    },
    [dispatch]
  );

  const setCapexOption = useCallback(() => {
    if (capex) {
      const filter = capexOptions.filter((option) => option.slug === capex)[0];
      formRef.current?.setFieldValue("capex", filter);
    }
  }, [capex, formRef]);

  useEffect(() => {
    setCapexOption();
  }, [setCapexOption]);

  return (
    <Scaffold>
      <BannerInterest />
      <S.Container>
        <S.Content>
          <S.Title>
            Invista em uma
            <br />
            rede de <span>sucesso</span>
          </S.Title>
          <S.Text>
            Preencha o formulário abaixo com os seus dados abaixo, fale mais
            sobre você e a razão pela qual você gostaria de ser um investidor
            Orthopride!
          </S.Text>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Select
              name="capex"
              label="Modelo de interesse:"
              options={capexOptions}
            />
            <Input name="name" label="Digite seu nome:" />
            <Input name="phone" label="Celular:" />
            <Input name="email" label="E-mail:" />
            <Select
              name="state"
              label="Estado de interesse:"
              options={statesOptions}
              onChange={(e) => getCitiesOptions(e)}
            />
            <Select
              name="city"
              label="Cidade de interesse:"
              isDisabled={listCitiesLoading}
              isLoading={listCitiesLoading}
              options={listCitiesData}
            />
            <Textarea
              name="message"
              label="Fale um pouco sobre você e porque você quer investir na Orthopride:"
              rows="5"
            />
            <S.Button className="GTM-Franquia">
              {createLoading ? <S.Loading /> : "Enviar"}
            </S.Button>
          </Form>
        </S.Content>
      </S.Container>
      <Footer />
    </Scaffold>
  );
};

import React from "react";

import * as S from "./styles";
export const Tour: React.FC = () => {
  return (
    <>
      <S.Main>
        <h1>Conheça o Studio Orthopride</h1>
        <S.Frame src="https://my.matterport.com/show/?m=izjn1EqNyMq" />
      </S.Main>
    </>
  );
};

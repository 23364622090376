import styled from "styled-components";
import bg from "assets/images/franchising-banner.png";
import { ReactComponent as logo } from "assets/images/company-logo.svg";

export const Container = styled.section`
  width: 100%;
  height: 700px;
  margin: 0 auto;
  background: url(${bg}) no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 96px 100px 96px 96px;

  @media screen and (max-width: 1000px) {
    height: auto;
    background: #000;
    align-items: center;
  }
  @media screen and (max-width: 800px) {
    padding: 20px 15px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 480px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 50px;
`;

export const Logo = styled(logo)`
  margin-bottom: 24px;
`;

export const Title = styled.h1`
  font-size: 72px;
  line-height: 72px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.colorScheme.primary};

  span {
    font-family: ${({ theme }) => theme.fonts.BrandontextLight};
    color: white;
  }

  @media screen and (max-width: 540px) {
    font-size: 40px;
    line-height: 40px;
  }

  @media screen and (max-width: 414px) {
    font-size: 30px;
    line-height: 30px;
  }
`;

export const Text = styled.article`
  width: 100%;
  max-width: 380px;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: white;

  @media screen and (max-width: 540px) {
    font-size: 16px;
  }
`;

export const GroupForm = styled.div`
  width: 100%;
  max-width: 500px;
  background-color: rgba(85, 85, 85, 0.8);
  height: auto;
  border-radius: 10px;
  padding: 30px;

  p {
    font-family: ${({ theme }) => theme.fonts.BrandontextLight};
    color: ${({ theme }) => theme.colors.white};
    font-size: 26px;
    line-height: 1.2em;
    text-transform: uppercase;
    &.orange {
      font-family: ${({ theme }) => theme.fonts.BrandontextBold};
      color: ${({ theme }) => theme.colors.orange};
      margin-bottom: 20px;
    }
    @media screen and (max-width: 540px) {
      font-size: 23px;
    }
  }
  @media screen and (max-width: 1000px) {
    max-width: 80%;
  }
  @media screen and (max-width: 700px) {
    max-width: 90%;
  }
  @media screen and (max-width: 540px) {
    max-width: 100%;
  }
`;

export const MyForm = styled.form`
  input.field {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
  }
  select {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
  }
`;

export const SubmitButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 150px;
  padding: 15px;
  font-family: ${({ theme }) => theme.fonts.BrandontextBold};
  color: ${({ theme }) => theme.colors.white};
  font-size: large;
  background: ${({ theme }) => theme.colors.orange};
  border-radius: 5px;
  transition: 0.5s;
  &:hover {
    background: #cc821f;
  }
`;

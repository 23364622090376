import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { format, addHours } from "date-fns";

import * as S from "./styles";
import { Scaffold, Footer } from "components/Shared";
import { ThanksBanner } from "components/Booking";

import { IUnidade } from "interfaces/unidade";
import { IProspect } from "interfaces/prospect";
import { CreateBookingSuccess } from "store/ducks/booking/create-booking";

interface ILocationState {
  unidade: IUnidade;
  avaliacao: CreateBookingSuccess;
  prospect: IProspect;
}

export const BookingThanks: React.FC = () => {
  const history = useHistory();
  const { state } = useLocation<ILocationState>();

  return (
    <Scaffold>
      <ThanksBanner />
      <S.Container>
        <S.Title>Olá {state.prospect.Nome}</S.Title>
        <S.Text>
          <p>Obrigado por entrar em contato com a Orthopride!</p>
          <p>
            A avaliação do seu sorriso na unidade{" "}
            <span>{state.unidade.Nome}</span>, está agendada para:{" "}
            <span>
              {state.avaliacao.DataHoraAvaliacao &&
                format(
                  addHours(new Date(state.avaliacao.DataHoraAvaliacao), 3),
                  "dd/MM/yyyy HH:mm"
                )}
            </span>
            . Promover tratamento de ortodontia com foco na qualidade,
            excelência de atendimento e tornar as pessoas mais sorridentes é a
            nossa especialidade.
          </p>
          <p>
            Para dúvidas ou informações, entre em contato conosco pelo telefone:
          </p>
          <p>
            {state.unidade.Telefone1} • {state.unidade.Endereco}
          </p>
        </S.Text>
        <S.Button btStyle="secondary" onClick={() => history.push("/")}>
          Voltar à home
        </S.Button>
      </S.Container>
      <Footer />
    </Scaffold>
  );
};

import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import { ChevronDown } from "@styled-icons/bootstrap";

import { colors, colorScheme, fonts } from "styles";

export const Container = styled.div`
  width: 100%;
  padding-bottom: 32px;
  border-bottom: 1px ${colorScheme.primary} solid;
  margin-bottom: 32px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Question = styled.h3`
  font-size: 18px;
  font-family: ${fonts.BrandontextMedium};
  color: ${colors.dark};

  @media screen and (max-width: 414px) {
    font-size: 16px;
  }
`;

export const Button = styled(motion.button).attrs({
  type: "button",
})`
  display: flex;
  transform: rotate(0);
`;

export const Icon = styled(motion.div)`
  margin-left: 24px;
`;
export const Arrow = styled(ChevronDown).attrs({
  size: 24,
})``;

export const Response = styled(motion.div)<{ open: boolean }>`
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  overflow: hidden;
  ${({ open }) =>
    open
      ? css`
          height: auto;
          margin-top: 24px;
        `
      : css`
          height: 0;
        `};
`;

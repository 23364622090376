import styled from "styled-components";
import { colorScheme, colors } from "styles";

export const Container = styled.section`
  width: 100%;
  background-color: white;
  margin: 0 auto;
  position: relative;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1366px;
  padding: 88px 32px;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  @media screen and (max-width: 414px) {
    padding: 48px 32px;
  }
`;

export const Title = styled.h2`
  width: 100%;
  max-width: 480px;
  font-size: 46px;
  line-height: 46px;
  margin-bottom: 32px;
  text-align: center;
  color: ${colorScheme.secondary};

  span {
    color: ${colorScheme.primary};
  }

  @media screen and (max-width: 414px) {
    font-size: 30px;
    line-height: 30px;
  }
`;

export const Text = styled.article`
  width: 100%;
  max-width: 700px;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: ${colors.blueGray};
  margin-bottom: 80px;

  @media screen and (max-width: 414px) {
    font-size: 16px;
    margin-bottom: 24px;
  }
`;

export const fonts = {
  SourceSansProRegular: "SourceSansProRegular",
  SourceSansProBold: "SourceSansProBold",
  BrandontextBlack: "BrandontextBlack",
  BrandontextBold: "BrandontextBold",
  BrandontextLight: "BrandontextLight",
  BrandontextMedium: "BrandontextMedium",
  BrandontextRegular: "BrandontextRegular",
  Knockout69: "Knockout69",
  Knockout93: "Knockout93",
  Knockout46: "Knockout46",
  Knockout49: "Knockout49",
};

import { all, takeLatest } from "redux-saga/effects";

// Types
import {
  ListUnidadesTypes,
  ListUnidadesTagsTypes,
  FetchUnidadeTypes,
  GeolocationUnidadeTypes,
  FetchTagIndexTypes,
} from "../ducks/unidades";
import { CreateProspectTypes } from "../ducks/prospect";
import { ListBookingTypes, CreateBookingTypes } from "../ducks/booking";
import { ListCitiesTypes } from "../ducks/city";
import { CreateContactTypes } from "../ducks/contact";
import { CreateOuvidoriaTypes } from "../ducks/ouvidoria";
import { CreateFranchisingContactTypes } from "../ducks/franchising";
import {
  FetchPacienteTypes,
  SorteioAtivoTypes,
  ParticiparSorteioTypes,
} from "../ducks/parcela-premiada";

// Sagas
import {
  ListUnidadesRequest,
  ListUnidadesTagsRequest,
  FetchUnidadeRequest,
  GeolocationtUnidadesRequest,
} from "./unidades";
import { CreateProspectRequest } from "./prospect";
import { ListBookingRequest, CreateBookingRequest } from "./booking";
import { ListCitiesRequest } from "./city";
import { CreateContactRequest } from "./contact";
import { CreateOuvidoriaRequest } from "./ouvidoria";
import { CreateFranchisingContactRequest } from "./franchising";
import { CreateWhatsappContactRequest } from "./whatsappContact/";

import {
  FetchPacienteRequest,
  SorteioAtivoRequest,
  ParticiparSorteioRequest,
} from "./parcela-premiada";
import { FetchTagIndexRequest } from "./unidades/index-tag";
import { CreateWhatsappContactTypes } from "store/ducks/whatsappContact";

export default function* rootSaga() {
  yield all([
    // Unidades Studio
    takeLatest(ListUnidadesTagsTypes.REQUEST, ListUnidadesTagsRequest),
    takeLatest(FetchUnidadeTypes.REQUEST, FetchUnidadeRequest),
    takeLatest(FetchTagIndexTypes.REQUEST, FetchTagIndexRequest),
    takeLatest(GeolocationUnidadeTypes.REQUEST, GeolocationtUnidadesRequest),

    // Unidades
    takeLatest(ListUnidadesTypes.REQUEST, ListUnidadesRequest),
    takeLatest(FetchUnidadeTypes.REQUEST, FetchUnidadeRequest),
    takeLatest(GeolocationUnidadeTypes.REQUEST, GeolocationtUnidadesRequest),

    // Prospect
    takeLatest(CreateProspectTypes.REQUEST, CreateProspectRequest),

    // Booking
    takeLatest(ListBookingTypes.REQUEST, ListBookingRequest),
    takeLatest(CreateBookingTypes.REQUEST, CreateBookingRequest),

    // Cities
    takeLatest(ListCitiesTypes.REQUEST, ListCitiesRequest),

    // Contact
    takeLatest(CreateContactTypes.REQUEST, CreateContactRequest),

    // Ouvidoria
    takeLatest(CreateOuvidoriaTypes.REQUEST, CreateOuvidoriaRequest),

    // Franchising Contact
    takeLatest(
      CreateFranchisingContactTypes.REQUEST,
      CreateFranchisingContactRequest
    ),

    // Whatsapp Contact
    takeLatest(
      CreateWhatsappContactTypes.REQUEST,
      CreateWhatsappContactRequest
    ),

    // Parcela premiada - paciente
    takeLatest(FetchPacienteTypes.REQUEST, FetchPacienteRequest),

    // Parcela premiada - sorteio ativo
    takeLatest(SorteioAtivoTypes.REQUEST, SorteioAtivoRequest),

    // Parcela premiada - participar sorteio
    takeLatest(ParticiparSorteioTypes.REQUEST, ParticiparSorteioRequest),
  ]);
}

import styled from "styled-components";
import { colors, fonts } from "styles";

export { FieldError } from "styles/styled-components";

export const Checkbox = styled.input.attrs({
  type: "checkbox",
})`
  position: absolute;
  left: -30000px;
`;

export const Label = styled.div`
  font-family: ${fonts.BrandontextBold};
  font-size: 14px;
  color: ${colors.blueGray};
  margin-right: 8px;
`;

export const ToggleContainer = styled.div.attrs({
  className: "toogle-container",
})`
  width: 34px;
  display: flex;
  align-items: center;
`;

export const ToggleTrack = styled.div.attrs({ className: "track" })`
  display: flex;
  align-items: center;
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background-color: ${colors.blueGray}66;
  position: relative;
`;

export const ToggleHandler = styled.div.attrs({ className: "handler" })`
  flex: 0 0 auto;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background-color: ${colors.blueGray};
  margin-left: 0px;
  transition: margin-left 120ms linear;
`;

export const MainContainer = styled.div`
  flex-direction: column;
`;

export const FieldContainer = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;

  align-items: center;
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  max-height: 50px;
  padding: 16px;
  background: #fff;

  cursor: pointer;
  > input:checked + div.toogle-container {
    div.handler {
      background-color: ${colors.orange};
      margin-left: 20px;
    }
    div.track {
      background-color: ${colors.orange}66;
    }
  }
`;

import styled from "styled-components";
import toxinaImg from "assets/images/orofacial/indicacoes/toxina.png";
import preenchimentoImg from "assets/images/orofacial/indicacoes/preenchimento.jpg";
import { fonts } from "styles";

interface iControle {
  color: string;
  bg: string;
}

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  max-width: 1350px;
  min-height: 300px;
  margin: 0 auto;
  p {
    font-family: ${fonts.SourceSansProRegular};
    font-size: 12px;
    color: black;
    width: 90%;
    text-align: center;
    max-width: 660px;
    margin: 35px auto;
  }
  @media screen and (max-width: 900px) {
    max-width: 100%;
  }
`;

export const Title = styled.h1`
  font-family: ${fonts.BrandontextBold};
  font-size: 46px;
  letter-spacing: -1px;
  color: #222;
  margin-bottom: 15px;
  text-align: center;
  line-height: 1em;
  @media screen and (max-width: 900px) {
    font-size: xx-large;
  }
`;

export const SubTitle = styled.h4`
  width: 95%;
  text-align: center;
  font-family: ${fonts.BrandontextRegular};
  font-size: 18px;
  color: #222;
  line-height: 1.5em;
`;

export const GroupControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 470px;
  height: auto;
  margin: 60px auto 20px auto;
  @media screen and (max-width: 640px) {
    flex-direction: column;
    row-gap: 20px;
  }
`;

export const Control = styled.a.attrs((props: iControle) => ({
  color: props.color,
  bg: props.bg,
}))<iControle>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 235px;
  color: ${(props) => props.color};
  background: ${(props) => props.bg};
  padding: 15px 20px;
  font-family: ${fonts.BrandontextBold};
  font-size: x-large;
  cursor: pointer;
  transition: 0.5s;
`;

export const AreaImg = styled.div`
  width: 90%;
  max-width: 700px;
  height: auto;
  margin: 0 auto;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
`;

export const ToxinaImage = styled.img.attrs({ src: toxinaImg })`
  width: 100%;
  height: auto;
  transition: 0.5s;
`;
export const preenchimentoImage = styled.img.attrs({ src: preenchimentoImg })`
  width: 100%;
  height: auto;
  transition: 0.5s;
`;

export const OrangeBar = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
  background: #f09327;
  height: auto;
  align-items: center;
  justify-content: center;
  h2 {
    font-family: ${fonts.BrandontextBold};
    color: #222;
    font-size: 46px;
    text-align: center;
    max-width: 730px;
    line-height: 1em;
    margin-bottom: 30px;
  }
  p {
    font-family: ${fonts.SourceSansProRegular};
    color: #fff;
    width: 90%;
    max-width: 577px;
    text-align: center;
    font-size: 15px;
  }
`;

import styled from "styled-components";
import { RightArrowAlt, LeftArrowAlt } from "@styled-icons/boxicons-regular/";

export const Container = styled.div`
  width: 100%;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  @media screen and (max-width: 890px) {
    display: flex;
  }
`;

export const WrapperSlider = styled.div`
  
  width: 100%;
  div.item img {
    max-width: 50%;
    height auto;
    transition: 300ms ease;
    margin: 0 auto;
    
    
    /* @media screen and (max-width: 570px) {
      max-width: 40%;
      margin-left: 30%;
    } */
  }
  .slick-prev:before,
  .slick-next:before {
    content: "";
    display: none;
  }
  div.item {
    display: flex;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
 
  }

  div.item a {
    display: flex;
    font-size: 30px;
    color: #fff;
    width: max-content;
    font-family: "Knockout49";
    text-transform: uppercase;
    margin: 20px auto !important;
    transition: 1s;
    &:hover {
      color: yellow;
    }
  }
    div.item a img {
    width:80%;
    max-width: 200px !important;
    height:auto;
  }
`;

export const IconNext = styled(RightArrowAlt).attrs({ size: 24 })`
  color: ${({ theme }) => theme.colors.dark};
`;

export const IconPrev = styled(LeftArrowAlt).attrs({ size: 24 })`
  color: ${({ theme }) => theme.colors.dark};
`;

export const PrevButton = styled.button.attrs({ type: "button" })`
  width: 32px;
  height: 32px;
  position: absolute;
  background-color: white;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 300ms ease;
  transform-origin: center center;
  z-index: 99 !important;
  left: 10px;

  &:hover {
    background: white !important;
  }

  &:focus {
    background: white !important;
  }
  @media screen and (max-width: 540px) {
    max-width: 30px;
    max-height: 30px;
  }
`;
export const NextButton = styled(PrevButton)`
  left: auto;
  right: 10px;
`;

import React from "react";
import { useHistory } from "react-router-dom";
import * as S from "./styles";

export const Kits: React.FC = () => {
  const history = useHistory();
  return (
    <S.Background>
      <S.Container>
        <S.Content>
          <S.Wrapper>
            <S.Title>
              <span>prevenção</span> kids
            </S.Title>
            <S.Text>
              Quando se trata de saúde bucal infantil, uma das principais
              preocupações é a limpeza dos dentes. Responsável por prevenir o
              acúmulo de placa bacteriana, a escovação e o uso do fio dental são
              as melhores formas de manter uma boa higiene bucal. Mas, assim
              como acontece com os adultos, as crianças também devem realizar a
              profilaxia dentária no consultório para manter os dentes de leite
              e os permanentes saudáveis.
            </S.Text>
            <S.Button
              onClick={() => history.push("/agendar-avaliacao")}
              btStyle="outline"
            >
              {" "}
              Encontre sua unidade
            </S.Button>
          </S.Wrapper>
        </S.Content>
        <S.Content>
          <S.Figure />
        </S.Content>
      </S.Container>
    </S.Background>
  );
};

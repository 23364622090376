import styled from "styled-components";
import { colors, fonts } from "styles";

export const Container = styled.div`
  width: 100%;
  background-color: ${colors.grayLight};
  padding-bottom: 110px;
  padding-right: 32px;
  padding-left: 32px;

  @media screen and (max-width: 414px) {
    padding-bottom: 48px;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 786px;
  margin: 0 auto;
`;

export const Title = styled.h2`
  font-size: 69px;
  line-height: 69px;
  color: ${colors.dark};
  text-align: center;
  margin-bottom: 56px;
  text-transform: uppercase;

  span {
    font-family: ${fonts.BrandontextLight};
  }

  @media screen and (max-width: 414px) {
    font-size: 36px;
    line-height: 36px;
  }
`;

import styled from "styled-components";
import { ReactComponent as logo } from "assets/images/company-logo.svg";
import { colors, fonts } from "styles";
import bg from "assets/images/franchising2024/bg-banner.jpg";

export const Container = styled.div`
  display:flex;
  align-items:center;
  justify-content:center
  width: 100%;
  max-width:1920px;
  height:auto;
  padding: 100px;
  background: ${colors.dark};
  @media screen and (min-width: 1720px) {
    display:none;
  }
  @media screen and (max-width: 900px) {
    padding: 50px 20px;
  }
`;

export const GroupForm = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;
  background-color: rgba(85, 85, 85, 0.8);
  height: auto;
  border-radius: 10px;
  padding: 30px;
  z-index: 7;
  margin: 0 auto;

  p {
    font-family: ${({ theme }) => theme.fonts.BrandontextLight};
    color: ${({ theme }) => theme.colors.white};
    font-size: 26px;
    line-height: 1.2em;
    text-transform: uppercase;
    &.orange {
      font-family: ${({ theme }) => theme.fonts.BrandontextBold};
      color: ${({ theme }) => theme.colors.orange};
      margin-bottom: 20px;
    }
    @media screen and (max-width: 540px) {
      font-size: 23px;
    }
  }
  @media screen and (max-width: 1000px) {
    max-width: 80%;
  }
  @media screen and (max-width: 700px) {
    max-width: 90%;
  }
  @media screen and (max-width: 540px) {
    max-width: 100%;
  }
`;

export const MyForm = styled.form`
  input.field {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
  }
  select {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
  }
`;

export const SubmitButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 150px;
  padding: 15px;
  font-family: ${({ theme }) => theme.fonts.BrandontextBold};
  color: ${({ theme }) => theme.colors.white};
  font-size: large;
  background: ${({ theme }) => theme.colors.orange};
  border-radius: 5px;
  transition: 0.5s;
  &:hover {
    background: #cc821f;
  }
`;

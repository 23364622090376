import React, { useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as S from "./styles";
import { Scaffold, Footer } from "components/Shared";
import { ThanksBanner } from "components/Franchising";
import GTMDataLayer from "utils/GTM-dataLayer";

type Produtos = {
  idProduto: number;
};

interface ILocationState {
  name: string;
  capex: string;
  city: string;
  email: string;
  phone: string;
  message: string;
  state: string;
  id: number;
  produtosInteresse: Produtos[] | null;
}

export const Thanks: React.FC = () => {
  const history = useHistory();
  const { state } = useLocation<ILocationState>();

  const setGTMdataLayer = useCallback(() => {
    GTMDataLayer.B2BForm({
      modeloDeInteresse: state?.capex,
      nome: state?.name,
      email: state?.email,
      telefone: state?.phone,
      cidade: state?.city,
      estado: state?.state,
      idProspect: state?.id,
      produtosInteresse: [],
    });
  }, [state]);

  useEffect(() => {
    setGTMdataLayer();
  }, [setGTMdataLayer]);
  return (
    <Scaffold>
      <ThanksBanner />
      <S.Container>
        <S.Title>Olá {state.name}</S.Title>
        <S.Text>
          Obrigado por entrar em contato com a Orthopride. Nossa equipe já
          recebeu suas informações e entraremos em contato em breve.
        </S.Text>
        <S.Button btStyle="secondary" onClick={() => history.push("/")}>
          Voltar à home
        </S.Button>
      </S.Container>
      <Footer />
    </Scaffold>
  );
};

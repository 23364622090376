import styled from "styled-components";
import { colors, fonts } from "styles";

import Medina from "assets/images/franchising2024/medina.png";
import Arrow from "assets/images/franchising2024/list-style-black.png";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 1920px;
  height: auto;
  background: ${colors.orange};
  padding: 100px;
  @media screen and (max-width: 1720px) {
    justify-content: center;
  }
  @media screen and (max-width: 1200px) {
    padding: 50px 30px;
  }
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const ImgMedina = styled.img.attrs({ src: Medina })`
  display: block;
  width: 90%;
  max-width: 500px;
  height: auto;
  margin-right: 20px;
  @media screen and (max-width: 1340px) {
    max-width: 400px;
  }
  @media screen and (max-width: 900px) {
    width: 90%;
    max-width: 250px;
    margin: 30px auto;
  }
`;

export const GroupInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;

  height: auto;
  h2.title {
    font-family: ${fonts.BrandontextLight};
    color: ${colors.white};
    font-size: 60px;
    line-height: 1.1em;
    margin-left: 40px;
    span {
      font-family: ${fonts.BrandontextBold};
    }
    margin-bottom: 20px;
    @media screen and (max-width: 1340px) {
      text-align: left;
      margin-left: 0;
    }
    @media screen and (max-width: 1100px) {
      font-size: 50px;
    }
    @media screen and (max-width: 900px) {
      text-align: center;
    }
  }
`;

export const Chev = styled.img.attrs({ src: Arrow })`
  margin-top: 10px;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  @media screen and (max-width: 540px) {
    margin-top: 9px;
  }
`;

export const ListItems = styled.ul`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  list-style-type: none; // Remova a lista de estilo padrão
  /* list-style-image: url(${Arrow}); // Defina a imagem de fundo da lista */
  margin: 10px auto 0 auto;

  li.item {
    display: flex;

    width: 100%;
    max-width: 350px;
    height: auto;
    font-family: ${fonts.BrandontextBold};
    color: ${colors.black};
    font-size: 30px;
    letter-spacing: -1px;
    line-height: 1.1em;
    margin-bottom: 25px;
    padding-top: -30px;

    @media screen and (max-width: 1100px) {
      font-size: 25px;
    }
  }
  @media screen and (max-width: 900px) {
    margin-left: 80px;
  }
`;

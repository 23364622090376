import { call, put } from "redux-saga/effects";

import { api, notify } from "services";
import { ICreateRequest } from "interfaces/create-duck";

import { requestErrorHandler } from "utils";
import { CreateBookingActions } from "store/ducks/booking";

export function* CreateBookingRequest(action: any) {
  try {
    const { postData, onSuccess } = action as ICreateRequest;
    const { data } = yield call(api.post, `/avaliacao`, postData);

    yield put(CreateBookingActions.success(data));
    if (onSuccess) onSuccess(data);
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(CreateBookingActions.failure(errorMessage));
  }
}

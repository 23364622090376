import { v4 as uuidV4 } from "uuid";

export interface ITip {
  id: string;
  number: number;
  title: string;
  text: string;
}

export const tips: Array<ITip> = [
  {
    id: uuidV4(),
    number: 1,
    title: "Escaneamento 3D",
    text: "Com o escaneamento 3D, você e seu dentista definem e visualizam o resultado desejado. Nessa etapa também é possível prever  a duração do seu tratamento.",
  },
  {
    id: uuidV4(),
    number: 2,
    title: "Confecção",
    text: "Com o escaneamento 3D, você e seu dentista poderão definir e visualizar o resultado desejado. Nessa etapa é possível prever também a duração do seu tratamento.",
  },
  {
    id: uuidV4(),
    number: 3,
    title: "Acompanhamento",
    text: "Seu dentista vai acompanhar cada etapa do seu tratamento e também fazer as manutençõees necessárias em seu alinhador.",
  },
];

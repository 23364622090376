import React from "react";

import * as S from "./styles";

export const Banner: React.FC = () => {
  return (
    <S.Container>
      <S.Wrapper>
        <S.Title>
          <span>Atendimento</span> ao Cliente
        </S.Title>
      </S.Wrapper>
    </S.Container>
  );
};

import { call, put } from "redux-saga/effects";

import { apiUnidadesGeral, notify } from "services";
import { IListRequest } from "interfaces/list-duck";

import { requestErrorHandler } from "utils";
import { ListUnidadesTagsActions } from "store/ducks/unidades";

export function* ListUnidadesTagsRequest(action: any) {
  try {
    const { query = { tag: "hof" }, onSuccess } = action as IListRequest;
    const url = `https://smartinstitucional.orthopride.com.br/api/unidades/tags/${query.tag}`;
    const { data } = yield call(apiUnidadesGeral.get, url);
    yield put(ListUnidadesTagsActions.success(data));
    if (onSuccess) onSuccess(data);
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(ListUnidadesTagsActions.failure(errorMessage));
  }
}

import React from "react";
import * as S from "./styles";

export const ReasonsInvest: React.FC = () => {
  return (
    <>
      <S.Container>
        <S.GroupMainText>
          <h2>10</h2>
          <h3>
            Motivos
            <br />
            para
            <br />
            Investir na
            <br />
            <span>Orthopride</span>
          </h3>
          <S.GroupInfo>
            <S.Card>
              <h3>1</h3>
              <div>
                <p>
                  Você não precisa ser dentista para ser um Franqueado
                  Orthopride
                </p>
              </div>
            </S.Card>
            <S.Card>
              <h3>2</h3>
              <div>
                <p>95% dos Brasileiros necessitam de tratamento ortodôntico</p>
              </div>
            </S.Card>
            <S.Card>
              <h3>3</h3>
              <div>
                <p>
                  Negócio focado em serviço que gera recorrência de pagamento
                </p>
              </div>
            </S.Card>
            <S.Card>
              <h3>4</h3>
              <div>
                <p>Nenhum caso de falência na rede</p>
              </div>
            </S.Card>
            <S.Card>
              <h3>5</h3>
              <div>
                <p>
                  90% dos Franqueados possuem mais de uma Unidade em operação
                </p>
              </div>
            </S.Card>
            <S.Card>
              <h3>6</h3>
              <div>
                <p>
                  Modelo de negócio consolidado, há mais de 15 anos no mercado
                </p>
              </div>
            </S.Card>
            <S.Card>
              <h3>7</h3>
              <div>
                <p>Operação de baixa complexidade e alta rentabilidade</p>
              </div>
            </S.Card>
            <S.Card>
              <h3>8</h3>
              <div>
                <p>
                  Segmento de saúde, beleza e bem-estar em contínuo crescimento
                </p>
              </div>
            </S.Card>
            <S.Card>
              <h3>9</h3>
              <div>
                <p>Melhor metodologia de captação de pacientes no mercado</p>
              </div>
            </S.Card>
            <S.Card className="margin">
              <h3>10</h3>
              <div>
                <p>
                  Software e app’s próprios de alta performance para gestão do
                  negócio
                </p>
              </div>
            </S.Card>
          </S.GroupInfo>
          <S.CaioJumping alt="Caio Castro" />
        </S.GroupMainText>
      </S.Container>
    </>
  );
};

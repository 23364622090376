import styled from "styled-components";
import { colors, colorScheme, fonts } from "styles";

export const Container = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  min-height: 600px;
  background: #2c2d2f;
`;

export const Title = styled.h1`
  width: 90%;
  max-width: 600px;
  height: auto;
  text-align: center;
  font-family: ${fonts.BrandontextBold};
  color: #fff;
  line-height: 1em;
  font-size: 50px;
  margin: 50px auto;
  span {
    color: #fea947;
  }
  @media screen and (max-width: 540px) {
    font-size: xx-large;
  }
`;
export const SubTitle = styled.h2`
  font-family: ${fonts.SourceSansProRegular};
  color: #fff;
  width: 90%;
  max-width: 900px;
  text-align: center;
  margin: 0 auto 30px auto;
`;

export const GroupInfo = styled.div`
  display: flex;
  width: 90%;
  max-width: 1200px;
  min-height: 300px;
  margin: 0 auto;
  @media screen and (max-width: 1125px) {
    flex-direction: column;
  }
`;

export const Lside = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  height: auto;
  padding: 20px;
  img {
    width: 100%;
    max-width: 500px;
    height: auto;
  }
  @media screen and (max-width: 1125px) {
    width: 100%;
    padding: 20px;
  }
`;
export const Rside = styled.div`
  width: 60%;
  height: auto;
  padding: 35px;
  img.logo {
    margin: 50px 0;
    width: 90%;
    max-width: 320px;
    height: auto;
    @media screen and (max-width: 1125px) {
      max-width: 300px;
    }
  }
  p {
    font-family: ${fonts.SourceSansProRegular};
    font-size: 18px;
    color: #fff;
    width: 90%;
    max-width: 450px;
    margin-bottom: 30px;
    @media screen and (max-width: 1125px) {
      text-align: center;
    }
  }
  a.callToAction {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${fonts.SourceSansProBold};
    width: 90%;
    max-width: 200px;
    background: #fea947;
    padding: 15px;
    color: #fff;
    cursor: pointer;
    @media screen and (max-width: 1125px) {
      margin-bottom: 80px;
    }
  }

  @media screen and (max-width: 1125px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 20px;
  }
`;

import React from "react";
import Slider from "react-slick";

import * as S from "./styles";

export const Gallery: React.FC = () => {
  const settings = {
    slidesToShow: 4,
    speed: 500,
    adaptiveHeight: true,
    nextArrow: (
      <S.NextButton>
        <S.IconNext />
      </S.NextButton>
    ),
    prevArrow: (
      <S.PrevButton>
        <S.IconPrev />
      </S.PrevButton>
    ),
    responsive: [
      {
        breakpoint: 801,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 541,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <S.Container>
      <S.WrapperSlider>
        <Slider {...settings}>
          <img src="gallery/company/01.jpg" alt="company" />
          <img src="gallery/company/02.jpg" alt="company" />
          <img src="gallery/company/03.jpg" alt="company" />
          <img src="gallery/company/04.jpg" alt="company" />
          <img src="gallery/company/05.jpg" alt="company" />
          <img src="gallery/company/06.jpg" alt="company" />
        </Slider>
      </S.WrapperSlider>
    </S.Container>
  );
};

import styled from "styled-components";
import { fonts } from "styles";

interface ButtonProps {
  active: boolean;
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 32px 0;
`;

export const Button = styled.button<ButtonProps>`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border-radius: 6px;
  transition: 300ms ease;
  border: 1px transparent solid;
  border-radius: 6px;
  margin-right: 4px;
  font-family: ${fonts.BrandontextBold};
  color: ${({ active, theme }) => (active ? "#fff" : theme.colors.light)};
  background-color: ${({ active, theme }) =>
    active ? theme.colorScheme.primary : theme.colors.disable};

  &:hover {
    background-color: ${({ theme }) => theme.colorScheme.primary};
    color: #fff;
  }

  &:last-child {
    margin-right: 0;
  }
`;

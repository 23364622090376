import styled from "styled-components";

export const PageHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 24px 32px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);

  h1 {
    font-size: 20px;
    color: ${({ theme }) => theme.colorScheme.text};
    display: flex;
    align-items: center;

    span {
      font-family: ${({ theme }) => theme.fonts.SourceSansProRegular};
      text-transform: uppercase;
      margin-left: 8px;
      font-size: 18px;
    }

    svg {
      margin-right: 8px;
    }
  }
`;

export const PageContainer = styled.main`
  width: 100%;
  max-width: 1920px;
  min-height: 100vh;
  margin: 0 auto;
`;

export const PageContent = styled.section`
  width: 100%;
  padding: 40px 32px;
`;

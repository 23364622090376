import styled from "styled-components";
import { fonts } from "styles";
export { Button } from "styles/styled-components";

export const Container = styled.section`
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
  padding: 64px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.SourceSansProBold};
  font-size: 30px;
  line-height: 30px;
  margin-bottom: 40px;
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
`;

export const Text = styled.article`
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 40px;
  text-align: center;

  span {
    font-family: ${fonts.SourceSansProBold};
  }

  p {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

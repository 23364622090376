import React from "react";
import { useHistory } from "react-router-dom";
import * as S from "./styles";
import { LegalText } from "components/Shared";

export const Raspagem: React.FC = () => {
  const history = useHistory();
  const animate = {
    flying: {
      y: [0, 10, 0],
      transition: { ease: "easeInOut", duration: 4, repeat: Infinity },
    },
  };
  return (
    <S.Background>
      <S.Container>
        <S.Content>
          <S.Wrapper>
            <S.Subtitle>Odontologia estética</S.Subtitle>
            <S.Title>
              Raspagem <span>de tártaro</span>
            </S.Title>
            <LegalText
              text="Clínico geral com atuação em periodontia"
              color="white"
            />
            <S.Text>
              Tratamento indicado para remover a placa bacteriana que se
              calcificou e se transformou em tártaro (cálculo dental), deixando
              o dente liso e polido, diminuindo assim o sangramento e devolvendo
              a saúde do periodonto (tecido que sustenta os dentes).
            </S.Text>
            <S.Button onClick={() => history.push("/agendar-avaliacao")}>
              Marcar uma avaliação
            </S.Button>
          </S.Wrapper>
        </S.Content>
        <S.Content>
          <S.Ball animate="flying" variants={animate} />
          <S.Figure />
        </S.Content>
      </S.Container>
    </S.Background>
  );
};

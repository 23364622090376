import React, { useRef, useCallback, useState } from "react";
import Slider from "react-slick";

import * as S from "./styles";
import { Item } from "./Item";
import { LegalText } from "components/Shared";
import { treatments, ITreatment, TreatmentTypes } from "data/treatments";

export const Braces: React.FC = () => {
  const sliderTreatments = useRef<HTMLDivElement>(null);
  const [selected, setSelected] = useState<TreatmentTypes>("fixos");

  const settings = {
    speed: 500,
    adaptiveHeight: true,
    slidesToShow: 1,
    dots: true,
  };

  const handleSlider = useCallback(
    (position: number, select: TreatmentTypes) => {
      const widthElement = sliderTreatments.current?.offsetWidth || 0;
      sliderTreatments.current?.scrollTo(position * widthElement, 0);
      setSelected(select);
    },
    []
  );
  return (
    <S.Container>
      <S.Title>Ortodontia</S.Title>
      <LegalText text="RT.: Dr. Fábio Fujioka - CRO 23444 RJ, especialista em Ortodontia e Ortopedia Facial" />
      <S.SubTitle>Selecione um tipo de aparelho:</S.SubTitle>
      <S.Wrapper>
        <S.ButtonType
          active={selected === "fixos"}
          onClick={() => handleSlider(0, "fixos")}
        >
          Fixos <S.Triangle />
        </S.ButtonType>
        <S.ButtonType
          active={selected === "removivel"}
          onClick={() => handleSlider(1, "removivel")}
        >
          Removíveis <S.Triangle />
        </S.ButtonType>
        <S.ButtonType
          active={selected === "esteticos"}
          onClick={() => handleSlider(2, "esteticos")}
        >
          Estéticos <S.Triangle />
        </S.ButtonType>
        <S.ButtonType
          active={selected === "autoligados"}
          onClick={() => handleSlider(3, "autoligados")}
        >
          Autoligados <S.Triangle />
        </S.ButtonType>
        <S.ButtonType
          active={selected === "transparentes"}
          onClick={() => handleSlider(4, "transparentes")}
        >
          Transparentes<sup>Novo!</sup>
          <S.Triangle />
        </S.ButtonType>
      </S.Wrapper>
      <S.TreatmentsWrapper ref={sliderTreatments}>
        {treatments.map((treatment: ITreatment, index) => (
          <Item key={treatment.id} treatment={treatment} position={index} />
        ))}
      </S.TreatmentsWrapper>
      <S.TreatmentsMobile>
        <Slider {...settings}>
          {treatments.map((treatment: ITreatment, index) => (
            <Item key={treatment.id} treatment={treatment} position={index} />
          ))}
        </Slider>
      </S.TreatmentsMobile>
      <S.DragText>Arraste para o lado</S.DragText>
    </S.Container>
  );
};

import styled from "styled-components";

import futebol from "assets/images/futebol-half-page.png";
import SponsorBotafogo from "assets/images/sponsor-botafogo.png";
import SponsorCorinthians from "assets/images/sponsor-corinthians.png";
import SponsorFlamengo from "assets/images/sponsor-flamengo.png";
import SponsorFluminense from "assets/images/sponsor-fluminense.png";
import medinaFirula from "assets/images/sponsor-medina-firula-big.png";
import medinaFirulaMobile from "assets/images/sponsor-medina-firula-mobile.png";
import medina from "assets/images/sponsor-medina.png";
import SponsorRioOpen from "assets/images/sponsor-rio-open.png";

export const Container = styled.section`
  display: flex;
  width: 100%;
  height: auto;
  @media screen and (max-width: 1240px) {
    flex-wrap: wrap;
  }
  @media screen and (max-width: 540px) {
    min-height: 800px;
  }
`;

export const Subtitle = styled.h3`
  font-size: 14px;
  text-transform: uppercase;
  color: white;
  margin-bottom: 8px;
  width: 100%;
  max-width: 154px;
  position: relative;
  z-index: 3;
`;

export const Title = styled.h2`
  font-size: 42px;
  line-height: 42px;
  color: white;
  margin-bottom: 16px;
  text-align: center;
  width: 100%;
  max-width: 250px;
  position: relative;
  z-index: 3;
`;

export const Text = styled.article`
  font-size: 14px;
  line-height: 18px;
  color: white;
  width: 100%;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 33.33%;
  height: 654px;
  position: relative;
  padding: 64px 32px;

  @media screen and (max-width: 980px) {
    width: 100%;
  }
`;

export const MedinaBox = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 180px;
  margin: 50px auto 0 auto;
  text-align: center;

  @media screen and (max-width: 1925px) {
    position: absolute;
    top: -20px;
    left: 400px;
    margin-top: 84px;
  }
  @media screen and (max-width: 1255px) {
    position: absolute;
    top: -20px;
    left: 350px;
  }

  @media screen and (max-width: 768px) {
    position: absolute;
    top: -20px;
    left: 300px;
  }

  @media screen and (max-width: 540px) {
    position: absolute;
    top: -20px;
    left: 200px;
  }
  @media screen and (max-width: 480px) {
    position: absolute;
    top: -25px;
    left: 300px;
  }
  @media screen and (max-width: 375px) {
    position: absolute;
    top: -25px;
    left: 180px;
  }
`;

export const Medina = styled.div`
  position: relative;
  width: 50%;
  background: #5a697e;
  h2 {
    color: #3acdd3;
  }

  article {
    width: 100%;
    max-width: 154px;
  }
  @media screen and (max-width: 1240px) {
    width: 100%;
    min-height: 500px;
  }
`;

export const Firula = styled.img.attrs({ src: medinaFirula })`
  width: 100%;
  height: auto;
  max-width: max-content;
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 64px;
  @media screen and (max-width: 1240px) {
    display: none;
  }
  @media screen and (max-width: 768px) {
    display: block;
  }
`;
export const FirulaMobile = styled.img.attrs({ src: medinaFirulaMobile })`
  display: none;
  width: 100%;
  height: auto;
  max-width: max-content;
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 64px;
  @media screen and (max-width: 1240px) {
    display: block;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const PhotoMedina = styled.img.attrs({ src: medina })`
  width: auto;
  height: 95%;
  max-width: max-content;
  position: absolute;
  z-index: 2;
  left: 50px;
  bottom: 0;

  @media screen and (max-width: 414px) {
    left: -48px;
  }
`;

export const Futebol = styled(Box)`
  width: 50%;
  background: black url(${futebol}) no-repeat center center;
  background-size: cover;
  text-align: center;
  align-items: center;

  h3 {
    color: ${({ theme }) => theme.colorScheme.primary};
  }

  h2 {
    text-align: center;
  }

  article {
    width: 100%;
    max-width: 360px;
  }
  @media screen and (max-width: 1240px) {
    width: 100%;
    min-height: 500px;
  }
`;

export const GroupTimes = styled.div`
  position: absolute;
  bottom: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  max-width: 400px;
  min-height: 100px;
  column-gap: 20px;
  @media screen and (max-width: 540px) {
    width: 280px;
    height: auto;
    flex-wrap: wrap;
    row-gap: 20px;
  }
`;

export const LogoFlamengo = styled.img.attrs({ src: SponsorFlamengo })`
  width: 100%;
  max-width: 82px;
  height: auto;
  flex-shrink: 1;
`;
export const LogoBotafogo = styled.img.attrs({ src: SponsorBotafogo })`
  width: 100%;
  max-width: 82px;
  height: auto;
`;
export const LogoCoringao = styled.img.attrs({ src: SponsorCorinthians })`
  width: 100%;
  max-width: 82px;
  height: auto;
`;
export const LogoFlu = styled.img.attrs({ src: SponsorFluminense })`
  width: 100%;
  max-width: 82px;
  height: auto;
`;
export const LogoRioOpen = styled.img.attrs({ src: SponsorRioOpen })`
  width: 150px;
  max-height: 26px;
`;

import { v4 as uuidV4 } from "uuid";

export interface IFaq {
  id: string;
  question: string;
  response: string;
}

export const faqFranchising: Array<IFaq> = [
  {
    id: uuidV4(),
    question:
      "1 - Quais são os requisitos para se tornar um franqueado Orthopride?",
    response:
      "Buscamos franqueados comprometidos com o sucesso da rede e de sua unidade, apaixonados por resultados e com visão de futuro. Além disso, é importante ressaltar que NÃO é necessário ter experiência na área de odontologia ou ser dentista para ser um franqueado Orthopride, já que, a Orthopride oferece todo o suporte operacional para a gestão da franquia.",
  },
  {
    id: uuidV4(),
    question:
      "2 - Qual é o investimento inicial necessário para abrir uma clínica Orthopride?",
    response:
      "O investimento inicial pode variar dependendo de vários fatores, como localização, tamanho da clínica e necessidades específicas do mercado local. Em média, o investimento inicial varia entre R$ 400.000 para clínicas pequenas e R$ 570.000 para clínicas médias. Este é o valor total do investimento que inclui taxa de franquia, equipamentos, mobiliário, estoque inicial, despesas operacionais iniciais, além do capital de giro também.",
  },
  {
    id: uuidV4(),
    question: "3 - Quanto tempo leva para recuperar o investimento inicial?",
    response:
      "O tempo necessário para recuperar o investimento inicial pode variar, mas geralmente nossos franqueados veem um retorno sobre o investimento dentro de um período de 24 a 36 meses.",
  },
  {
    id: uuidV4(),
    question: "4 - Qual é o suporte oferecido aos franqueados da Orthopride?",
    response:
      "Nossos franqueados recebem um amplo suporte antes, durante e após a abertura de suas clínicas. Isso inclui assistência na seleção do local, treinamento inicial e contínuo, suporte de marketing e publicidade, fornecimento de materiais e equipamentos de alta qualidade.",
  },
  {
    id: uuidV4(),
    question:
      "5 - Quais são as vantagens de se tornar um franqueado Orthopride em comparação com abrir uma clínica independente?",
    response:
      "Como franqueado Orthopride, você se beneficia do reconhecimento de uma marca estabelecida e de uma rede de suporte sólida. Além disso, você tem acesso a processos operacionais comprovadamente eficazes, tecnologia avançada, pesquisa e desenvolvimento contínuos, poder de compra coletivo e estratégias de marketing eficazes. Isso ajuda a reduzir riscos e aumentar as chances de sucesso em comparação com iniciar uma clínica independente.",
  },
];

import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

const { Types, Creators } = createActions(
  {
    request: ["query", "onSuccess"],
    success: ["data"],
    failure: ["error"],
    reset: [],
  },
  { prefix: "SORTEIO_ATIVO_" }
);

export interface SorteioAtivoState {
  data: Record<string, any> | null;
  loading: boolean;
  error: string | null;
}

export interface ISorteioAtivoRequest {
  query?: Record<string, any>;
  onSuccess?: Function;
}

export interface ISuccessAction {
  data: Record<string, any>;
}

export interface IFailureAction {
  error: string;
}

const INITIAL_STATE: SorteioAtivoState = {
  data: null,
  loading: false,
  error: null,
};

const request = (state: SorteioAtivoState) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

const success = (state: SorteioAtivoState, action: ISuccessAction) =>
  update(state, {
    data: { $set: action.data },
    loading: { $set: false },
  });

const failure = (state: SorteioAtivoState, action: IFailureAction) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

const reset = () => INITIAL_STATE;

export const sorteioAtivo = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
});

export const SorteioAtivoTypes = Types;
export const SorteioAtivoActions = Creators;

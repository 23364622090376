import styled from "styled-components";
import { RightArrowAlt, LeftArrowAlt } from "@styled-icons/boxicons-regular/";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: -4px;
`;

export const WrapperSlider = styled.div`
  width: 100%;

  img {
    max-width: 500px;
    max-height: 500px;
    transition: 300ms ease;
  }
  .slick-prev:before,
  .slick-next:before {
    content: "";
    display: none;
  }
`;

export const IconNext = styled(RightArrowAlt).attrs({ size: 24 })`
  color: ${({ theme }) => theme.colors.dark};
`;

export const IconPrev = styled(LeftArrowAlt).attrs({ size: 24 })`
  color: ${({ theme }) => theme.colors.dark};
`;

export const PrevButton = styled.button.attrs({ type: "button" })`
  width: 64px;
  height: 64px;
  position: absolute;
  background-color: white;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 300ms ease;
  transform-origin: center center;
  z-index: 99 !important;
  left: 10px;

  &:hover {
    background: white !important;
  }

  &:focus {
    background: white !important;
  }
`;
export const NextButton = styled(PrevButton)`
  left: auto;
  right: 10px;
`;

import React from "react";

import * as S from "./styles";

export const Manuais: React.FC = () => {
  return (
    <S.Container>
      <S.Title>Manuais</S.Title>
      <S.Subtitle>Operacional</S.Subtitle>
      <S.Text>
        Todas as atividades operacionais da clínica são descritas de maneira
        clara e didática, oferecendo aos colaboradores e gerentes facilidade
        para manter o padrão de cada atividade operacional do consultório. O
        manual foi desenvolvido em parceria com a assessoria do Grupo Cherto e
        com a Visagio.
      </S.Text>
      <S.Subtitle>Implantação</S.Subtitle>
      <S.Text>
        O Manual de Implantação da Unidade, juntamente com o trabalho do
        consultor de campo, oferece ao franqueado todas as informações
        necessárias para a condução do processo de montagem da clínica, desde a
        procura do ponto até a inauguração.
      </S.Text>
      <S.Subtitle>Padrão</S.Subtitle>
      <S.Text>
        Cada franqueado tem acesso ao Manual de Padrão Físico da Clínica e nele
        terá acesso a detalhes de construção, equipamentos e identidade visual,
        com riqueza de informações e detalhes para a montagem da unidade. O
        material é fruto de parceria com o Grupo Cherto e a Visagio, consultoria
        em engenharia de gestão a processos.
      </S.Text>
    </S.Container>
  );
};

import React from "react";
import { Link } from "react-scroll";
import * as S from "./styles";
import modelo from "../../../assets/images/orofacial/banner/modelo.png";

export const Banner: React.FC = () => {
  return (
    <>
      <S.Container id="main-banner">
        <S.Group>
          <S.Lside>
            <S.Modelo src={modelo} alt="Modelo" />
          </S.Lside>
          <S.Rside>
            <S.GroupInfo>
              <S.Logo alt="Logo Orofacial" />
              <h2>Marque a sua Avaliação</h2>
              <p>
                Conheça as unidades em que você pode fazer esse procedimento
              </p>
              {/* <S.ActionButton>
                Agendar uma Avaliação <S.ArrowRightIcon />
              </S.ActionButton> */}

              <Link to="areaUnits" smooth={true} offset={50} duration={500}>
                Agendar Avaliação
              </Link>
            </S.GroupInfo>
          </S.Rside>
        </S.Group>
      </S.Container>
    </>
  );
};

import React from "react";

import * as S from "./styles";

export const Pessoal: React.FC = () => {
  return (
    <S.Container>
      <S.Title>
        Gestão <br />
        <span>pessoal</span>
      </S.Title>
      {/* <S.Subtitle>{"R&S"}</S.Subtitle>
      <S.Text>
        É através do recrutamento e seleção que é dado o primeiro passo para
        definir a equipe que irá compor a unidade. O processo supre as
        necessidades de pessoal de acordo com as características e necessidades
        de cada clínica. Utilizamos os principais veículos e ferramentas
        disponíveis no mercado para atrair os candidatos mais aptos e, através
        de um conjunto de práticas e métodos, selecionamos as melhores opções e
        direcionamos os mesmos em função do perfil de cada cargo. Este processo
        é realizado, levando em consideração as datas previstas de inauguração,
        e suportado por um cronograma de atividades, onde o franqueado acompanha
        e é consultado em todas as etapas.
      </S.Text> */}
      <S.Subtitle>Treinamento de colaboradores</S.Subtitle>
      <S.Text>
        Treinamentos da equipe com exposição teórica e prática. Nosso objetivo é
        fazer com que todos os colaboradores compreendam as funções de cada
        membro da equipe, com uma visão ampla e coerente. Neste período de
        treinamento, o novo colaborador é guiado por profissionais altamente
        capacitados nas áreas em que atuam, levando-os a presenciar o cotidiano
        de nossas unidades, os principais desafios e as melhores práticas para
        cada situação. Todo treinamento é acompanhado pela equipe de recursos
        humanos que constantemente fornece informações aos franqueados sobre o
        desempenho de cada membro da equipe.
      </S.Text>
      <S.Subtitle>Treinamento do franqueado</S.Subtitle>
      <S.Text>
        Além de contar com todo o suporte de treinamento dos funcionários, a
        Orthopride oferece também treinamentos específicos para o franqueado,
        desenvolvendo a capacidade de análise, auditoria, supervisão e gestão do
        negócio. Estes treinamentos são realizados de acordo com a sua
        disponibilidade e possibilitam o acompanhamento na prática das
        principais atividades desenvolvidas nas clínicas.
      </S.Text>
    </S.Container>
  );
};

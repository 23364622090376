import React from "react";
import { faqFranchising } from "data/faqFranchising";

import * as S from "./styles";
import { Accordion } from "./Accordion";

export const FAQ: React.FC = () => {
  return (
    <S.Container>
      <S.Content>
        <S.Title>Tem dúvidas sobre a Orthopride</S.Title>
        {faqFranchising.map((faq) => (
          <Accordion key={faq.id} data={faq} />
        ))}
      </S.Content>
    </S.Container>
  );
};

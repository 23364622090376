import styled from "styled-components";
import { colors, fonts } from "styles";

export const Main = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 200px;
  background: ${colors.white};
  align-items: center;
  padding: 100px 0;

  h1 {
    font-family: ${fonts.BrandontextBold};
    color: ${colors.dark};
    font-size: 50px;
    margin-bottom: 30px;
    text-align: center;
    line-height: 1.1em;
    @media screen and (max-width: 540px) {
      font-size: 40px;
    }
  }
`;

export const Frame = styled.iframe`
  margin: 20px auto;
  width: 800px;
  height: 450px;
  max-width: 85%;
`;

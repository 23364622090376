import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

interface GoogleCampaign {
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_content: string;
  utm_term: string;
}

export interface CampaignState {
  data: Partial<GoogleCampaign> | null;
}

export interface SetCampaignAction {
  data: Partial<GoogleCampaign>;
}

const { Types, Creators } = createActions(
  {
    setCampaign: ["data"],
    reset: [],
  },
  { prefix: "CAMPAIGN_" }
);

const INITIAL_STATE: CampaignState = {
  data: null,
};

const setCampaign = (state: CampaignState, action: SetCampaignAction) =>
  update(state, {
    data: { $set: action.data },
  });

const reset = () => INITIAL_STATE;

export const campaign = createReducer(INITIAL_STATE, {
  [Types.SET_CAMPAIGN]: setCampaign,
  [Types.RESET]: reset,
});

export const CampaignTypes = Types;
export const CampaignActions = Creators;

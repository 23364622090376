import styled from "styled-components";
import { RightArrowAlt, LeftArrowAlt } from "@styled-icons/boxicons-regular/";
import { colors, colorScheme } from "styles";

import iconAparelho from "assets/images/treatment-icon-aparelho.png";
import iconRaspagem from "assets/images/treatment-icon-raspagem.png";
import iconClareamento from "assets/images/treatment-icon-clareamento.png";
import iconPrevencao from "assets/images/treatment-icon-prevencao.png";
import iconOrtho from "assets/images/treatment-logo-ortho-whitening.png";
import iconLineo from "assets/images/treatment-logo-lineo-whitening.png";

export const Container = styled.section`
  width: 100%;
  background-color: ${colors.grayLight};
  margin: 0 auto;
  position: relative;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1366px;
  padding: 88px 32px;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;

export const Title = styled.h2`
  width: 100%;
  max-width: 480px;
  font-size: 46px;
  line-height: 46px;
  margin-bottom: 32px;
  text-align: center;
  color: ${colorScheme.secondary};

  span {
    color: ${colorScheme.primary};
  }

  @media screen and (max-width: 414px) {
    font-size: 30px;
    line-height: 30px;
  }
`;

export const Text = styled.article`
  width: 100%;
  max-width: 360px;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: ${colors.blueGray};
  margin-bottom: 32px;

  @media screen and (max-width: 414px) {
    font-size: 16px;
    margin-bottom: 32px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;

  .slick-prev:before,
  .slick-next:before {
    content: "";
    display: none;
  }
`;

export const IconAparelho = styled.img.attrs({ src: iconAparelho })`
  width: 100%;
  max-width: max-content;
`;

export const IconRaspagem = styled.img.attrs({ src: iconRaspagem })`
  width: 100%;
  max-width: max-content;
`;

export const IconClareamento = styled.img.attrs({ src: iconClareamento })`
  width: 100%;
  max-width: max-content;
`;

export const IconPrevencao = styled.img.attrs({ src: iconPrevencao })`
  width: 100%;
  max-width: max-content;
`;

export const IconOrtho = styled.img.attrs({ src: iconOrtho })`
  width: 100%;
  max-width: max-content;
`;

export const IconLineo = styled.img.attrs({ src: iconLineo })`
  width: 100%;
  max-width: max-content;
`;

export const IconNext = styled(RightArrowAlt).attrs({ size: 20 })`
  color: ${colors.dark};
`;

export const IconPrev = styled(LeftArrowAlt).attrs({ size: 20 })`
  color: ${colors.dark};
`;

export const WrapperSlide = styled.div`
  padding: 24px;
`;

export const PrevButton = styled.button.attrs({ type: "button" })`
  width: 48px;
  height: 48px;
  position: absolute;
  background-color: white;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 300ms ease;
  transform-origin: center center;
  z-index: 99 !important;
  left: -48px;

  &:hover {
    background: white !important;
  }

  &:focus {
    background: white !important;
  }

  @media screen and (max-width: 1160px) {
    display: none !important;
  }
`;

export const NextButton = styled(PrevButton)`
  left: auto;
  right: -48px;

  @media screen and (max-width: 1160px) {
    display: none !important;
  }
`;

import React from "react";
import * as S from "./styles";

export const Recognition: React.FC = () => {
  return (
    <>
      <S.Container>
        <S.Title>Reconhecimento</S.Title>
        <S.GroupList>
          <S.ItemList>
            <p>
              • Fomos indicados pelo <span>6° ano consecutivo</span> ao prêmio
              "As melhores empresas para o consumidor", do Reclame Aqui
            </p>
          </S.ItemList>
          <S.ItemList>
            <p>
              • Pelo <span>9º ano consecutivo</span>, ganhamos o prêmio
              Excelência em Franchising da ABF
            </p>
          </S.ItemList>
          <S.ItemList>
            <p>
              • Atingimos a <span>pontuação máxima de 5 estrelas</span> na
              premiação "As melhores Franquias do Brasil", da revista Pequenas
              Empresas & Grandes Negócios.
            </p>
          </S.ItemList>
          <S.ItemList>
            <p>
              • Temos <span>excelente reputação pública</span> no site Reclame
              Aqui, conforme votação dos próprios clientes.
            </p>
          </S.ItemList>
          <S.ItemList>
            <p>
              • Solidez de mercado <span>desde 2009</span>
            </p>
          </S.ItemList>
        </S.GroupList>
        <S.Premiacao alt="Prêmios Recebidos" />
        <S.WhiteBall></S.WhiteBall>
        <S.OrangeBall></S.OrangeBall>
      </S.Container>
    </>
  );
};

import React, { useEffect } from "react";
import * as S from "./styles";
import { Scaffold, Footer } from "components/Shared";
import { Banner } from "components/Orofacial/Banner";
import { Treatments } from "components/Orofacial/Treatments";
import { Indications } from "components/Orofacial/Indications";
import { Units } from "components/Orofacial/Units";
import { UnitsOrofacial } from "../Booking/BookingFindUnitByOrofacial";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";

export const Orofacial: React.FC = () => {
  return (
    <Scaffold>
      <S.Container>
        <Banner />
        <Treatments />
        <Indications />
        <UnitsOrofacial />
        {/* <Units /> */}
      </S.Container>
      <Footer />
    </Scaffold>
  );
};

import React from "react";
import * as S from "./styles";

export const NewBanner: React.FC = () => {
  return (
    <>
      <S.Container>
        <S.BannerDesk alt="Banner" />
        <S.BannerMobile alt="Banner Mobile" />
      </S.Container>
    </>
  );
};

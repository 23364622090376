import styled from "styled-components";
import { motion } from "framer-motion";
import { colors, colorScheme } from "styles";

import caio from "assets/images/caio-game.png";
import caioMobile from "assets/images/caio-game-mobile.png";
import cockpit from "assets/images/game-cockpit.png";
import esport from "assets/images/game-esport.png";
import pcs from "assets/images/game-pcs.png";

export const Container = styled.section`
  display: flex;
  width: 100%;
  background-color: ${colors.grayLight};
  margin: 0 auto;
  position: relative;
  padding: 88px 32px 0 32px;

  @media screen and (max-width: 414px) {
    padding-top: 48px;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:first-child {
    align-items: flex-end;
    padding-right: 72px;
  }
  @media screen and (max-width: 900px) {
    &:first-child {
      display: none;
    }
    max-width: 100%;
  }
`;

export const Title = styled.h2`
  width: 100%;
  max-width: 480px;
  font-size: 46px;
  line-height: 46px;
  margin-bottom: 32px;
  text-align: left;
  color: ${colorScheme.secondary};

  span {
    color: ${colorScheme.primary};
  }

  @media screen and (max-width: 900px) {
    max-width: 100%;
    text-align: center;
  }

  @media screen and (max-width: 414px) {
    font-size: 30px;
    line-height: 30px;
  }
`;

export const Text = styled.article`
  width: 100%;
  max-width: 530px;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
  color: ${colors.blueGray};
  margin-bottom: 80px;

  @media screen and (max-width: 900px) {
    max-width: 100%;
    text-align: center;
    margin-bottom: 48px;
  }

  @media screen and (max-width: 414px) {
    font-size: 16px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const WrapperItems = styled.div`
  display: flex;
  max-width: 530px;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    row-gap: 24px;
    margin-bottom: 16px;
  }
`;

export const WrapperItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: 414px) {
    width: 100%;
  }
`;

export const WrapperCaio = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 45%;
  position: relative;
  display: none;

  @media screen and (max-width: 900px) {
    display: block;
  }
`;

export const Caio = styled(motion.img).attrs({ src: caio })`
  width: 100%;
  max-width: max-content;
  max-height: max-content;
`;

export const CaioMobile = styled(motion.img).attrs({ src: caioMobile })`
  width: 100%;
  max-width: max-content;
  display: none;

  @media screen and (max-width: 900px) {
    display: block;
  }

  @media screen and (max-width: 414px) {
    margin-left: -85px;
    width: 135%;
  }
`;

export const Cockpit = styled.img.attrs({ src: cockpit })`
  width: 100%;
  max-width: max-content;
  margin-right: 16px;
`;

export const Esport = styled.img.attrs({ src: esport })`
  width: 100%;
  max-width: max-content;
  margin-right: 16px;
`;

export const Pcs = styled.img.attrs({ src: pcs })`
  width: 100%;
  max-width: max-content;
  margin-right: 16px;
`;

export const Label = styled.h6`
  font-size: 14px;
  text-transform: uppercase;
  color: ${colors.blueGray};
`;

import React from "react";
import { useHistory } from "react-router-dom";

import * as S from "./styles";

interface BoxTreatmentProps {
  data: {
    image: Object;
    title?: String;
    text: String;
    isNew?: Boolean;
  };
}

export const BoxTreatment: React.FC<BoxTreatmentProps> = ({ data }) => {
  const history = useHistory();
  return (
    <S.Container>
      {data.isNew && <S.New>Novo!</S.New>}
      <S.Image>{data.image}</S.Image>
      {data.title && <S.Title>{data.title}</S.Title>}
      <S.Text>{data.text}</S.Text>
      <S.Button onClick={() => history.push("/agendar-avaliacao")}>
        Agendar uma Avaliação
      </S.Button>
    </S.Container>
  );
};

import styled from "styled-components";
import { motion } from "framer-motion";

import symbolBg from "assets/images/brace-symbol-bg.svg";
import symbolBgDark from "assets/images/brace-symbol-bg-dark.svg";
import logoLineo from "assets/images/logo-lineo-by-orthopride.png";

import autoligados from "assets/images/braces-autoligados.png";
import esteticos from "assets/images/braces-esteticos.png";
import fixos from "assets/images/braces-fixos.png";
import transparentes from "assets/images/braces-invisiveis.png";
import removivel from "assets/images/braces-removivel.png";

export { Button } from "styles/styled-components";

interface ContainerProps {
  position: Number;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  width: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  padding: 0 48px;

  @media screen and (max-width: 990px) {
    flex-direction: column;
  }

  @media screen and (max-width: 620px) {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 32px;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 990px) {
    max-width: 100%;

    &:first-child {
      margin-bottom: 32px;
    }
  }
`;

export const SymbolBg = styled(motion.img).attrs({ src: symbolBg })`
  width: 100%;
  max-width: max-content;
  max-height: max-content;
`;

export const SymbolBgDark = styled(motion.img).attrs({ src: symbolBgDark })`
  width: 100%;
  max-width: max-content;
  max-height: max-content;
`;

export const LogoLineo = styled(motion.img).attrs({ src: logoLineo })`
  width: 100%;
  max-width: max-content;
  max-height: max-content;
  margin-bottom: 16px;
`;

export const Picture = styled(motion.img)`
  width: 100%;
  max-width: max-content;
  position: absolute;
  z-index: 2;

  @media screen and (max-width: 620px) {
    width: 460px;
  }

  @media screen and (max-width: 500px) {
    width: 380px;
  }

  @media screen and (max-width: 414px) {
    width: 300px;
  }

  @media screen and (max-width: 375px) {
    width: 260px;
  }

  @media screen and (max-width: 320px) {
    width: 200px;
  }
`;

export const Autoligados = styled(Picture).attrs({ src: autoligados })``;
export const Esteticos = styled(Picture).attrs({ src: esteticos })``;
export const Fixos = styled(Picture).attrs({ src: fixos })``;
export const Transparentes = styled(Picture).attrs({ src: transparentes })``;
export const Removivel = styled(Picture).attrs({ src: removivel })``;

export const Warning = styled.div`
  width: 100%;
  min-height: 30px;
  margin: 10px 0 20px 0;
  p {
    font-size: 9px;
    @media screen and (max-width: 540px) {
      text-align: center;
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 400px;

  @media screen and (max-width: 990px) {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const Title = styled.h2`
  width: 100%;
  max-width: 480px;
  font-size: 46px;
  line-height: 46px;
  margin-bottom: 24px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colorScheme.secondary};

  span {
    font-family: ${({ theme }) => theme.fonts.BrandontextLight};
  }

  @media screen and (max-width: 990px) {
    max-width: 100%;
    text-align: center;
  }

  @media screen and (max-width: 414px) {
    font-size: 30px;
    line-height: 30px;
  }
`;

export const Text = styled.article`
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.blueGray};
  margin-bottom: 32px;

  @media screen and (max-width: 990px) {
    text-align: center;
  }
`;

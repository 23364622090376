import React from "react";
import * as S from "./styles";
import { Link } from "react-scroll";

import toxina from "../../../assets/images/orofacial/treatments/toxina-botulinica.png";
import acido from "../../../assets/images/orofacial/treatments/acido-hialuronico.png";
import bio from "../../../assets/images/orofacial/treatments/bio-estimulador.png";

export const Treatments: React.FC = () => {
  return (
    <>
      <S.Cointainer>
        <S.GroupText>
          <h2>Tratamentos que trazem mais harmonia para toda a sua face</h2>
          <p>
            Com a qualidade e cuidado que oferecemos nos tratamento
            ortodônticos, agora temos mais essa novidade, harmonização orofacil
            para cuidar da sua face por completo.{" "}
          </p>
        </S.GroupText>
        <S.AreaCards>
          <S.Card bg="#9032c0">
            <S.GroupImage>
              <h2 className="title">
                Toxina
                <br />
                Botulínica
              </h2>
              <img
                className="label-orange"
                src={toxina}
                alt="Toxina Botulínica"
              />
            </S.GroupImage>
            <S.GroupCardText>
              <p>
                A toxina botulínica é muito utilizada em procedimentos estéticos
                e é capaz de amenizar as rugas dinâmicas, aquelas que são
                causadas pela movimentação constante dos músculos faciais e
                responsáveis pelas linhas de expressão facial.
              </p>
              <p>
                Após a aplicação, a toxina atua como um bloqueador no músculo,
                impedindo a contração muscular.  Possui efeito preventivo e sua
                aplicação precoce retarda a formação de rugas estáticas.
              </p>
            </S.GroupCardText>
            <S.GroupCall>
              <S.CallToAction>
                <Link to="areaUnits" smooth={true} offset={50} duration={500}>
                  <S.ChevronR item_color="#F58226" size={18} />
                  &nbsp; Agendar Avaliação
                </Link>
              </S.CallToAction>
            </S.GroupCall>
          </S.Card>
          <S.Card bg="#f09327">
            <S.GroupImage>
              <h2 className="title">
                Preenchimento
                <br />
                com Ácido Hialurônico
              </h2>
              <img
                className="label-purple"
                src={acido}
                alt="Toxina Botulínica"
              />
            </S.GroupImage>
            <S.GroupCardText>
              <p>
                O preenchimento é realizado através da aplicação de ácido
                hialurônico sob a área da pele a ser tratada, dando mais volume
                e reduzindo sua profundidade. O ácido hialurônico além de repor
                volume, possui propriedades hidratantes.
              </p>
              <p>
                A depender da região, utiliza-se diferentes tipos de ácido
                hialurônico, de acordo com sua densidade:
              </p>
              <ul>
                <li>Ácido Hialurônico para Hidratação;</li>
                <li>Ácido Hialurônico de Baixa Densidade;</li>
                <li>Ácido Hialurônico de Média Densidade;</li>
                <li>Ácido Hialurônico de Alta Densidade.</li>
              </ul>
              <p>
                Dessa forma, o procedimento pode ser realizado em diversas áreas
                do rosto, com diferentes objetivos.
              </p>
            </S.GroupCardText>
            <S.GroupCall>
              <S.CallToAction>
                <Link to="areaUnits" smooth={true} offset={50} duration={500}>
                  <S.ChevronR item_color="#9032c0" size={18} />
                  &nbsp;Agendar Avaliação
                </Link>
              </S.CallToAction>
            </S.GroupCall>
          </S.Card>
          <S.Card bg="#9032c0">
            <S.GroupImage>
              <h2 className="title">
                Bioestimulador
                <br />
                de Colágeno
              </h2>
              <img className="label-orange" src={bio} alt="Toxina Botulínica" />
            </S.GroupImage>
            <S.GroupCardText>
              <p>
                O bioestimulador de colágeno induz uma resposta no organismo que
                culmina na produção de colágeno e elastina.
              </p>
              <p>
                O colágeno é uma proteína que promove firmeza na pele, enquanto
                a elastina é responsável pela elasticidade.
              </p>
              <p>
                É possível com a aplicação do bioestimulador restabelecer a
                forma, o volume da face, a elasticidade e a firmeza.
              </p>
            </S.GroupCardText>
            <S.GroupCall>
              <S.CallToAction>
                <Link to="areaUnits" smooth={true} offset={50} duration={500}>
                  <S.ChevronR item_color="#F58226" size={18} />
                  &nbsp; Agendar Avaliação
                </Link>
              </S.CallToAction>
            </S.GroupCall>
          </S.Card>
        </S.AreaCards>
      </S.Cointainer>
    </>
  );
};

import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

import {
  GeolocationUnidadeActions,
  GeolocationUnidadeState,
} from "store/ducks/unidades/geolocation-unidade";

import * as S from "./styles";

interface IMapProps {
  GooglePlaceId: string;
}

const KEY = "AIzaSyDTeEPmp4STYKAsvGgcEHVqBqS9z103BVY";
const containerMapStyle = {
  width: "100%",
  height: "100%",
};

export const Map: React.FC<IMapProps> = ({ GooglePlaceId }) => {
  const dispatch = useDispatch();

  const { data } = useSelector<RootStateOrAny>(
    (state) => state.geolocationUnidade
  ) as GeolocationUnidadeState;

  const getGeolocation = useCallback(() => {
    if (GooglePlaceId) {
      dispatch(
        GeolocationUnidadeActions.request({
          key: KEY,
          place_id: GooglePlaceId,
        })
      );
    }
  }, [GooglePlaceId, dispatch]);

  useEffect(() => {
    getGeolocation();
    return () => {
      dispatch(GeolocationUnidadeActions.reset());
    };
  }, [dispatch, getGeolocation]);
  return (
    data &&
    data.results.length && (
      <S.Container>
        <LoadScript googleMapsApiKey={KEY}>
          <GoogleMap
            mapContainerStyle={containerMapStyle}
            zoom={13}
            center={data.results[0].geometry.location}
          >
            <Marker position={data.results[0].geometry.location} />
          </GoogleMap>
        </LoadScript>
      </S.Container>
    )
  );
};

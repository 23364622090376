import styled, { css } from "styled-components";
import { ChevronRightCircle } from "@styled-icons/boxicons-solid";
import thopSmall from "assets/images/capex-thop-small.png";

export { Button, Loading } from "styles/styled-components";

export const Container = styled.section`
  width: 100%;
  max-width: 1280px;
  display: flex;
  padding: 72px 32px;
  margin: 0 auto;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const Aside = styled.div`
  width: 100%;
  max-width: 480px;
  margin-right: 110px;

  @media screen and (max-width: 900px) {
    margin-right: 0;
    margin-bottom: 48px;
    display: flex;
    flex-direction: column;
  }
`;

export const ThopSmall = styled.img.attrs({ src: thopSmall })`
  width: 100%;
  max-width: max-content;
  position: absolute;
  top: -80px;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 320px;
  min-height: 350px;
  position: relative;
  background: rgb(245, 130, 38);
  background: linear-gradient(
    180deg,
    rgba(245, 130, 38, 1) 35%,
    rgba(242, 111, 57, 1) 100%
  );
  border: 1px #ffa245 solid;
  padding: 24px 24px 40px 24px;
  border-radius: 4px;
  margin-top: 110px;

  @media screen and (max-width: 900px) {
    align-self: center;
  }
`;

export const BoxTitle = styled.h5`
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 32px;
  color: white;
  text-align: center;

  @media screen and (max-width: 900px) {
    text-align: center;
  }
`;

export const FindUnitButton = styled.button.attrs({ type: "button" })`
  width: 100%;
  padding: 24px 16px 0 16px;
  border-top: 1px #8e5d36 solid;
  display: flex;
  align-items: center;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.dark};
`;

export const ChevronIcon = styled(ChevronRightCircle).attrs({ size: 20 })`
  color: white;
  margin-right: 8px;
`;

export const Content = styled.div`
  width: 100%;

  form {
    display: flex;
    flex-direction: column;
    padding-top: 32px;
    margin-top: 32px;
    border-top: 2px #c7d0dd solid;
    button {
      align-self: center;
      min-width: 200px;
    }
  }

  @media screen and (max-width: 900px) {
    max-width: 480px;
  }

  @media screen and (max-width: 414px) {
    form {
      button {
        width: 100%;
      }
    }
  }
`;

export const Title = styled.h2`
  font-size: 46px;
  line-height: 46px;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.colors.black};

  span {
    color: ${({ theme }) => theme.colorScheme.primary};
  }

  @media screen and (max-width: 900px) {
    text-align: center;
  }

  @media screen and (max-width: 414px) {
    font-size: 30px;
    line-height: 30px;
  }
`;

export const Text = styled.article`
  font-size: 16px;
  line-height: 20px;

  p {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 900px) {
    text-align: center;
  }

  @media screen and (max-width: 414px) {
    font-size: 16px;
  }
`;

export const Row = styled.div<{ border?: boolean }>`
  width: 100%;
  display: flex;
  column-gap: 16px;
  ${({ border }) =>
    border &&
    css`
      border-bottom: 2px #c7d0dd solid;
      padding-bottom: 16px;
      margin-bottom: 32px;
    `};

  @media screen and (max-width: 414px) {
    flex-direction: column;
  }
`;

import { v4 as uuidV4 } from "uuid";

export interface ITip {
  id: string;
  number: number;
  title: string;
  text: string;
}

export const tips: Array<ITip> = [
  {
    id: uuidV4(),
    number: 1,
    title: "Consulte seu dentista",
    text: "Antes de iniciar o tratamento, consulte seu dentista para receber orientações de como realizar o tratamento.",
  },
  {
    id: uuidV4(),
    number: 2,
    title: "Aplique o gel",
    text: "Aplique o gel seguindo as orientações do seu dentista.",
  },
  {
    id: uuidV4(),
    number: 3,
    title: "Conecte a moldeira",
    text: "Conecte a moldeira de LED no seu celular e fique com ela pelo tempo indicado pelo seu dentista.",
  },
];

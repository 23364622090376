import styled from "styled-components";
import { colors, fonts } from "styles";

export const Container = styled.div`
  width: 100%;
  background-color: ${colors.dark};
  padding: 100px 10%;

  @media screen and (max-width: 650px) {
    padding: 50px 20px;
  }

  @media screen and (max-width: 414px) {
    padding-bottom: 48px;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 786px;
`;

export const Title = styled.h2`
  font-size: 45px;
  line-height: 1.1em;
  color: ${colors.orange};
  text-align: left;
  margin-bottom: 45px;

  @media screen and (max-width: 414px) {
    font-size: 36px;
    line-height: 36px;
  }
`;

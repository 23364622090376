import React from "react";
import { useHistory } from "react-router-dom";
import * as S from "./styles";

export const Footer: React.FC = () => {
  const history = useHistory();
  return (
    <S.Container>
      <S.Content>
        <S.Row className="main">
          <S.Column>
            <S.Thop />
          </S.Column>
          <S.Column>
            <S.Orthopride />
            <S.Text>
              A Orthopride nasceu para oferecer a você mais qualidade de vida,
              saúde e muitos sorrisos no seu dia a dia! Nosso objetivo é
              propagar alegria para nossos investidores, colaboradores e, claro,
              nossos pacientes. Acreditamos que tudo que é bom começa com um
              sorriso, inclusive grandes negócios.
            </S.Text>
            <S.ContactButtonsWrapper>
              {/* <S.ContactButton onClick={() => history.push("/ouvidoria")}>
                <S.ArrowIcon /> Contato
              </S.ContactButton> */}
              <S.ContactButton
                onClick={() =>
                  window.open("https://orthopride.gupy.io/", "_blank")
                }
              >
                <S.ArrowIcon /> Trabalhe conosco
              </S.ContactButton>
            </S.ContactButtonsWrapper>
            <S.Privacy
              href="/PoliticadePrivacidadeOrthopride.pdf"
              target="_blank"
            >
              Política de privacidade
            </S.Privacy>
          </S.Column>
          <S.Column className="links">
            <S.Title>Acesso rápido:</S.Title>
            <S.Link onClick={() => history.push("/")}>Início</S.Link>
            <S.Link onClick={() => history.push("/encontre-uma-unidade")}>
              Unidades
            </S.Link>

            <S.Link onClick={() => history.push("/tratamentos")}>
              Tratamentos
            </S.Link>
            <S.Link onClick={() => history.push("/harmonizacao-orofacial")}>
              Harmonização Orofacial
            </S.Link>
            {/* <S.Link onClick={() => history.push("/studioorthopride")}>
              Studio Orthopride
            </S.Link> */}

            <S.Link onClick={() => history.push("/empresa")}>
              A Orthopride
            </S.Link>
            <S.Link onClick={() => history.push("/franchising")}>
              Franchising
            </S.Link>
            <S.Link onClick={() => history.push("/agendar-avaliacao")}>
              Agendar uma Avaliação
            </S.Link>
            <S.Link onClick={() => history.push("/duvidas-frequentes")}>
              Dúvidas frequentes
            </S.Link>
            <S.Link onClick={() => history.push("/contato")}>
              Fale com a gente
            </S.Link>
            <S.Link onClick={() => history.push("/ouvidoria")}>
              Ouvidoria
            </S.Link>
          </S.Column>
          <S.Column>
            <S.Title>App Orthopride:</S.Title>
            <S.Text className="app">
              Baixe o aplicativo e acompanhe seus tratamentos, consultas e
              mensalidades em um só lugar!
            </S.Text>
            <S.AppButtonWrapper>
              <S.AppButton
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=br.com.orthopride.paciente",
                    "_blank"
                  )
                }
              >
                <S.Android />
              </S.AppButton>
              <S.AppButton
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/us/app/orthopride/id1469922425",
                    "_blank"
                  )
                }
              >
                <S.Ios />
              </S.AppButton>
            </S.AppButtonWrapper>
          </S.Column>
        </S.Row>
      </S.Content>
      <S.Content>
        <S.Row className="main">
          <S.Column>
            <S.Social>
              <span>Siga-nos:</span>
              <S.SocialButton
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/redeorthopride/",
                    "_blank"
                  )
                }
              >
                <S.IconFacebook />
              </S.SocialButton>
              <S.SocialButton
                onClick={() =>
                  window.open("https://www.instagram.com/orthopride/", "_blank")
                }
              >
                <S.IconInstagram />
              </S.SocialButton>
              <S.SocialButton
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/company/orthopride-franchising/",
                    "_blank"
                  )
                }
              >
                <S.IconLinkedin />
              </S.SocialButton>
            </S.Social>
          </S.Column>
          <S.Column>
            <S.Row className="main">
              {/* <S.TextLegal>
                ORTHOPRIDE ORTODONTIA E ESTÉTICA
                <br />
                RESPONSÁVEL TÉCNICO DR. FABIO FUJIOKA - CRO 23444RJ
              </S.TextLegal> */}
              <S.TextLegal>ORTHOPRIDE ORTODONTIA E ESTÉTICA</S.TextLegal>
              <S.FocoButton
                onClick={() => window.open("https://fococomunicacao.com")}
              >
                <S.Foco />
              </S.FocoButton>
            </S.Row>
          </S.Column>
        </S.Row>
      </S.Content>
    </S.Container>
  );
};

import React from "react";

import * as S from "./styles";

interface BannerProps {
  unitName?: string;
}

type Props = BannerProps;

export const Banner: React.FC<Props> = ({ unitName }) => {
  return (
    <S.Container>
      <S.Content>
        <S.Wrapper>
          <S.SubTitle>Conheça a</S.SubTitle>
          <S.Title>
            Orthopride
            <br />
            <span>{unitName}</span>
          </S.Title>
          <S.Text>Agende já sua avaliação!</S.Text>
        </S.Wrapper>
      </S.Content>
    </S.Container>
  );
};

import styled from "styled-components";
import { colors, colorScheme, fonts } from "styles";
export { Button } from "styles/styled-components";

export const Container = styled.section`
  width: 100%;
  margin: 0 auto;
`;

export const Content = styled.div`
  width: 100%;
  padding: 88px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 590px;
    padding-top: 32px;
    border-top: 1px #c7d0dd solid;

    button {
      margin-top: 32px;
      align-self: center;
    }
  }

  @media screen and (max-width: 414px) {
    padding: 48px 32px;
  }
`;

export const Title = styled.h2`
  width: 100%;
  max-width: 360px;
  font-size: 46px;
  line-height: 46px;
  margin-bottom: 32px;
  text-align: center;
  color: ${colorScheme.secondary};

  span {
    color: ${colorScheme.primary};
  }

  @media screen and (max-width: 414px) {
    font-size: 30px;
    line-height: 30px;
  }
`;

export const Text = styled.article`
  width: 100%;
  max-width: 580px;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: ${colors.blueGray};
  margin-bottom: 80px;

  @media screen and (max-width: 414px) {
    font-size: 16px;
    margin-bottom: 32px;
  }
`;

export const Warning = styled.div`
  font-family: ${fonts.SourceSansProRegular};
  font-size: 14px;
  color: ${colors.red};
`;

import React, { useState, useEffect } from "react";
import * as S from "./styles";

export const Indications: React.FC = () => {
  const [ctrl, setCtrl] = useState<boolean>(true);
  let tColor = "";
  let tBg = "";
  let fColor = "";
  let fBg = "";
  if (ctrl === true) {
    tColor = "#f09327";
    tBg = "#9032c0";
    fColor = "#ECECEC";
    fBg = "#CFCFCF";
  } else {
    fColor = "#f09327";
    fBg = "#9032c0";
    tColor = "#ECECEC";
    tBg = "#CFCFCF";
  }

  useEffect(() => {
    setCtrl(true);
  }, []);

  return (
    <>
      <S.Container>
        <S.Content>
          <S.Title>Indicações das regiões a serem tratadas</S.Title>
          <S.SubTitle>
            Cada tratamento tem sua função e região para ser beneficiada,
            conheça.
          </S.SubTitle>
          <S.GroupControls>
            <S.Control color={tColor} bg={tBg} onClick={() => setCtrl(true)}>
              Toxina Botulínica
            </S.Control>
            <S.Control color={fColor} bg={fBg} onClick={() => setCtrl(false)}>
              Preenchimento
            </S.Control>
          </S.GroupControls>
          <S.AreaImg>
            {ctrl === true ? (
              <S.ToxinaImage alt="Toxina Botulínica" />
            ) : (
              <S.preenchimentoImage alt="Preenchimento" />
            )}
          </S.AreaImg>
          <p>
            Clínico geral atuando em harmonização orofacial. Os produtos e
            serviços podem ser encontrados em unidades Orthopride.
          </p>
        </S.Content>
        <S.OrangeBar>
          <h2>
            Na Orthopride, além da qualidade já conhecida, você tem total
            conforto
          </h2>
          <p id="areaUnits">
            Uma clínica moderna e pronta para atender você de forma completa com
            acompanhamento de especialistas e equipe altamente treinada para que
            você alcance o melhor resultado no seu tratamento.{" "}
          </p>
        </S.OrangeBar>
      </S.Container>
    </>
  );
};

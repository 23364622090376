import { call, put } from "redux-saga/effects";

import { apiGoogle, notify, queryBuilder } from "services";
import { IListRequest } from "interfaces/list-duck";

import { requestErrorHandler } from "utils";
import { GeolocationUnidadeActions } from "store/ducks/unidades";

export function* GeolocationtUnidadesRequest(action: any) {
  try {
    const { query = {} } = action as IListRequest;
    const params = queryBuilder(query);
    const url = `/geocode/json?${params}`;
    const { data } = yield call(apiGoogle.get, url);

    yield put(GeolocationUnidadeActions.success(data));
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(GeolocationUnidadeActions.failure(errorMessage));
  }
}

import styled from "styled-components";
import { motion } from "framer-motion";
import { LocationPin } from "@styled-icons/entypo";
import { colors, fonts, colorScheme } from "styles";

import caio from "assets/images/caio-medina-banner-home.png";
export { Button } from "styles/styled-components";

export const Container = styled.section`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1920px;
  background-color: ${colors.greenLight};
  height: 730px;
  position: relative;

  @media screen and (max-width: 1126px) {
    padding: 56px 32px;
    height: auto;
    background: rgb(255, 153, 43);
    background: linear-gradient(
      180deg,
      rgba(255, 153, 43, 1) 0%,
      rgba(221, 110, 27, 1) 100%
    );
  }
`;

export const Content = styled.div`
  width: 50%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 2;

  &:first-child {
    align-items: flex-end;
    justify-content: flex-end;
  }

  @media screen and (max-width: 1126px) {
    &:first-child {
      display: none;
    }
    width: 100%;
  }
`;

export const BigBall = styled(motion.div)`
  width: 1100px;
  height: 1100px;
  border-radius: 550px;
  background: rgb(255, 153, 43);
  background: linear-gradient(
    0deg,
    rgba(255, 153, 43, 1) 0%,
    rgba(221, 110, 27, 1) 100%
  );
  position: absolute;
  left: -550px;
  top: -225px;
  z-index: 1;

  @media screen and (max-width: 1024px) {
    left: -850px;
  }

  @media screen and (max-width: 1126px) {
    display: none;
  }
`;

export const BlackBall = styled(motion.div)`
  width: 116px;
  height: 116px;
  border-radius: 58px;
  background-color: ${colorScheme.secondary};
  position: absolute;
  top: 5%;
  right: 5%;
  z-index: 1;

  @media screen and (max-width: 1024px) {
    width: 64px;
    height: 64px;
  }

  @media screen and (max-width: 1126px) {
    display: none;
  }
`;

export const Caio = styled.img.attrs({ src: caio })`
  position: absolute;
  width: 100%;
  height: auto;
  z-index: 2;
  left: 20px;

  @media screen and (max-width: 1126px) {
    display: none;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 440px;
`;

export const Title = styled.h1`
  font-size: 69px;
  line-height: 69px;
  font-family: ${fonts.BrandontextBold};
  color: ${colorScheme.primary};
  text-transform: uppercase;
  margin-bottom: 32px;

  span {
    font-family: ${fonts.BrandontextLight};
    color: ${colors.dark};
  }

  @media screen and (max-width: 1126px) {
    text-align: center;
    color: white;
  }

  @media screen and (max-width: 414px) {
    font-size: 40px;
    line-height: 40px;
  }
`;

export const Text = styled.article`
  font-size: 18px;
  color: ${colors.blueGray};
  line-height: 24px;

  @media screen and (max-width: 1126px) {
    text-align: center;
    color: white;
  }

  @media screen and (max-width: 414px) {
    font-size: 16px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 40px 0;

  button:first-child {
    margin-right: 16px;
  }

  @media screen and (max-width: 1126px) {
    align-self: center;
  }

  @media screen and (max-width: 414px) {
    flex-direction: column;

    button:first-child {
      margin-right: 0;
      margin-bottom: 16px;
    }

    button {
      width: 100%;
    }
  }
`;

export const Pin = styled(LocationPin).attrs({ size: 20 })`
  margin-right: 8px;

  @media screen and (max-width: 414px) {
    display: none;
  }
`;

export const Loncation = styled.article`
  display: flex;
  align-items: center;
  font-size: 18px;
  color: ${colors.dark};

  span {
    margin-left: 8px;
    font-family: ${fonts.BrandontextBold};
  }

  @media screen and (max-width: 1126px) {
    color: white;
    align-self: center;

    span {
      color: ${colors.dark};
    }
  }

  @media screen and (max-width: 414px) {
    align-self: center;
    flex-direction: column;
    span {
      margin: 0;
    }
  }
`;

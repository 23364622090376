import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";
import { IState, ISuccessAction, IFailureAction } from "interfaces/list-duck";
import { IUnidade } from "interfaces/unidade";

const { Types, Creators } = createActions(
  {
    request: ["query", "onSuccess"],
    success: ["data", "pagination"],
    failure: ["error"],
    reset: [],
  },
  { prefix: "LIST_UNIDADES_" }
);

export interface ListUnidadesState extends IState {
  data: IUnidade[];
}

interface ISuccessListUnitsAction extends ISuccessAction {
  data: IUnidade[];
}

const INITIAL_STATE: ListUnidadesState = {
  data: [],
  loading: false,
  error: null,
};

const request = (state: ListUnidadesState) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

const success = (state: ListUnidadesState, action: ISuccessListUnitsAction) =>
  update(state, {
    data: { $set: action.data },
    loading: { $set: false },
  });

const failure = (state: ListUnidadesState, action: IFailureAction) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

const reset = () => INITIAL_STATE;

export const listUnidades = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
});

export const ListUnidadesTypes = Types;
export const ListUnidadesActions = Creators;

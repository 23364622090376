import React, { useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import * as S from "./styles";
import { ITreatment } from "data/treatments";

interface Props {
  treatment: ITreatment;
  position: Number;
}

const animate = {
  move: {
    rotate: [0, 5, 0],
    transition: { ease: "easeInOut", duration: 10, repeat: Infinity },
  },
  scaling: {
    scale: [1, 1.025, 1],
    transition: { ease: "easeInOut", duration: 8, repeat: Infinity },
  },
};

export const Item: React.FC<Props> = ({ treatment, position }) => {
  const history = useHistory();
  const containerRef = useRef<HTMLDivElement>(null);
  const Pictures = {
    fixos: <S.Fixos animate="scaling" variants={animate} />,
    autoligados: <S.Autoligados animate="scaling" variants={animate} />,
    esteticos: <S.Esteticos animate="scaling" variants={animate} />,
    transparentes: <S.Transparentes animate="scaling" variants={animate} />,
    removivel: <S.Removivel animate="scaling" variants={animate} />,
  };

  const handleBooking = useCallback(() => {
    if (treatment.type === "transparentes") {
      return history.push("/agendar-avaliacao-tratamento/lineo");
    }
    history.push("/agendar-avaliacao");
  }, [history, treatment]);

  return (
    <S.Container ref={containerRef} position={position}>
      <S.Content>
        {Pictures[treatment.type]}
        <S.SymbolBg animate="move" variants={animate} />
      </S.Content>
      <S.Content>
        <S.Wrapper>
          <S.Title dangerouslySetInnerHTML={{ __html: treatment.title }} />
          <S.Warning>
            <p>*Especialistas em ortodontia</p>
            <p>*Clínico geral com atuação em ortodontia</p>
          </S.Warning>
          {treatment.type === "transparentes" && <S.LogoLineo />}
          <S.Text>{treatment.text}</S.Text>
          <S.Button onClick={handleBooking}>Marcar uma avaliação</S.Button>
        </S.Wrapper>
      </S.Content>
    </S.Container>
  );
};

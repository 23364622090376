import styled, { css } from "styled-components";
import { colors, fonts } from "styles";
import { Tooth } from "@styled-icons/fa-solid/Tooth";
import { Offer } from "@styled-icons/boxicons-solid/Offer";
import { Building } from "@styled-icons/boxicons-solid/Building";
import { Calendar } from "@styled-icons/boxicons-regular/Calendar";
import { Mobile } from "@styled-icons/fa-solid/Mobile";
import { UserVoice } from "@styled-icons/boxicons-regular/UserVoice";
import { Chat } from "@styled-icons/boxicons-solid/Chat";
import { Task } from "@styled-icons/boxicons-regular/Task";
import { ArrowIosDownward } from "@styled-icons/evaicons-solid/ArrowIosDownward";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1920px;
  align-items: center;
  justify-content: center;
  background: ${colors.gray};
  padding-bottom: 150px;
  @media screen and (max-width: 900px) {
    padding-bottom: 50px;
  }
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
  min-height: 300px;
`;
export const TopTitle = styled.h2`
  font-family: ${fonts.BrandontextBold};
  color: ${colors.dark};
  font-size: 46px;
  line-height: 1em;
  margin: 0 auto;
  letter-spacing: -1px;
  &:first-child {
    margin-top: 62px;
  }
  span {
    color: ${colors.orange};
  }
  @media screen and (max-width: 500px) {
    font-size: 26px;
  }
`;

export const GroupLinks = styled.div`
  width: 90%;
  display: grid;
  grid-template-columns: 240px 240px 240px auto;
  column-gap: 30px;
  margin: 60px auto 100px auto;
  padding-bottom: 56px;
  border-bottom: 1px solid ${colors.line};
  @media screen and (max-width: 1200px) {
    width: 70%;
    grid-template-columns: 240px 240px auto;
  }
  @media screen and (max-width: 900px) {
    display: none;
  }
`;
export const ButtonDefaut = styled.a<{ active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: left;
  cursor: pointer;
  align-self: left;
  width: 240px;
  font-family: ${fonts.BrandontextMedium};
  font-size: 18px;
  color: ${colors.dark};
  background: ${colors.white};
  padding: 16px 24px;
  transition: 300ms ease;
  border-radius: 10px;
  border: 1px solid #aab7c8;
  margin: 0 30px 30px 0;

  ${({ active }) =>
    active &&
    css`
      background-color: ${colors.orange};
      color: ${colors.white};
      svg {
        color: white;
      }
    `}

  &:hover {
    transform: scale(0.98);
  }
`;

export const GroupLinksMobile = styled.div`
  display: flex;
  flex-direction: column;
  display: none;
  width: 90%;
  margin-top: 33px;
  margin-bottom: 31px;

  @media screen and (max-width: 900px) {
    display: flex;
  }
`;

export const DivisorMobile = styled.hr`
  display: none;
  width: 90%;
  border: 1px solid ${colors.line};
  margin-bottom: 33px;
  @media screen and (max-width: 900px) {
    display: block;
  }
`;

export const ArrowDown = styled(ArrowIosDownward).attrs({ size: 24 })`
  margin-right: 8px;
  color: ${colors.dark};
`;

export const ToothIcon = styled(Tooth).attrs({ size: 24 })`
  margin-right: 8px;
  color: ${colors.orange};
`;

export const OfferIcon = styled(Offer).attrs({ size: 24 })`
  margin-right: 8px;
  color: ${colors.orange};
`;
export const BuildIcon = styled(Building).attrs({ size: 24 })`
  margin-right: 8px;
  color: ${colors.orange};
`;
export const CalendarIcon = styled(Calendar).attrs({ size: 24 })`
  margin-right: 8px;
  color: ${colors.orange};
`;
export const MobileIcon = styled(Mobile).attrs({ size: 24 })`
  margin-right: 8px;
  color: ${colors.orange};
`;
export const UserVoiceIcon = styled(UserVoice).attrs({ size: 24 })`
  margin-right: 8px;
  color: ${colors.orange};
`;

export const ChatIcon = styled(Chat).attrs({ size: 24 })`
  margin-right: 8px;
  color: ${colors.orange};
`;

export const TaskIcon = styled(Task).attrs({ size: 24 })`
  margin-right: 8px;
  color: ${colors.orange};
`;

export const GroupAccordion = styled.div`
  width: 90%;
`;

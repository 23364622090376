import styled from "styled-components";
import { fonts } from "styles/fonts";
import { colorScheme } from "styles/colors";
export {
  FieldContainer,
  FieldError,
  FieldLabel,
} from "styles/styled-components";

export const Input = styled.input<{ hidden?: boolean }>`
  font-family: ${fonts.SourceSansProRegular};
  font-size: 14px;
  color: ${colorScheme.text};
  background-color: #fff;
  padding: 16px;
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  max-height: 50px;
  width: 100%;

  &:disabled {
    background-color: #f1f1f1;
  }
`;

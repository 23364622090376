import styled, { css } from "styled-components";
import { ChevronRight } from "@styled-icons/boxicons-regular";
import { colors, fonts } from "styles";

export const BackgroundContainer = styled.section`
  width: 100%;
  background-color: #f0f0f0;
  padding: 96px 32px;

  @media screen and (max-width: 414px) {
    padding-top: 48px;
    padding-bottom: 48px;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const Aside = styled.aside`
  width: 100%;
  max-width: 380px;
  position: relative;
  margin-right: 110px;

  @media screen and (max-width: 820px) {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 48px;
  }
`;

export const AsideTitle = styled.h6`
  font-size: 18px;
  line-height: 18px;
  font-family: ${fonts.BrandontextMedium};
  color: ${colors.dark};
  margin-bottom: 24px;

  @media screen and (max-width: 414px) {
    text-align: center;
  }
`;

export const Menu = styled.nav`
  background-color: white;
  border-radius: 6px;
  border: 1px #c5c8ce solid;
  padding: 32px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
  position: sticky;
  top: 100px;
`;

export const Option = styled.button.attrs({ type: "button" })<{
  active?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  width: 100%;
  font-size: 18px;
  border-bottom: 1px #c5c8ce solid;
  font-family: ${fonts.BrandontextRegular};

  ${({ active }) =>
    active &&
    css`
      font-family: ${fonts.BrandontextBold};
      svg {
        display: block;
      }
    `}

  &:hover {
    svg {
      display: block;
    }
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }

  @media screen and (max-width: 414px) {
    font-size: 16px;
  }
`;

export const Arrow = styled(ChevronRight).attrs({ size: 24 })`
  display: none;
  color: ${colors.dark};
`;

export const Content = styled.div`
  width: 100%;
`;

import styled from "styled-components";
import { MenuAlt3 } from "@styled-icons/heroicons-outline";
import { Close } from "@styled-icons/evaicons-solid";
import { ButtonMini } from "styles/styled-components";
import { colors, fonts } from "styles";
import { ReactComponent as logo } from "assets/images/back-logo.svg";

export { Button } from "styles/styled-components";

export const WebContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 0px;
  @media screen and (max-width: 1100px) {
    display: none;
  }
`;
interface OptionProps {
  spotlight?: boolean;
}
export const Option = styled.button.attrs({ type: "button" })<OptionProps>`
  font-family: ${({ theme }) => theme.fonts.BrandontextBold};
  font-size: 12px;
  text-transform: uppercase;
  color: ${({ theme, spotlight }) =>
    spotlight ? theme.colorScheme.primary : theme.colors.dark};
  padding: 0 16px;
  transition: 300ms ease;

  max-width: 150px;

  &:hover {
    color: ${({ theme }) => theme.colorScheme.primary};
  }
`;

export const BookButton = styled(ButtonMini)`
  margin-left: 16px;
`;

export const MobileButton = styled.button.attrs({ type: "button" })`
  display: none;

  @media screen and (max-width: 1100px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const MenuIcon = styled(MenuAlt3).attrs({ size: 24 })`
  color: ${colors.dark};
`;

export const Overlay = styled.div<{ open: Boolean }>`
  position: fixed;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: ${({ open }) => (open ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  background: rgb(245, 130, 38);
  background: linear-gradient(
    135deg,
    rgba(245, 130, 38, 1) 0%,
    rgba(242, 111, 57, 1) 100%
  );
  z-index: 1000;
  top: 0;
  left: 0;
  overflow: auto;
`;

export const CloseIcon = styled(Close).attrs({ size: 32 })`
  color: white;
  position: absolute;
  top: 32px;
  right: 32px;
`;

export const CloseButton = styled.button.attrs({ type: "button" })`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled(logo)`
  margin-bottom: 24px;
`;

export const WrapperMobileOptions = styled.div`
  border-top: 1px #ffffff80 solid;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 80%;
`;

export const MobileOption = styled.button.attrs({ type: "button" })`
  font-family: ${fonts.BrandontextBold};
  font-size: 16px;
  text-transform: uppercase;
  color: white;
  padding: 16px 0;
  transition: 300ms ease;

  &:hover {
    color: ${colors.dark};
  }
`;

export const WrapperButtons = styled.div`
  display: flex;
  flex-direction: column;

  button:first-child {
    margin-bottom: 24px;
  }
`;

import { v4 as uuidV4 } from "uuid";

export interface IFaq {
  id: string;
  question: string;
  response: string;
}

export const faqs: Array<IFaq> = [
  {
    id: uuidV4(),
    question: "Aparelho ortodôntico mancha os dentes?",
    response:
      "Não. O que pode ocorrer é o acúmulo da placa bacteriana ao redor dos bráquetes por falta de higiene. Como a placa concentra restos de alimentos e microrganismos vivos, pode haver uma deterioração da superfície do esmalte, o que provoca manchas brancas ou marrons e, posteriormente, cáries. Por isso, é muito importante manter a higiene bucal em dia.",
  },
  {
    id: uuidV4(),
    question: "Posso abandonar o tratamento?",
    response:
      "Não abandone o tratamento sem retirar o aparelho em uma de nossas unidades. Caso contrário, você pode causar danos futuros. O abandono do tratamento sem consulta prévia e autorização de nossa equipe de dentistas exime a Orthopride e seus colaboradores de quaisquer responsabilidades do que possa vir a acontecer com o paciente.",
  },
  {
    id: uuidV4(),
    question: "Os retornos podem ser agendados em prazo menor que 21 dias?",
    response:
      "Esse é o prazo mínimo que o movimento ortodôntico leva para fazer efeito, mas esse tempo pode ser reduzido caso surjam necessidades especiais, como: quebra de aparelho, dor ou quando o profissional julgar necessário.",
  },
  {
    id: uuidV4(),
    question: "Por que às vezes só troco os elásticos?",
    response:
      "A troca de elásticos ativa o aparelho, dando continuidade ao tratamento. As “borrachinhas” tendem a se desgastar, perdendo sua força e eficácia",
  },
  {
    id: uuidV4(),
    question: "O que fazer em caso de bráquetes ou bandas descoladas?",
    response:
      "Ambos devem ser recolocados o mais rápido possível, para isso, você deve ir a uma de nossas unidades. As bandas são extremamente importantes, pois são individualizadas para cada dente, o que facilita a readaptação. Caso o paciente não traga a banda que soltou, o seu ortodontista terá que providenciar uma nova.",
  },
  {
    id: uuidV4(),
    question:
      "Qual o material utilizado na fabricação dos aparelhos ortodônticos?",
    response:
      "Em geral, os aparelhos são feitos com liga metálica de aço, níquel e cromo. Os estéticos de safira são confeccionados com cerâmica monocristalina, responsável pelo aspecto transparente e cristalino. Já os aparelhos cerâmicos são feitos a partir de cerâmica policristalina, que apresenta mais resistência.",
  },
  {
    id: uuidV4(),
    question: "A Orthopride trabalha com aparelhos transparentes?",
    response: "Sim, esses também são conhecidos como “estéticos”.",
  },
  {
    id: uuidV4(),
    question: "Todos os ortodontistas são formados?",
    response:
      "Todos os nossos profissionais têm pós-graduação e/ou especialização na área de ortodontia, ou seja, cursos específicos na área após o término de suas faculdades.",
  },
  {
    id: uuidV4(),
    question:
      "O que devo fazer quando o meu ortodontista não puder me atender ou fazer a manutenção?",
    response:
      "Isso provavelmente não ocorrerá, mas, caso o seu ortodontista não possa atendê-lo, outro profissional estará à disposição para dar continuidade ao tratamento. O paciente nunca ficará sem fazer a manutenção.",
  },
  {
    id: uuidV4(),
    question: "Dentistas de outros locais podem fazer minhas manutenções?",
    response:
      "Não, pois eles não sabem qual foi o plano de tratamento que o ortodontista da Orthopride propôs. Somente os profissionais das nossas clínicas estão aptos a dar continuidade ao seu tratamento. Só eles sabem qual técnica, material e procedimento são adequados para cada um de seus pacientes.",
  },
  {
    id: uuidV4(),
    question: "De quanto em quanto tempo posso fazer clareamento dentário?",
    response:
      "A durabilidade do resultado do clareamento vai depender dos cuidados e hábitos do paciente. Quanto mais alimentos com corantes o cliente ingerir, mais rapidamente os dentes escurecem. Para realizar um novo clareamento, é imprescindível a avaliação com o especialista.",
  },
];

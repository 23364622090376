import React from "react";

import * as S from "./styles";

export const ThanksBanner: React.FC = () => {
  const animate = {
    scaling: {
      scale: [1, 1.1, 1],
      transition: { ease: "easeInOut", duration: 8, repeat: Infinity },
    },
  };
  return (
    <S.Container>
      <S.Symbol animate="scaling" variants={animate} />
      <S.Wrapper>
        <S.Thop />
        <S.Title>
          <span>Estamos te</span>
          <br />
          Esperando
        </S.Title>
      </S.Wrapper>
    </S.Container>
  );
};

import styled from "styled-components";
import { MapMarkerAlt } from "@styled-icons/fa-solid";
import { colors } from "styles";

export const Container = styled.section`
  width: 100%;
  height: 520px;
`;

export const Pin = styled(MapMarkerAlt).attrs({ size: 32 })`
  color: ${colors.darkOrange};
`;

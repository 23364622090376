import styled from "styled-components";
import { colors } from "styles";
import thopSmall from "assets/images/capex-thop-small.png";
import thopBig from "assets/images/capex-thop-big.png";
import thopMedium from "assets/images/capex-thop-medium.png";

export const Container = styled.section`
  width: 100%;
  padding: 72px 32px;
  background-color: ${colors.dark};

  @media screen and (max-width: 414px) {
    padding-top: 48px;
    padding-bottom: 48px;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 780px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h1`
  font-size: 52px;
  line-height: 52px;
  margin-bottom: 32px;
  color: white;
  text-align: center;

  @media screen and (max-width: 414px) {
    font-size: 30px;
    line-height: 30px;
  }
`;

export const Text = styled.article`
  width: 100%;
  max-width: 580px;
  font-size: 16px;
  line-height: 24px;
  color: white;
  text-align: center;
  margin-bottom: 32px;
`;

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 24px;

  .slick-prev:before,
  .slick-next:before {
    content: "";
    display: none;
  }

  @media screen and (max-width: 414px) {
    margin-top: 16px;
  }
`;

export const Item = styled.div`
  width: 100% !important;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  padding-top: 88px;
`;

export const Capex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 240px;
  min-height: 320px;
  position: relative;
  background: rgb(245, 130, 38);
  background: linear-gradient(
    180deg,
    rgba(245, 130, 38, 1) 35%,
    rgba(242, 111, 57, 1) 100%
  );
  border: 1px #ffa245 solid;
  padding: 24px 24px 48px 24px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  margin-bottom: 16px;
`;

export const CapexTitle = styled.h5`
  font-size: 20px;
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 16px;
  color: white;
  text-align: center;
`;

export const CapexSubTitle = styled.p`
  font-size: 12px;
  color: #fff;
  text-align: center;
`;

export const CapexPrice = styled.h6`
  font-size: 18px;
  line-height: 18px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: ${colors.dark};
  text-align: center;
`;

export const ThopSmall = styled.img.attrs({ src: thopSmall })`
  width: 100%;
  max-width: max-content;
  position: absolute;
  top: -40px;
`;

export const ThopMedium = styled.img.attrs({ src: thopMedium })`
  width: 100%;
  max-width: max-content;
  position: absolute;
  top: -70px;
`;

export const ThopBig = styled.img.attrs({ src: thopBig })`
  width: 100%;
  max-width: max-content;
  position: absolute;
  top: -90px;
`;

export const Button = styled.button.attrs({ type: "button" })`
  width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  border: 1px #f58226 solid;
  border-radius: 4px;
  color: white;
  font-size: 14px;
  text-transform: uppercase;
  background-color: transparent;
  transition: 300ms ease;

  &:hover {
    background-color: #f58226;
  }

  &:last-child {
    margin-top: 8px;
  }
`;

import styled from "styled-components";
import { ChevronRightCircle } from "@styled-icons/boxicons-solid";
import { ArrowForwardOutline } from "@styled-icons/evaicons-outline";
import { ArrowLeft2 } from "@styled-icons/icomoon";
import { colors, colorScheme, fonts } from "styles";
import { Button } from "styles/styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
  background: #a429da;
  &.center {
    margin: 0 auto;
  }
`;
export const Title = styled.h1`
  font-family: ${fonts.BrandontextBold};
  margin-bottom: 24px;
  text-align: center;
  width: 100%;
  max-width: 450px;
  font-size: 46px;
  line-height: 46px;
  color: #fbe995;
  text-transform: uppercase;
  letter-spacing: -1px;

  @media screen and (max-width: 414px) {
    font-size: 30px;
    line-height: 30px;
  }
`;

export const Text = styled.article`
  font-size: 16px;
  color: #fff;
  line-height: 20px;
  text-align: center;
  padding-bottom: 40px;
  width: 100%;
  max-width: 470px;
  span {
    color: ${colorScheme.primary};
    font-family: ${fonts.SourceSansProBold};
  }
  &.center {
    margin-bottom: 20px;
  }
`;

export const SubmitButton = styled(Button).attrs({ type: "submit" })`
  margin: 30px 0;
  align-self: center;
  background: #ffaa29;
`;

export const ArrowRightIcon = styled(ArrowForwardOutline).attrs({ size: 20 })`
  color: white;
  margin-left: 24px;
`;

export const BackgroundIcon = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f58226;
  margin-right: 16px;
  transition: 300ms ease;
`;

export const BackButton = styled.button.attrs({ type: "button" })`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-bottom: 48px;
  text-transform: uppercase;
  font-size: 14px;
  font-family: ${fonts.BrandontextBold};

  :hover {
    ${BackgroundIcon} {
      transform: translateX(-4px);
    }
  }
`;

export const BackIcon = styled(ArrowLeft2).attrs({ size: 20 })`
  color: white;
`;

export const UnitsList = styled.div`
  margin: 32px 0;
`;

export const Warning = styled.div`
  font-family: ${fonts.SourceSansProRegular};
  font-size: 14px;
  color: ${colors.red};
`;

export const GroupScheduleMid = styled.div`
  width: 100%;
  height: auto;
  padding: 50px 0;
  background: rgb(250, 174, 50);
  background: linear-gradient(
    130deg,
    rgba(250, 174, 50, 1) 0%,
    rgba(244, 121, 88, 1) 45%,
    rgba(204, 131, 69, 1) 100%
  );
`;

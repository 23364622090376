import React from "react";
import * as S from "./styles";

export const Numbers: React.FC = () => {
  return (
    <>
      <S.Container>
        <S.BlackCircle></S.BlackCircle>
        <S.GroupInfo>
          <h2>A Orthopride em números</h2>
          <S.GroupCard>
            <S.Card>
              <h2>15</h2>
              <p>Anos em operação</p>
            </S.Card>
            <S.Card>
              <h2>ZERO</h2>
              <p>casos de falência</p>
            </S.Card>
            <S.Card>
              <h2>90%</h2>
              <p>dos franqueados com mais de uma unidade</p>
            </S.Card>
            <S.Card>
              <h2>25%</h2>
              <p>a 30% de lucratividade</p>
            </S.Card>
            <S.Card>
              <h2>+ 200</h2>
              <p>clinicas no brasil</p>
            </S.Card>
            <S.Card>
              <h2>1.5mi</h2>
              <p>Faturamento Anual Médio</p>
            </S.Card>
          </S.GroupCard>
        </S.GroupInfo>
      </S.Container>
    </>
  );
};

import styled, { css } from "styled-components";
import { ReactComponent as triangle } from "assets/images/triangle.svg";
import { colorScheme, colors, fonts } from "styles";

export const Container = styled.section`
  width: 100%;
  background-color: white;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h2`
  width: 100%;
  font-size: 20px;
  text-align: center;
  color: ${colors.dark};

  @media screen and (max-width: 620px) {
    display: none;
  }
`;

export const SubTitle = styled.h3`
  width: 100%;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: ${colors.dark};
  margin-bottom: 32px;

  @media screen and (max-width: 620px) {
    display: none;
  }
`;

export const Wrapper = styled.div`
  display: flex;
`;

export const ButtonType = styled.button.attrs({ type: "button" })<{
  active: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px #aab7c8 solid;
  border-top: 1px #aab7c8 solid;
  border-bottom: 1px #aab7c8 solid;
  padding: 16px 24px;
  text-transform: uppercase;
  font-size: 14px;
  font-family: ${fonts.SourceSansProRegular};
  color: ${colorScheme.text};
  transition: 300ms ease;
  position: relative;

  svg {
    display: none;
  }

  ${({ theme, active }) =>
    active &&
    css`
      color: white;
      font-family: ${fonts.SourceSansProBold};
      background-color: ${colors.dark};

      svg {
        display: block;
      }
    `}

  &:hover {
    color: white;
    background-color: ${colors.dark};
  }

  &:first-child {
    border-radius: 8px 0 0 8px;
  }

  &:last-child {
    border-radius: 0 8px 8px 0;
    border-right: 1px #aab7c8 solid;
  }

  sup {
    align-self: flex-start;
    font-size: smaller;
    margin-left: 8px;
    color: ${colorScheme.primary};
    font-family: ${fonts.SourceSansProBold};
    text-transform: initial;
  }

  @media screen and (max-width: 620px) {
    display: none;
  }
`;

export const Triangle = styled(triangle)`
  position: absolute;
  bottom: -10px;
  fill: ${colors.dark};
  transition: 300ms ease;
`;

export const TreatmentsWrapper = styled.div`
  width: 100%;
  max-width: 1000px;
  height: 530px;
  margin: 64px auto 0 auto;
  position: relative;
  overflow-x: hidden;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  display: flex;

  @media screen and (max-width: 990px) {
    height: auto;
  }

  @media screen and (max-width: 620px) {
    display: none;
  }
`;

export const TreatmentsMobile = styled.div`
  width: 100%;
  max-width: 1000px;
  height: auto;
  margin: 64px auto 0 auto;
  display: none;

  .slick-prev:before,
  .slick-next:before {
    content: "";
    display: none;
  }

  @media screen and (max-width: 620px) {
    display: block;
  }
`;

export const DragText = styled.span`
  display: none;

  @media screen and (max-width: 620px) {
    display: block;
    text-align: center;
    margin-top: 32px;
    color: ${colors.blueGray};
  }
`;

import styled from "styled-components";
import { colors, fonts } from "styles";

import Caio from "assets/images/franchising2024/caio-jumping.png";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 1920px;
  height: auto;
  background: ${colors.greyMedium};
  padding: 50px 150px;

  @media screen and (max-width: 1720px) {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 50px;
  }
  @media screen and (max-width: 900px) {
    padding: 50px 30px;
  }
  @media screen and (max-width: 540px) {
    padding: 100px 30px 10px 30px;
  }
`;

export const GroupMainText = styled.div`
  position: relative;
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 0px;
  margin-left: 100px;

  h2 {
    font-family: ${fonts.BrandontextBlack};
    letter-spacing: -1px;
    margin-bottom: -50px;
    margin-top: -80px;
    font-size: 250px;
    color: ${colors.orange};

    @media screen and (max-width: 600px) {
      font-size: 200px;
      letter-spacing: -1px;
      margin-bottom: -30px;
    }
    @media screen and (max-width: 540px) {
      font-size: 150px;
      letter-spacing: -1px;
    }
  }
  h3 {
    font-family: ${fonts.BrandontextBold};
    color: ${colors.black};
    font-size: 50px;
    text-transform: uppercase;
    line-height: 1.1em;
    margin-bottom: 80px;
    span {
      color: ${colors.orange};
    }
    @media screen and (max-width: 1720px) {
      text-align: center;
    }
  }
  @media screen and (max-width: 1720px) {
    max-width: 700px;
    align-items: center;
    margin: 0 auto;
  }
  @media screen and (max-width: 1200px) {
    margin-left: 25%;
  }
  @media screen and (max-width: 1190px) {
    margin-left: 10%;
  }
  @media screen and (max-width: 1040px) {
    margin-left: 10%;
  }
  @media screen and (max-width: 1000px) {
    margin-left: 0 auto;
    max-width: 100%;
    margin: 0;
  }
`;

export const CaioJumping = styled.img.attrs({ src: Caio })`
  position: absolute;
  top: -115px;
  left: 250px;
  width: 100%;
  max-width: 450px;
  height: auto;
  z-index: 1;
  @media screen and (max-width: 1720px) {
    top: -100px;
    margin-left: 25%;
  }
  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

export const GroupInfo = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  max-width: 710px;
  column-gap: 20px;
  z-index: 2;

  @media screen and (max-width: 1300px) {
    max-width: 100%;
  }

  @media screen and (max-width: 900px) {
    margin: 0 auto;
    padding: 0;
  }
  @media screen and (max-width: 860px) {
    justify-content: center;
  }
  @media screen and (max-width: 760px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Card = styled.div`
  display: flex;
  width: 100%;
  max-width: 340px;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 20px;
  @media screen and (max-width: 760px) {
    &.margin {
      margin-left: -40px;
    }
  }

  h3 {
    font-family: ${fonts.BrandontextBlack};
    color: ${colors.orange};
    font-size: 40px;
  }
  div {
    display: flex;
    flex-direction: column;
    width: 100%;

    height: auto;
    p {
      font-family: ${fonts.BrandontextMedium};
      color: ${colors.black};
      font-size: 19px;
      line-height: 1.1em;
      margin-top: 5px;
    }
    @media screen and (max-width: 900px) {
      text-align: left;
    }
  }
  @media screen and (max-width: 900px) {
    margin: 0;
  }
  @media screen and (max-width: 600px) {
    margin-bottom: 30px;
  }
  @media screen and (max-width: 540px) {
    margin-left: 0;
  }
`;

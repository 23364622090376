import styled from "styled-components";
import { PlayCircle } from "@styled-icons/bootstrap/PlayCircle";

interface VideoPlayProps {
  control: boolean;
}

export const Container = styled.section`
  width: 100%;
  margin: 0 auto;
  position: relative;
  background: rgb(245, 130, 38);
  background: linear-gradient(
    135deg,
    rgba(245, 130, 38, 1) 0%,
    rgba(242, 111, 57, 1) 100%
  );
`;

export const Background = styled.div`
  width: 100%;
  height: 440px;
  background-color: white;
  position: absolute;
  z-index: 1;

  @media screen and (max-width: 414px) {
    display: none;
  }
`;

export const BackgroundMobile = styled.div`
  width: 100%;

  @media screen and (max-width: 414px) {
    background-color: white;
  }
`;

export const Content = styled.div`
  width: 100%;
  padding: 72px 32px 0 32px;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h2`
  width: 100%;
  max-width: 560px;
  font-size: 46px;
  line-height: 46px;
  margin-bottom: 40px;
  text-align: center;
  color: ${({ theme }) => theme.colors.dark};

  span {
    font-family: ${({ theme }) => theme.fonts.BrandontextLight};

    &.light {
      font-family: ${({ theme }) => theme.fonts.BrandontextBold};
      color: white;
    }
  }

  @media screen and (max-width: 414px) {
    font-size: 30px;
    line-height: 30px;
  }
`;

export const VideoPlay = styled(PlayCircle)<VideoPlayProps>`
  position: absolute;
  display: ${(props) => (props.control ? "none" : "flex")};
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  cursor: pointer;
`;

export const Text = styled.article`
  width: 100%;
  max-width: 570px;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: white;
  margin-bottom: 80px;

  @media screen and (max-width: 414px) {
    font-size: 16px;
    margin-bottom: 32px;
  }
`;

export const Video = styled.div`
  position: relative;
  width: 100%;
  max-width: 786px;
  margin: 0 auto;
  position: relative;
  z-index: 2;

  .player-wrapper {
    position: relative;
    padding-top: 56.25%;
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const UnitsPredicted = styled.div`
  display: flex;
  width: 100%;
  max-width: 560px;
  padding: 32px 0;
  border-top: 1px #ffffff65 solid;
  margin-bottom: 40px;

  @media screen and (max-width: 414px) {
    flex-direction: column;
  }
`;

export const WrapperItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0 8px;
`;

export const UnitNumber = styled.h5`
  font-family: ${({ theme }) => theme.fonts.BrandontextBlack};
  font-size: 54px;
  line-height: 54px;
  color: ${({ theme }) => theme.colorScheme.secondary};
  margin-right: 8px;

  @media screen and (max-width: 414px) {
    min-width: 100px;
  }
`;

export const Label = styled.h6`
  font-size: 14px;
  line-height: 14px;
  color: white;
  text-transform: uppercase;
`;

import { v4 as uuidV4 } from "uuid";

export type TreatmentTypes =
  | "fixos"
  | "autoligados"
  | "esteticos"
  | "transparentes"
  | "removivel";

export interface ITreatment {
  id: string;
  title: string;
  text: string;
  type: TreatmentTypes;
}

export const treatments: Array<ITreatment> = [
  {
    id: uuidV4(),
    title: "Aparelhos <span>fixos</span>",
    text:
      "Sendo a escolha mais comum, a força das suas peças metálicas promove a movimentação dos dentes para os locais corretos, alinhando-os. Sua composição básica é formada pelas bandas/tubos, bráquetes, arcos e elásticos. Visitas mensais ao ortodontista para um acompanhamento é indispensável.",
    type: "fixos",
  },
  {
    id: uuidV4(),
    title: "Aparelhos <span>removíveis</span>",
    text:
      "São usado para corrigir segmentos inteiros de dentes através dos movimentos ortopédicos. Existem inúmeros modelos de aparelhos removíveis e geralmente são indicados para quem ainda se encontra em fase de crescimento ósseo. São formados por resina acrílica, fios de aço e dispositivos de aço inox.",
    type: "removivel",
  },
  {
    id: uuidV4(),
    title: "Aparelhos <span>estéticos</span>",
    text:
      "Opção mais discreta de tratamento, com peças da cor dos dentes ou translúcidas. Sua eficácia é igual à do aparelho fixo, porém tem o diferencial de quase não ser notado sobre os dentes. Pode ser encontrado nos materiais: safira, com seu aspecto translúcido; e porcelana, com coloração opaca.",
    type: "esteticos",
  },
  {
    id: uuidV4(),
    title: "Aparelhos <span>autoligados</span>",
    text:
      "A principal diferença do aparelho autoligado está nos bráquetes. Eles possuem uma “porta” que prende o fio ortodôntico, dispensando as borrachinhas, evitando manchas, diminuindo o acúmulo de bactérias e tornando o tratamento mais rápido. O uso do fio termoativado (que trabalha conforme a variação de temperatura da boca) intensifica o seu resultado.",
    type: "autoligados",
  },
  {
    id: uuidV4(),
    title: "Aparelhos <span>transparentes</span>",
    text:
      "O Lineo é o novo alinhador transparente da Orthopride, um produto exclusivo. A tecnologia usada na fabricação dos alinhadores é um avanço para o tratamento ortodôntico, principalmente para pacientes que preferem um aparelho mais discreto e moderno.",
    type: "transparentes",
  },
];

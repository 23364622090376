import styled from "styled-components";
import { ReactComponent as logo } from "assets/images/company-logo.svg";

interface GroupFormProps {
  topPosition: string | null;
}

export const GroupForm = styled.div<GroupFormProps>`
  position: fixed;
  top: ${(props) => props.topPosition};
  right: 15%;
  width: 100%;
  max-width: 500px;
  background-color: rgba(85, 85, 85, 0.8);
  height: auto;
  border-radius: 10px;
  padding: 30px;
  z-index: 7;
  transition: 0.8s;
  @media screen and (max-width: 2215px) {
    right: 13%;
  }
  @media screen and (max-width: 2125px) {
    right: 10%;
  }
  @media screen and (max-width: 2020px) {
    right: 9%;
  }
  @media screen and (max-width: 1950px) {
    right: 7.5%;
  }
  @media screen and (max-width: 1720px) {
    display: none;
  }

  p {
    font-family: ${({ theme }) => theme.fonts.BrandontextLight};
    color: ${({ theme }) => theme.colors.white};
    font-size: 26px;
    line-height: 1.2em;
    text-transform: uppercase;
    &.orange {
      font-family: ${({ theme }) => theme.fonts.BrandontextBold};
      color: ${({ theme }) => theme.colors.orange};
      margin-bottom: 20px;
    }
    @media screen and (max-width: 540px) {
      font-size: 23px;
    }
  }
  @media screen and (max-width: 1000px) {
    max-width: 80%;
  }
  @media screen and (max-width: 700px) {
    max-width: 90%;
  }
  @media screen and (max-width: 540px) {
    max-width: 100%;
  }
`;

export const MyForm = styled.form`
  input.field {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
  }
  select {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
  }
`;

export const SubmitButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 150px;
  padding: 15px;
  font-family: ${({ theme }) => theme.fonts.BrandontextBold};
  color: ${({ theme }) => theme.colors.white};
  font-size: large;
  background: ${({ theme }) => theme.colors.orange};
  border-radius: 5px;
  transition: 0.5s;
  &:hover {
    background: #cc821f;
  }
`;

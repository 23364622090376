import styled from "styled-components";

import popup from "assets/images/arte-popup.jpg";
export const Popup = styled.div`
  position: relative;
  width: 100%;
  max-width: 600px;
`;

export const PopupContent = styled.img.attrs({ src: popup })`
  object-fit: contain;
  width: 100%;
  max-width: 600px;
  height: auto;
  cursor: pointer;
`;

//export const Close = styled.button.attrs({ type: "button" })`
export const Close = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: xx-large;
  font-weight: bolder;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 16px;
  right: 16px;
  color: #fff;
  background-color: transparent;
  cursor: pointer;
  z-index: 1001;
`;

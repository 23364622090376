import React from "react";

import * as S from "./styles";
import { Scaffold, Footer } from "components/Shared";
import {
  Banner,
  Braces,
  BracesTips,
  CleanTeeth,
  FAQ,
  Clareamento,
  Raspagem,
  Whitening,
  Kits,
} from "components/Treatments";

export const Treatments: React.FC = () => {
  return (
    <Scaffold>
      <Banner />
      <S.Container>
        <S.Content>
          <S.Title>
            Aparelhos que se encaixam no seu <span>estilo</span>.
          </S.Title>
          <S.Text>
            Temos aparelhos para todos os gostos: colorido para quem quer
            "causar", estético para quem quer simplicidade e transparente para
            quem prefere um tratamento discreto e moderno!
          </S.Text>
          <Braces />
        </S.Content>
        <BracesTips />
        <CleanTeeth />
        <Clareamento />
        <Raspagem />
        <Whitening />
        <Kits />
        <FAQ />
      </S.Container>
      <Footer />
    </Scaffold>
  );
};

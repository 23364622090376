export const colors = {
  white: "#fff",
  grayLight: "#EFEFEF",
  greyMedium: "#F5F5F5",
  gray: "#eaeaea",
  black: "#2B2B2B",
  dark: "#353941",
  mediumDark: "#222427",
  darkRgba15: "rgba(41,45,65, 0.15)",
  darkRgba25: "rgba(41,45,65, 0.25)",
  darkRgba8: "rgba(41,45,65, 0.08)",
  greenLight: "#D9DFDF",
  darkBg: "#242424",
  green: "#3BBCA3",
  line: "#D8DAE8",
  blueGray: "#5A697E",
  blue: "#14B6D3",
  red: "#ec1751",
  darkOrange: "#EA4335",
  orange: "#F58226",
};

export const colorScheme = {
  primary: colors.orange,
  secondary: colors.dark,
  warning: colors.orange,
  text: colors.blueGray,
};

import styled from "styled-components";
import { colorScheme, fonts } from "styles";
import bg from "assets/images/banner-ouvidoria.png";

export const Container = styled.section`
  width: 100%;
  height: 480px;
  margin: 0 auto;
  background: #000 url(${bg}) no-repeat center center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 96px 32px;

  @media screen and (max-width: 500px) {
    height: auto;
    background-size: cover;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h1`
  font-size: 72px;
  line-height: 72px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 24px;
  color: ${colorScheme.primary};

  span {
    font-family: ${fonts.BrandontextLight};
    color: white;
  }

  @media screen and (max-width: 500px) {
    font-size: 49px;
    line-height: 49px;
  }

  @media screen and (max-width: 414px) {
    font-size: 30px;
    line-height: 30px;
  }
`;

import styled from "styled-components";
import { CheckmarkSquare2Outline } from "@styled-icons/evaicons-outline/CheckmarkSquare2Outline";
import { SquareOutline } from "@styled-icons/evaicons-outline/SquareOutline";

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
  &:not(:last-child) {
    margin-right: 16px;
  }
`;

export const FieldLabel = styled.label`
  display: block;
  font-family: "CircularBook";
  font-weight: 600;
  font-size: 16px;
  color: #1a1a1a;
  margin-bottom: 8px;
`;

export const FieldError = styled.span`
  display: block;
  font-family: "CircularBook";
  font-weight: 600;
  font-size: 14px;
  color: #e74c3c;
  margin-top: 8px;
`;

export const OptionsContainer = styled.div`
  flex-direction: row;
  columns: 2;
  column-gap: 24px;

  @media screen and (max-width: 414px) {
    columns: 1;
  }
`;

export const Option = styled.label`
  padding-right: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* height: 48px; */
`;

export const Icons = styled.div.attrs({ className: "icons" })``;

export const Input = styled.input`
  appearance: none;
  &:checked + .icons > .checked {
    display: initial;
  }
  &:not(:checked) + .icons > .unchecked {
    display: initial;
  }
`;

export const CheckedIcon = styled(CheckmarkSquare2Outline).attrs({
  size: 24,
  className: "checked",
})`
  color: #e74d1d;
  margin-right: 10px;
  display: none;
`;

export const UncheckedIcon = styled(SquareOutline).attrs({
  size: 24,
  className: "unchecked",
})`
  color: #606060;
  margin-right: 10px;
  display: none;
`;

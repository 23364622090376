import styled from "styled-components";
import { colors, colorScheme, fonts } from "styles";
import Surfers from "assets/images/surfers.png";

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  padding: 0;
  margin-bottom: -5px;
`;

export const Slink = styled.a`
  position: relative;
  display: flex;
  top: 50px;
`;

export const ImgSurfers = styled.img.attrs({ src: Surfers })`
  width: 100%;
  height: auto;
`;
export const Text = styled.h2`
  position: absolute;
  top: 10%;
  font-family: ${fonts.BrandontextBold};
  font-size: 45px;
  line-height: 1em;
  color: #fff;
  max-width: 600px;
  text-transform: uppercase;
  text-align: center;

  @media screen and (max-width: 1240px) {
    font-size: 35px;
    max-width: 500px;
  }
  @media screen and (max-width: 1000px) {
    font-size: 25px;
    max-width: 400px;
  }

  @media screen and (max-width: 715px) {
    font-size: 16px;
    max-width: 300px;
  }
  @media screen and (max-width: 580px) {
    font-size: 12px;
    max-width: 250px;
  }
  @media screen and (max-width: 450px) {
    font-size: 11px;
    max-width: 200px;
  }
`;

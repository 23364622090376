import React, { useState } from "react";
import * as S from "./styles";

import titleThopImg from "assets/images/thop-surf/macote-text.png";

interface AboutProps {
  iSlug: string;
}

export const About: React.FC<AboutProps> = ({ iSlug }) => {
  const infData = [
    {
      slug: "medina",
      name: "Gabriel Medina",
      text: "<p>Foco, ousadia e garra. Tudo isso junto foi o que levou essa lenda do surfe brasileiro a conquistar três vezes o título de campeão mundial.</p><p>Ele quebrou recordes, superou desafios, projetou o Brasil no cenário internacional de surfe e hoje inspira outros surfistas brasileiros a sonharem com uma vida dedicada ao esporte.</p>",
      imgVertical: require("../../../assets/images/thop-surf/medina-vertical.png"),
      imgPic: require("../../../assets/images/thop-surf/medina-pic.png"),
      marginTop: "200px",
      imgMobile: require("../../../assets/images/thop-surf/medina-vertical-mobile.png"),
    },
    {
      slug: "michelle",
      name: "Michelle des Bouillons",
      text: "<p>Com pais apaixonados pelo esporte, o surfe já está no DNA da Michelle. Mas uma coisa é gostar de surfar, outra é se tornar uma das melhores surfistas de ondas gigantes do mundo, recentemente campeã feminina de maior onda surfada em Nazaré.</p><p>Não é só genética, e sim muita paixão, coragem e dedicação.</p>",
      imgVertical: require("../../../assets/images/thop-surf/michelle-vertical.png"),
      imgPic: require("../../../assets/images/thop-surf/michelle-pic.png"),
      marginTop: "200px",
      imgMobile: require("../../../assets/images/thop-surf/michelle-vertical-mobile.png"),
    },
    {
      slug: "ian",
      name: "Ian Cosenza",
      text: "<p>Mar calmo não é mar para o Ian. Seja surfando ou navegando, seu arsenal é feito para mares turbulentos, com suas pranchas e jet skis prontos para se aventurar em ondas gigantes.</p><p>Com toda essa adrenalina correndo solta, não à toa ele é considerado um mestre da onda Grower, na Indonésia.</p>",
      imgVertical: require("../../../assets/images/thop-surf/ian-vertical.png"),
      imgPic: require("../../../assets/images/thop-surf/ian-pic.png"),
      marginTop: "200px",
      imgMobile: require("../../../assets/images/thop-surf/ian-vertical-mobile.png"),
    },
    {
      slug: "lucas-chumbo",
      name: "Lucas Chumbo",
      text: "<p>Poucos se sentem tão à vontade no mar quanto ele. Por isso, quando o desafio é encarar uma onda de mais de dez metros, o Lucas vai com sorrisão no rosto.</p><p>Essa capacidade de transformar o assustador em divertido está sendo muito bem recompensada: nosso herói é pentacampeão em Nazaré.</p>",
      imgVertical: require("../../../assets/images/thop-surf/chumbo-vertical.png"),
      imgPic: require("../../../assets/images/thop-surf/chumbo-pic.png"),
      marginTop: "180px",
      imgMobile: require("../../../assets/images/thop-surf/chumbo-vertical-mobile.png"),
    },
    {
      slug: "lucas-fink",
      name: "Lucas Fink",
      text: "<p>Não tem um centímetro do mar que ele não aproveite. Seu skimboard desliza dos quebra-cocos na beira da areia até as bombas de Nazaré.</p><p>Essa paixão pela modalidade e pelo mar sem pré-requisitos já trouxe as merecidas recompensas: nosso skimboarder já é tetracampeão mundial.</p>",
      imgVertical: require("../../../assets/images/thop-surf/fink-vertical.png"),
      imgPic: require("../../../assets/images/thop-surf/fink-pic.png"),
      marginTop: "180px",
      imgMobile: require("../../../assets/images/thop-surf/fink-vertical-mobile.png"),
    },
    {
      slug: "thop",
      name: "Thop",
      text: "<p>De sorriso ele entende bem. Afinal, o nosso Thop está sempre fazendo de tudo pra estampar o melhor sorriso do mundo, principalmente em cima de uma prancha. Mas não é só o tubo perfeito que garante um sorriso bonito.</p><p>Por isso, entre uma sessão de surfe e outra, aquele cuidado fundamental com a higiene. E sempre que precisar, dar aquela passadinha na Orthopride.</p>",
      imgVertical: require("../../../assets/images/thop-surf/thop-vertical.png"),
      imgPic: require("../../../assets/images/thop-surf/thop-pic.png"),
      marginTop: "180px",
      imgMobile: require("../../../assets/images/thop-surf/thop-vertical-mobile.png"),
    },
  ];

  const filteredData = infData.find((item) => item.slug === iSlug);

  if (!filteredData) {
    return <div>Data not found</div>;
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <S.Container>
        <S.Content>
          <S.GroupTitle>
            <S.Title
              className="wow fadeIn"
              data-wow-delay="300ms"
              dangerouslySetInnerHTML={{ __html: filteredData.name }}
            />
            {filteredData.slug === "thop" ? (
              <img src={titleThopImg} alt="texto mascote" />
            ) : null}
          </S.GroupTitle>
          <S.Text
            className="wow fadeIn"
            data-wow-delay="350ms"
            dangerouslySetInnerHTML={{ __html: filteredData.text }}
          />
          <img
            className="vertical"
            src={filteredData.imgVertical.default}
            alt={filteredData.slug}
          />
          <S.MobileImg>
            <img src={filteredData.imgMobile.default} alt="imagem" />
          </S.MobileImg>
          <S.PicImage mTop={filteredData.marginTop}>
            <img
              className="pic"
              src={filteredData.imgPic.default}
              alt={filteredData.slug}
            />
          </S.PicImage>
          <S.BtnButton onClick={scrollToTop}>Voltar Thop Team</S.BtnButton>
        </S.Content>
      </S.Container>
    </>
  );
};

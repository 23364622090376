import { call, put } from "redux-saga/effects";

import { api, notify } from "services";
import { requestErrorHandler } from "utils";
import {
  SorteioAtivoActions,
  ISorteioAtivoRequest,
} from "store/ducks/parcela-premiada";

export function* SorteioAtivoRequest(action: any) {
  try {
    const { onSuccess } = action as ISorteioAtivoRequest;
    const { data } = yield call(api.get, `/sorteio-ativo`);

    yield put(SorteioAtivoActions.success(data));
    if (onSuccess) onSuccess(data);
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(SorteioAtivoActions.failure(errorMessage));
  }
}

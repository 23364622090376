import { call, put } from "redux-saga/effects";

import { apiFoco, notify } from "services";
import { ICreateRequest } from "interfaces/create-duck";

import { requestErrorHandler } from "utils";
import { CreateFranchisingContactActions } from "store/ducks/franchising";

export function* CreateFranchisingContactRequest(action: any) {
  try {
    const { postData, onSuccess } = action as ICreateRequest;
    const { data } = yield call(apiFoco.post, `/franchising-contact`, postData);

    yield put(CreateFranchisingContactActions.success(data));
    if (onSuccess) onSuccess(data);
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    //notify("error", errorMessage);
    yield put(CreateFranchisingContactActions.failure(errorMessage));
  }
}

import styled from "styled-components";
import { motion } from "framer-motion";

import figure from "assets/images/whitening.png";
import ball from "assets/images/whitening-ball.png";
import logo from "assets/images/whitening-logo.png";

import { colors, fonts } from "styles";
export { Button } from "styles/styled-components";

export const Background = styled.section`
  width: 100%;
  padding: 96px 32px;

  @media screen and (max-width: 414px) {
    padding-top: 48px;
    padding-bottom: 48px;
  }
`;
export const Container = styled.section`
  width: 100%;
  max-width: 1280px;
  display: flex;
  margin: 0 auto;

  @media screen and (max-width: 880px) {
    flex-direction: column;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;

  @media screen and (max-width: 880px) {
    max-width: 100%;

    &:first-child {
      margin-bottom: 32px;
    }
  }
`;

export const Logo = styled.img.attrs({ src: logo })`
  width: 100%;
  max-width: max-content;
  max-height: max-content;
  margin-bottom: 32px;
`;

export const Figure = styled.img.attrs({ src: figure })`
  width: 100%;
  max-width: max-content;
  max-height: max-content;
`;

export const Ball = styled(motion.img).attrs({ src: ball })`
  width: 100%;
  max-width: max-content;
  max-height: max-content;
  position: absolute;
  left: 5%;
  bottom: 10%;

  @media screen and (max-width: 414px) {
    width: 130px;
  }

  @media screen and (max-width: 340px) {
    width: 100px;
  }
`;

export const Wrapper = styled.div`
  padding-left: 48px;
  width: 100%;
  max-width: 510px;

  @media screen and (max-width: 880px) {
    padding-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const Subtitle = styled.h3`
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 24px;
  text-transform: uppercase;
  color: #7f92ad;

  @media screen and (max-width: 880px) {
    text-align: center;
  }

  @media screen and (max-width: 414px) {
    font-size: 16px;
    line-height: 16px;
  }
`;

export const Title = styled.h2`
  font-size: 69px;
  line-height: 69px;
  color: ${colors.dark};
  margin-bottom: 24px;
  text-transform: uppercase;

  span {
    font-family: ${fonts.BrandontextLight};
  }

  @media screen and (max-width: 1130px) {
    font-size: 54px;
    line-height: 54px;
  }

  @media screen and (max-width: 880px) {
    text-align: center;
  }

  @media screen and (max-width: 414px) {
    font-size: 30px;
    line-height: 30px;
  }
`;

export const Text = styled.article`
  width: 100%;
  max-width: 430px;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 40px;

  @media screen and (max-width: 880px) {
    text-align: center;
  }
`;

import styled from "styled-components";
import futebol from "assets/images/footebol-bg-grande.png";
import SponsorBotafogo from "assets/images/sponsor-botafogo.png";
import SponsorCorinthians from "assets/images/sponsor-corinthians.png";
import SponsorFlamengo from "assets/images/sponsor-flamengo.png";
import SponsorFluminense from "assets/images/sponsor-fluminense.png";
import SponsorRioOpen from "assets/images/sponsor-rio-open.png";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items:center;
  justify:content:center;
  width: 100%;
  height:auto;
  background: black url(${futebol}) no-repeat center center;
  background-size: cover;
`;

export const GroupElements = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 500px;
  min-height: 300px;
  padding: 200px 20px;
`;

export const Subtitle = styled.h3`
  font-size: 14px;
  text-transform: uppercase;
  color: white;
  margin-bottom: 8px;
  width: 100%;
  max-width: 150px;
  color: rgb(245, 130, 38);
  margin-bottom: 10px;
  text-align: center;
`;

export const Title = styled.h2`
  font-size: 42px;
  line-height: 42px;
  color: white;
  margin-bottom: 16px;
  text-align: center;
  width: 100%;
  max-width: 250px;
`;

export const Text = styled.article`
  font-size: 14px;
  line-height: 18px;
  color: white;
  width: 100%;
  max-width: 350px;
  text-align: center;
  margin-bottom: 15px;
  @media screen and (max-width: 480px) {
    margin-bottom: 50px;
  }
`;

export const GroupTeam = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
`;

export const LogoFlamengo = styled.img.attrs({ src: SponsorFlamengo })`
  width: 100%;
  max-width: 82px;
  height: auto;
  margin: 0;
`;

export const LogoFlu = styled.img.attrs({ src: SponsorFluminense })`
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 82px;
  height: auto;
`;

export const LogoRioOpen = styled.img.attrs({ src: SponsorRioOpen })`
  width: 150px;
  max-height: 26px;
`;

import React from "react";

import * as S from "./styles";

export const Software: React.FC = () => {
  return (
    <S.Container>
      <S.Title>
        Software e
        <br />
        <span>tecnologia</span>
      </S.Title>
      <S.Subtitle>Smart</S.Subtitle>
      <S.Text>
        Software criado para total administração das atividades, tais como:
        agendas, prontuários, contas a receber, contas a pagar, cadastros,
        relatórios e diversas outras funções que tornam o dia a dia das equipes
        muito mais eficaz e objetivo.
      </S.Text>
      <S.Subtitle>Tecnologia</S.Subtitle>
      <S.Text>
        Buscamos sempre pelas tecnologias mais recentes e inovadoras do mercado.
        Baseados nesse conceito, desenvolvemos toda a nossa plataforma em nuvem,
        possibilitando o acompanhamento dos resultados em tempo real de nossas
        clínicas e em qualquer lugar.
      </S.Text>
      <S.Subtitle>Suporte</S.Subtitle>
      <S.Text>
        Através da ferramenta OP Helpdesk, é possível realizar a abertura de
        chamados de forma simples e acompanhar o andamento das ações que são
        realizadas por nossa equipe. Nosso franqueado conta com um canal de
        suporte técnico extremamente eficaz.
      </S.Text>
    </S.Container>
  );
};

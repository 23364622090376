import styled from "styled-components";
import { colorScheme, fonts } from "styles";
import bg from "assets/images/awards-bg.png";
import awards from "assets/images/awards.png";

export const Container = styled.section`
  width: 100%;
  height: 650px;
  background: url(${bg}) no-repeat center center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 72px 32px;

  @media screen and (max-width: 414px) {
    padding-top: 48px;
    padding-bottom: 48px;
    height: auto;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 590px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h2`
  font-size: 46px;
  line-height: 46px;
  margin-bottom: 32px;
  text-align: center;
  color: ${colorScheme.primary};

  span {
    font-family: ${fonts.BrandontextLight};
    color: white;
  }

  @media screen and (max-width: 414px) {
    font-size: 30px;
    line-height: 30px;
  }
`;

export const Text = styled.article`
  color: white;
  text-align: center;
  font-size: 18px;
  margin-bottom: 40px;

  p {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 414px) {
    font-size: 16px;
  }
`;

export const AwardsPic = styled.img.attrs({ src: awards })`
  width: 100%;
  max-width: max-content;
`;

import styled from "styled-components";

export const Container = styled.div``;

export const Title = styled.h2`
  font-size: 48px;
  line-height: 0.9em;
  color: ${({ theme }) => theme.colors.dark};
  margin-bottom: 48px;

  span {
    font-family: ${({ theme }) => theme.fonts.BrandontextLight};
  }

  @media screen and (max-width: 414px) {
    font-size: 30px;
    line-height: 30px;
    text-align: center;
  }
`;

export const Subtitle = styled.h3`
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colorScheme.primary};

  @media screen and (max-width: 414px) {
    font-size: 18px;
    line-height: 18px;
    text-align: center;
  }
`;

export const Text = styled.article`
  font-size: 17px;
  line-height: 1.5em;
  margin-bottom: 40px;

  @media screen and (max-width: 414px) {
    text-align: center;
  }
`;

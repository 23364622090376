import React from "react";
import { useHistory } from "react-router-dom";
import * as S from "./styles";
import { LegalText } from "components/Shared";

export const Whitening: React.FC = () => {
  const history = useHistory();
  const animate = {
    flying: {
      y: [0, 10, 0],
      transition: { ease: "easeInOut", duration: 4, repeat: Infinity },
    },
  };
  return (
    <S.Background>
      <S.Container>
        <S.Content>
          <S.Ball animate="flying" variants={animate} />
          <S.Figure />
        </S.Content>
        <S.Content>
          <S.Wrapper>
            <S.Logo />
            <S.Subtitle>clareamento dentário portátil</S.Subtitle>
            <LegalText text="Clínico geral com atuação em dentística" />
            <S.Text>
              O kit de clareamento dentário Orthopride Whitening inclui uma
              moldeira de LED e 3 canetas com gel clareador, que facilitam a
              aplicação do produto devido à sua praticidade. Faça o seu
              clareamento em casa, com comodidade e tecnologia avançada, e tenha
              dentes mais claros, em menos tempo! Para realizar este
              procedimento, é necessário agendar uma avaliação com o seu
              dentista.
            </S.Text>
            <S.Button onClick={() => history.push("/agendar-avaliacao")}>
              Marcar uma avaliação
            </S.Button>
          </S.Wrapper>
        </S.Content>
      </S.Container>
    </S.Background>
  );
};

import styled, { css } from "styled-components";
import { colors } from "styles";
import videoBg from "assets/images/clean-teeth-video-bg.png";

export const Container = styled.section`
  width: 100%;
  margin: 0 auto;
  position: relative;
  /* background-color: ${colors.grayLight}; */
  background-color: white;
  padding-bottom: 80px;
`;

export const Background = styled.div`
  width: 100%;
  height: 540px;
  background: rgb(245, 130, 38);
  background: linear-gradient(
    135deg,
    rgba(245, 130, 38, 1) 0%,
    rgba(242, 111, 57, 1) 100%
  );
  position: absolute;
  z-index: 1;

  @media screen and (max-width: 720px) {
    display: none;
  }
`;

export const BackgroundMobile = styled.div`
  width: 100%;

  @media screen and (max-width: 720px) {
    background: rgb(245, 130, 38);
    background: linear-gradient(
      135deg,
      rgba(245, 130, 38, 1) 0%,
      rgba(242, 111, 57, 1) 100%
    );
  }
`;

export const Content = styled.div`
  width: 100%;
  padding: 88px 32px 0 32px;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h2`
  width: 100%;
  max-width: 560px;
  font-size: 46px;
  line-height: 46px;
  margin-bottom: 32px;
  text-align: center;
  color: ${colors.dark};

  span {
    color: white;
  }

  @media screen and (max-width: 414px) {
    font-size: 30px;
    line-height: 30px;
  }
`;

export const Text = styled.article`
  width: 100%;
  max-width: 570px;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: ${colors.dark};
  margin-bottom: 80px;

  @media screen and (max-width: 414px) {
    font-size: 16px;
  }
`;

export const Video = styled.div`
  width: 100%;
  max-width: 786px;
  margin: 0 auto 80px auto;
  position: relative;
  z-index: 2;

  .player-wrapper {
    position: relative;
    padding-top: 56.25%;
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  @media screen and (max-width: 414px) {
    margin-bottom: 0;
  }
`;

export const Tapume = styled.button.attrs({ type: "button" })<{
  hidden: boolean;
}>`
  width: 100%;
  max-width: 786px;
  height: auto;
  position: absolute;
  z-index: 3;

  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `}
`;

export const TapumeImage = styled.img.attrs({ src: videoBg })`
  width: 100%;
  max-width: max-content;
`;

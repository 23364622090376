import styled from "styled-components";
import { motion } from "framer-motion";
import { ChevronCircleRight } from "@styled-icons/fa-solid";
import { RightArrowAlt, LeftArrowAlt } from "@styled-icons/boxicons-regular/";
import { colors, colorScheme, fonts } from "styles";

import aparelhoEstetico from "assets/images/thop-aparelho-estetico.png";
import aparelhoFixo from "assets/images/thop-aparelho-fixo.png";
import aparelhoInvisivel from "assets/images/thop-aparelho-invisivel.png";

export const Container = styled.section`
  width: 100%;
  background-color: ${colors.grayLight};
  margin: 0 auto;
  position: relative;

  @media screen and (max-width: 414px) {
    background-color: white;
  }
`;

export const WhiteBackground = styled.div`
  width: 100%;
  height: 700px;
  background-color: white;
  position: absolute;
  z-index: 1;

  @media screen and (max-width: 414px) {
    display: none;
  }
`;

export const Content = styled.div`
  width: 100%;
  padding: 88px 32px 0 32px;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 414px) {
    padding: 48px 0;
  }
`;

export const Title = styled.h2`
  width: 100%;
  max-width: 480px;
  font-size: 46px;
  line-height: 46px;
  margin-bottom: 32px;
  text-align: center;
  color: ${colorScheme.secondary};

  span {
    color: ${colorScheme.primary};
  }

  @media screen and (max-width: 414px) {
    font-size: 30px;
    line-height: 30px;
    padding: 0 32px;
  }
`;

export const Text = styled.article`
  width: 100%;
  max-width: 800px;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: ${colors.blueGray};
  margin-bottom: 80px;

  @media screen and (max-width: 414px) {
    font-size: 16px;
    padding: 0 32px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1366px;

  .slick-prev:before,
  .slick-next:before {
    content: "";
    display: none;
  }
`;

export const BoxBrace = styled.div`
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 640px;
  padding: 40px 48px;
  background: rgb(245, 130, 38);
  background: linear-gradient(
    135deg,
    rgba(245, 130, 38, 1) 0%,
    rgba(242, 111, 57, 1) 100%
  );

  &.estetico {
    background: ${colors.dark};
  }
  &.transparente {
    background: ${colors.blueGray};
  }
`;

export const TitleBox = styled.h3`
  font-size: 28px;
  line-height: 28px;
  color: white;
  margin-bottom: 24px;
  width: 100%;

  @media screen and (max-width: 900px) {
    text-align: center;
  }

  @media screen and (max-width: 414px) {
    font-size: 26px;
    line-height: 26px;
  }
`;

export const AparelhoEstetico = styled(motion.img).attrs({
  src: aparelhoEstetico,
})`
  margin-bottom: 24px;
  width: 100%;
  max-width: max-content;
  max-height: max-content;
  height: auto;
`;

export const AparelhoFixo = styled(motion.img).attrs({ src: aparelhoFixo })`
  margin-bottom: 24px;
  width: 100%;
  max-width: max-content;
  max-height: max-content;
  height: auto;
`;

export const AparelhoInvisivel = styled(motion.img).attrs({
  src: aparelhoInvisivel,
})`
  margin-bottom: 24px;
  width: 100%;
  max-width: max-content;
  max-height: max-content;
  height: auto;
`;

export const TextBox = styled.article`
  width: 100%;
  font-size: 17px;
  line-height: 24px;
  margin-bottom: 32px;
  color: white;
  flex: 1;

  span {
    font-family: ${fonts.SourceSansProBold};

    &.dark {
      color: ${colors.dark};
    }
  }

  @media screen and (max-width: 900px) {
    text-align: center;
  }
`;

export const KnowMore = styled.button.attrs({ type: "button" })`
  display: flex;
  align-items: center;
  font-size: 18px;
  color: white;
  align-self: flex-start;
  align-items: center;
  transition: 300ms ease;

  :hover {
    svg {
      transform: translateX(4px);
    }
  }

  @media screen and (max-width: 900px) {
    align-self: center;
  }
`;

export const ArrowIcon = styled(ChevronCircleRight).attrs({ size: 20 })<{
  dark?: boolean;
}>`
  transition: 300ms ease;
  margin-right: 8px;
  color: ${({ theme, dark }) =>
    dark ? theme.colorScheme.secondary : theme.colorScheme.primary};
`;

export const IconNext = styled(RightArrowAlt).attrs({ size: 20 })`
  color: ${colors.dark};
`;

export const IconPrev = styled(LeftArrowAlt).attrs({ size: 20 })`
  color: ${colors.dark};
`;

export const PrevButton = styled.button.attrs({ type: "button" })`
  width: 48px;
  height: 48px;
  position: absolute;
  background-color: white;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 300ms ease;
  transform-origin: center center;
  z-index: 99 !important;
  left: -48px;

  &:hover {
    background: white !important;
  }

  &:focus {
    background: white !important;
  }

  @media screen and (max-width: 1300px) {
    display: none !important;
  }
`;

export const NextButton = styled(PrevButton)`
  left: auto;
  right: -48px;

  @media screen and (max-width: 1300px) {
    display: none !important;
  }
`;

export const DragText = styled.span`
  display: none;

  @media screen and (max-width: 414px) {
    display: block;
    text-align: center;
    margin-top: 32px;
    color: ${colors.blueGray};
  }
`;

import React, { useRef, useCallback, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useHistory } from "react-router-dom";
import { OptionTypeBase } from "react-select";
import { Form } from "@unform/web";
import { FormHandles, SubmitHandler } from "@unform/core";
import * as Yup from "yup";

import { ListUnidadesState, ListUnidadesActions } from "store/ducks/unidades";
import {
  CreateOuvidoriaActions,
  CreateOuvidoriaState,
} from "store/ducks/ouvidoria";

import { IUnidade } from "interfaces/unidade";
import { useValidation } from "hooks";
import { statesOptions } from "utils";

import * as S from "./styles";
import { Scaffold, Footer } from "components/Shared";
import { Input, Select, Textarea } from "components/Shared/Form";
import { Banner } from "components/Ouvidoria";

export const Ouvidoria: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [cityOptions, setCityOptions] = useState<OptionTypeBase[]>([]);
  const [units, setUnits] = useState<OptionTypeBase[]>([]);
  const { handleFormErrors } = useValidation();

  const { data: listUnitsData, loading: listUnitsLoading } =
    useSelector<RootStateOrAny>(
      (state) => state.listUnidades
    ) as ListUnidadesState;

  const { loading: loadingOuvidoria } = useSelector<RootStateOrAny>(
    (state) => state.createOuvidoria
  ) as CreateOuvidoriaState;

  const setCities = useCallback((unitsData) => {
    const data = unitsData.map((unit: IUnidade) => ({
      label: unit.Cidade,
      value: unit.Cidade,
    }));

    const uniqueData: any[] = [];

    for (let index = 0; index < data.length; index++) {
      const item = data[index];
      if (!uniqueData.some((i) => i.value === item.value)) {
        uniqueData.push(item);
      }
    }
    setCityOptions(uniqueData);
  }, []);

  const getUnits = useCallback(
    (option) => {
      dispatch(ListUnidadesActions.request({ state: option.value }, setCities));
    },
    [dispatch, setCities]
  );

  const unitsOptions = useCallback(
    (option) => {
      const data = listUnitsData
        .filter((unit) => unit.Cidade === option.value)
        .map((unit) => ({
          label: unit.Nome,
          value: unit.Sigla,
        }));

      setUnits(data);
    },
    [listUnitsData]
  );

  const onSuccess = useCallback(
    (data) => {
      history.push({
        pathname: "/ouvidoria-obrigado",
        state: data,
      });
    },
    [history]
  );

  const handleSubmit = useCallback<SubmitHandler>(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          state: Yup.string().required("Obrigatório"),
          city: Yup.string().required("Obrigatório"),
          unit: Yup.string().required("Obrigatório"),
          name: Yup.string().required("Obrigatório"),
          email: Yup.string().required("Obrigatório"),
          phone: Yup.string().required("Obrigatório"),
          message: Yup.string().required("Obrigatório"),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        dispatch(CreateOuvidoriaActions.request(data, onSuccess));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, handleFormErrors, onSuccess]
  );

  return (
    <Scaffold>
      <Banner />
      <S.Container>
        <S.Aside>
          <S.Title>
            Já conhece a<br />
            nossa <span>Ouvidoria</span>?
          </S.Title>
          <S.Text>
            <p>
              A principal função da Ouvidoria é defender os interesses dos
              clientes dentro da Orthopride. Nela, todas as manifestações são
              analisadas criteriosamente e encaminhadas às áreas competentes,
              com acompanhamento do início até a resposta final ao solicitante.
            </p>
            <p>
              O paciente deve acioná-la quando não se sentir totalmente atendido
              nos outros canais disponibilizados pela Orthopride.
            </p>
          </S.Text>
          <S.Box>
            <S.ThopSmall />
            <S.BoxTitle>
              deseja tirar alguma dúvida com uma unidade próxima?
            </S.BoxTitle>
            <S.FindUnitButton
              onClick={() => history.push("/encontre-uma-unidade")}
            >
              <S.ChevronIcon />
              Encontre sua Unidade aqui
            </S.FindUnitButton>
          </S.Box>
        </S.Aside>
        <S.Content>
          <S.Text>
            Para fazer uma manifestação, preencha o formulário abaixo com seus
            dados, a unidade e os detalhes do ocorrido.
          </S.Text>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Select
              name="state"
              label="Estado: "
              options={statesOptions}
              onChange={(e) => getUnits(e)}
            />
            <S.Row border>
              <Select
                name="city"
                label="Cidade:"
                isDisabled={listUnitsLoading || cityOptions.length === 0}
                isLoading={listUnitsLoading}
                options={cityOptions}
                onChange={(e) => unitsOptions(e)}
              />
              <Select
                name="unit"
                label="Unidade:"
                options={units}
                isDisabled={units.length === 0}
              />
            </S.Row>
            <Input name="name" label="Nome e sobrenome:" />
            <Input name="email" label="E-mail:" />
            <Input name="phone" label="Celular:" />
            <Textarea name="message" label="Descreva o ocorrido:" rows="5" />
            <S.Button>{loadingOuvidoria ? <S.Loading /> : "Enviar"}</S.Button>
          </Form>
        </S.Content>
      </S.Container>
      <Footer />
    </Scaffold>
  );
};

import React, { useState } from "react";
import ReactPlayer from "react-player";

import * as S from "./styles";

export const CleanTeeth: React.FC = () => {
  const [play, setPlay] = useState<boolean>(false);
  return (
    <S.Container>
      <S.Background />
      <S.BackgroundMobile>
        <S.Content>
          <S.Title>
            Limpeza <span>do aparelho</span>
          </S.Title>
          <S.Text>
            Veja aqui algumas orientações para manter o seu aparelho sempre
            limpo.
          </S.Text>
        </S.Content>
      </S.BackgroundMobile>
      <S.Video>
        <S.Tapume onClick={() => setPlay(true)} hidden={play}>
          <S.TapumeImage />
        </S.Tapume>
        <div className="player-wrapper">
          <ReactPlayer
            url="https://vimeo.com/275073868"
            className="react-player"
            width="100%"
            height="100%"
            playing={play}
          />
        </div>
      </S.Video>
    </S.Container>
  );
};

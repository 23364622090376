import { call, put } from "redux-saga/effects";

import { api, notify } from "services";
import { IListRequest } from "interfaces/list-duck";

import { requestErrorHandler } from "utils";
import { ListUnidadesActions } from "store/ducks/unidades";

export function* ListUnidadesRequest(action: any) {
  try {
    const { query = { state: "RJ" }, onSuccess } = action as IListRequest;
    const url = `/unidades/estado/${query.state}/${query?.lineo}`;
    const { data } = yield call(api.get, url);
    console.log("VALOR DA URL BUSCA DE UNIDADES: ", url);
    yield put(ListUnidadesActions.success(data));
    if (onSuccess) onSuccess(data);
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(ListUnidadesActions.failure(errorMessage));
  }
}

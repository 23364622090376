import React, { useState } from "react";

import * as S from "./styles";
import { Pessoal, Ponto, Infra, Software, Manuais } from "./Options";

type TFeatures = "pessoal" | "ponto" | "infra" | "software" | "manuais";

export const Features: React.FC = () => {
  const [selected, setSelected] = useState<TFeatures>("pessoal");

  const features = {
    pessoal: <Pessoal />,
    ponto: <Ponto />,
    infra: <Infra />,
    software: <Software />,
    manuais: <Manuais />,
  };
  return (
    <S.BackgroundContainer>
      <S.Container>
        <S.Aside>
          <S.AsideTitle>Características do nosso franchising:</S.AsideTitle>
          <S.Menu>
            <S.Option
              active={selected === "pessoal"}
              onClick={() => setSelected("pessoal")}
            >
              Gestão de pessoal
              <S.Arrow />
            </S.Option>
            <S.Option
              active={selected === "ponto"}
              onClick={() => setSelected("ponto")}
            >
              Ponto de instalação da unidade <S.Arrow />
            </S.Option>
            <S.Option
              active={selected === "infra"}
              onClick={() => setSelected("infra")}
            >
              Infraestutura <S.Arrow />
            </S.Option>
            <S.Option
              active={selected === "software"}
              onClick={() => setSelected("software")}
            >
              Softwares e tecnologias <S.Arrow />
            </S.Option>
            <S.Option
              active={selected === "manuais"}
              onClick={() => setSelected("manuais")}
            >
              Manuais <S.Arrow />
            </S.Option>
          </S.Menu>
        </S.Aside>
        <S.Content>{features[selected]}</S.Content>
      </S.Container>
    </S.BackgroundContainer>
  );
};

import React from "react";
import Slider from "react-slick";
import * as S from "./styles";

import { tips, ITip } from "data/braceTips";

export const BracesTips: React.FC = () => {
  const settings = {
    speed: 500,
    adaptiveHeight: true,
    slidesToShow: 1,
    dots: true,
  };

  return (
    <S.Container>
      <S.Content>
        <S.Title>
          Vai colocar aparelhos?
          <br />
          Confira aqui algumas dicas.
        </S.Title>
        <S.Wrapper>
          {tips.map((tip: ITip) => (
            <S.Tips key={tip.id}>
              <S.TipsNumber>{tip.number}</S.TipsNumber>
              <S.TipsTitle>{tip.title}</S.TipsTitle>
              <S.Text>{tip.text}</S.Text>
            </S.Tips>
          ))}
        </S.Wrapper>
        <S.Wrapper className="slider-mobile">
          <Slider {...settings}>
            {tips.map((tip: ITip) => (
              <S.Tips key={tip.id}>
                <S.TipsNumber>{tip.number}</S.TipsNumber>
                <S.TipsTitle>{tip.title}</S.TipsTitle>
                <S.Text>{tip.text}</S.Text>
              </S.Tips>
            ))}
          </Slider>
        </S.Wrapper>
        <S.DragText>Arraste para o lado</S.DragText>
      </S.Content>
    </S.Container>
  );
};

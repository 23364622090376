import React from "react";

import * as S from "./styles";

export const Infra: React.FC = () => {
  return (
    <S.Container>
      <S.Title>Infraestrutura</S.Title>
      <S.Subtitle>Fornecedores</S.Subtitle>
      <S.Text>
        O processo de homologação de nossos fornecedores é totalmente
        qualificado, o que nos possibilita contar com parceiros que oferecem os
        produtos mais modernos do mercado, preços competitivos e respeito aos
        prazos estabelecidos.
      </S.Text>
      <S.Subtitle>Compras</S.Subtitle>
      <S.Text>
        A compra dos produtos necessários para a operação das clínicas é
        centralizada na nossa ferramenta oplog, o que nos possibilita realizar
        as compras em maior volume e, consequentemente, melhores preços junto
        aos fornecedores em uma logística confiável.
      </S.Text>
    </S.Container>
  );
};

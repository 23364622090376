import styled from "styled-components";
import bg from "assets/images/franchising2024/bg-banner.jpg";
import medinaCaio from "assets/images/franchising2024/medina-caio.png";
import listStyle from "assets/images/franchising2024/list-style.png";
import { colors, fonts } from "styles";

export const Container = styled.section`
  position: relative;
  width: 100%;
  min-height: 700px;
  margin: 0 auto;
  background: url(${bg}) no-repeat center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 50px 100px;

  @media screen and (max-width: 1240px) {
    padding: 50px 30px;
  }
  @media screen and (max-width: 1330px) {
    justify-content: flex-end;
  }
  @media screen and (max-width: 850px) {
    padding: 50px 30px;
  }
  @media screen and (max-width: 600px) {
    justify-content: center;
  }
`;

export const Modalbg = styled.div`
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
  @media screen and (max-width: 600px) {
    display: flex;
    z-index: 1;
  }
`;

export const GroupText = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;
  height: auto;
  z-index: 3;

  h2 {
    font-family: ${fonts.BrandontextBlack};
    color: ${colors.white};
    font-size: 44px;
    line-height: 1.1em;
    margin-bottom: 20px;
    span {
      font-family: ${fonts.BrandontextBlack};
      color: ${colors.orange};
    }

    @media screen and (max-width: 600px) {
      font-size: 50px;
      text-align: center;
    }
    @media screen and (max-width: 500px) {
      font-size: 40px;
      text-align: center;
    }
  }
`;

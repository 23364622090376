import styled from "styled-components";
import { Search } from "@styled-icons/ionicons-solid";

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 500px;

  form {
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;

  input {
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightDarkBlue};
    padding: 8px 0;
    width: 100%;
    height: auto;
  }
`;

export const SubmitButton = styled.button`
  color: ${({ theme }) => theme.colors.lightDarkBlue};
`;
export const IconSearch = styled(Search).attrs({ size: 24 })``;
